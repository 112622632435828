import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import { useSaveErcheck } from "../../Hooks/useSaveErcheck";
import { CheckUUID } from "../../Utility/CheckUUID";
import AnalyzeModal from "../Includes/Layouts/Split_1/AnalayzeModal";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";

const ErCheck = () => {
  const  {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('','er_check');
  
  const { isCheck} = CheckUUID();
  isCheck();
  const [modalClass, SetModalClass] = useState("modal fade");
  const [modalClass1, SetModalClass1] = useState("show");
  const [modalClass2, SetModalClass2] = useState("hide");
  const { saveEr_check, isLoading } = useSaveErcheck();
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  }; 
  const uuid = getUuid();
  
    const formdata = JSON.parse(localStorage.getItem("formData"));
    
    useEffect(async () => {
      const response = await saveEr_check(
        formdata,
        uuid  
      ); 
      if(response.data === 1){
        history.push(
          "/"+DynamicRouteNextPage.sub_page2+"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName
        );
      } else{
        history.push(
          "/"+DynamicRouteNextPage.sub_page1+"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName
        );
      }
    },[]);

    
   
  return (
    <>
   <AnalyzeModal
                modalClass="modal fade show"
                modalClass1="show"
                modalClass2="hide"
                textHeading="Loading"
            />
    </>
  );
};
export default ErCheck;
