import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const PrivacyPolicy = ({ showstatus, hidepopup }) => {
    return (
        <>
            <div className="modal-custom-div">
                <Modal show={showstatus} onHide={hidepopup} dialog size="xl" ClassName="modal fade privacykl" 
                    aria-labelledby="example-custom-modal-styling-title" dialogClassName="privacykl" >
                    
                        <Modal.Header className="modal-header">
                            <Modal.Title  id="privacykellerLabel">Online Privacy Notice</Modal.Title>
                            <Button  className="close" onClick={hidepopup}>×</Button>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="modal-body">
                            <p>Online Privacy Notice</p>
                        </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={hidepopup}>
                                Close
                            </Button>
                        </Modal.Footer>
                  
                </Modal>
            </div>
        </>
    )
}

export default PrivacyPolicy;