import React, { useState, useEffect, useReducer } from "react";
import { queryString } from "../../../Utility/QueryString";
import InputButton from "../../UI/InputButton";
import SignatureModal from "../../Includes/Layouts/Followup/SignatureModal";
import IdUploadModal from "../../Includes/Layouts/Followup/IdUploadModal";
import { Api } from "../.././../api/Api";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import Loader from "../../Includes/Layouts/Followup/Loader";
import { useHistory } from "react-router-dom";
import img01 from "../../../assets/img/y-but.png";

var complete_arr = [];
const initialState = {
  showButton1: "hide",
  showButton2: "hide",
  showTick1: "hide",
  showTick2: "hide",
};
const FormReducer = (state, action) => {
  switch (action.type) {
    case "showButton": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "id-upload") {
        return {
          ...state,
          showButton2: "show",
          showTick2: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "hideButton": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "id-upload") {
        return {
          ...state,
          showButton2: "hide",
          showTick2: "show",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "showTick": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showTick1: "show",
          showButton1: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "id-upload") {
        return {
          ...state,
          showTick2: "show",
          showButton2: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};
const Followup = (props) => {
  const [signatureClass, SetSignatureClass] = useState(false);
  const [idUploadClass, SetIdUploadClass] = useState(false);
  const [pendingStatus, setPendingStatus] = useState([]);
  const [showLoad, setshowLoad] = useState("show");
  const [showFooter, setshowFooter] = useState("d-none");
  const [showUserName, setUserName] = useState("");
  const [allFollowupData, setFollowupData] = useState({});
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [pending, setPending] = useState(true);
  const [followupStatus, setFollowupStatus] = useState("Pending");
  const [currentUuid, setCurrentUuid] = useState("");
  const history = useHistory();
  const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
  const query = new URLSearchParams(window.location.search);
  const userToken = query.get("atp_sub2");
  const atpSub6 = query.get("atp_sub6") ? query.get("atp_sub6") : "FLP";
  localStorage.setItem("user_token", userToken);
  localStorage.setItem("atp_sub6", atpSub6);
  const setLoader = (e) => {
    setshowLoad("show");
  };
  const hideLoader = (e) => {
    setshowLoad("hide");
  };
  const showThankYou = (uuid) => {
    history.push(
      "/followup-thankyou?uuid=" +
        uuid +
        "&user_token=" +
        userToken +
        "&source=" +
        atpSub6
    );
  };
  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/followup/get-pending-details?query_string=" + queryString,
        {}
      );
      const followupDetails = response.data.response.followup_data;
      if (userToken == null) {
        localStorage.setItem("user_token", followupDetails.atp_sub2);
      }
      setUserName(response.data.response.user_info.user_name);
      setFollowupData({
        flpData: response.data.response,
      });
      localStorage.setItem(
        "user_info",
        JSON.stringify(response.data.response.user_info)
      );
      setCurrentUuid(response.data.response.user_info.uuid);
      if (response.data.status === "Success") {
        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          "DE_JLG_1",
          queryString,
          "v2"
        );
        setTimeout(() => {
          hideLoader();
        }, 2000);
        if (response.data.response.pending_details.is_user_complete === 1) {
          showThankYou(response.data.response.user_info.uuid);
          return false;
        }
        if (response.data.response.pending_details.is_user_sign === 0) {
          setPendingStatus({
            status_btn: "signature",
            status_flag: "showButton",
          });
        } else {
          setPendingStatus({
            status_btn: "signature",
            status_flag: "showTick",
          });
        }
        if (response.data.response.pending_details.is_user_doc === 0) {
          setPendingStatus({
            status_btn: "id-upload",
            status_flag: "showButton",
          });
        } else {
          setPendingStatus({
            status_btn: "id-upload",
            status_flag: "showTick",
          });
        }
        if (response.data.response.pending_details.is_user_doc === 1) {
          setPendingStatus({
            status_btn: "id-upload",
            status_flag: "hideButton",
          });
        } 
      }
    })();
  }, []);
  useEffect(() => {
    if (pendingStatus) {
      dispatch({ type: pendingStatus.status_flag, payload: { pendingStatus } });
      if (pendingStatus.status_flag == "showTick") {
        complete_arr.push(pendingStatus.status_btn);
        if (
          complete_arr.indexOf("id-upload") > -1 &&
          complete_arr.indexOf("signature") > -1
        ) {
          setPending(false);
          setFollowupStatus("Review Stage");
          setshowFooter("d-block");
        }
      }
    }
  }, [pendingStatus]);
  return (
    <>
      <div className="Followup">
        <div className="bg-gradient">
          <section className="questionspart">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 l_bg">
                  <form>
                    <Loader showLoad={showLoad}></Loader>
                    <div
                      id="myDiv2"
                      className="slide2"
                      style={
                        showLoad === "hide"
                          ? { display: "block", margin: "20px 0px 0px" }
                          : { display: "none" }
                      }
                    >
                      <div className="blue-bg col-12">
                        <h4>Claim Status: {followupStatus}</h4>
                        <p>
                          Almost done! We need a few more details to being
                          processing your claim.
                        </p>
                      </div>

                      <div className="col-12 data-div mb20 animated fadeInUp">
                        <div className=" col-12 nopad">
                          <table className="table table-bordered">
                            <thead className="thead-light">
                              <tr>
                                <th className="align-middle" width="60%">
                                  Account Holder{" "}
                                </th>
                                <th width="40%">{showUserName}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Signature</td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle blink_me  ${state.showButton1}`
                                      : `hide`
                                  }
                                >
                                  <InputButton
                                    id=""
                                    value="Sign here"
                                    btnType="button"
                                    onClick={() => SetSignatureClass(true)}
                                    className="add-btn signbut"
                                  />
                                </td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle signyes ${state.showTick1}`
                                      : `hide`
                                  }
                                >
                                  <img src={img01} alt="" />
                                </td>
                              </tr>

                              <tr style={{ borderBottom: "solid 4px #000000" }}>
                                <td>Id Upload</td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle blink_me  ${state.showButton2}`
                                      : `hide`
                                  }
                                >
                                  <InputButton
                                    id=""
                                    value="Add now"
                                    btnType="button"
                                    onClick={() => SetIdUploadClass(true)}
                                    className="add-btn idbut"
                                  />
                                </td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle signyes ${state.showTick2}`
                                      : `hide`
                                  }
                                >
                                  <img src={img01} alt="" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <footer className={showFooter}
          style={
            showLoad === "hide" ? { display: "block" } : { display: "none" }
          }
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6">
                <InputButton
                  id=""
                  value="Submit"
                  btnType="button"
                  onClick={() => showThankYou(currentUuid)}
                  className="btn btn-primary flp-button"
                />
              </div>
            </div>
          </div>
        </footer>
      </div>

      <SignatureModal
        popDisplay={signatureClass}
        allFollowupData={allFollowupData}
        setPendingStatus={setPendingStatus}
        setLoader={setLoader}
        hideLoader={hideLoader}
        showUserName={showUserName}
      />
      <IdUploadModal
        popDisplay={idUploadClass}
        allFollowupData={allFollowupData}
        setPendingStatus={setPendingStatus}
        setLoader={setLoader}
        hideLoader={hideLoader}
        showUserName={showUserName}
      />
    </>
  );
};

export default Followup;
