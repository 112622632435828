import { useHistory } from "react-router-dom";
import { UUID } from "./UUID";
import { urlParams } from "../Utility/QueryString";

export const CheckUUID = () => {
  const full_url = window.location.href;
  const params = Object.fromEntries(urlParams.entries());
  const history = useHistory();
  const { fetchUuid } = UUID();
  const isCheck = () => {
    var flag = false;
    var localFlag = false;
    if (!params.hasOwnProperty("uuid") || (params.hasOwnProperty("uuid") && !urlParams.get("uuid")) || (params.hasOwnProperty("uuid") && urlParams.get("uuid") === '""')) {
      flag = true;
    }
    if (!localStorage.getItem('uuid')) {
      localFlag = true;
    }
    const urlParams = new URLSearchParams(full_url);
   
    let splitName=   urlParams.get('splitName');
    if (flag && localFlag && !splitName) {
      history.push("/404");
      return false;
    } else {
      const uuid = fetchUuid();
        window.history.pushState({}, '', '?uuid=' + uuid + "&splitName="+splitName);
        return true;  
    }
  }
  return { isCheck }
}