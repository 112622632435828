import React, { useState } from "react";
import "../../../assets/css/modules/TCG_YH_DE_V1.scss";
import AdvHeader from "../../Includes/Layouts/TCG_YH_DE_V1/AdvHeader";
import AdvFooter from "../../Includes/Layouts/TCG_YH_DE_V1/AdvFooter";
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import img01 from '../../../assets/img/banner1.jpg';
import img02 from '../../../assets/img/side-ban1.1.png';
import img03 from '../../../assets/img/btm_arow1.png';
import img04 from '../../../assets/img/map.png';
import img05 from '../../../assets/img/number-plate.png';

const TCG_YH_DE_V1 = () => {
  return (
    <>
      <AdtopiaLoadAP pageType="AP" splitName="DE_V1" />
      <div className="TCG_YH_DE_V1">
        <AdvHeader />

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>
                Over one million Mercedes-Benz drivers may have been misled and could be owed £1,000s for each vehicle!
                </h1>
                <h2>
                If you owned or leased a Mercedes-Benz diesel car between 2008 - 2020, you could be eligible for compensation.
                </h2>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                <AnchorAdv dataId="PARA_IMAGE_1">
                  <img
                    src={img01}
                    alt=""
                    className="banner"
                  />
                </AnchorAdv>
                <div className="liness">
                  <div className="yellows d-lg-block d-md-block d-sm-block d-none">
                    <AnchorAdv dataId="PARA_TEXT_LINKS_1">
                      <b>
                        <u>
                          <span>
                            ‘DIESELGATE’ SCANDAL: 1.2 million diesel vehicles in
                            the UK have been impacted in{" "}
                          </span>
                          <br />
                          <span>
                            what could become the UK’s largest consumer group
                            action.
                          </span>
                        </u>
                      </b>
                    </AnchorAdv>
                  </div>

                  <div className="yellowmob d-lg-none d-md-none d-sm-none d-block">
                    <AnchorAdv dataId="PARA_TEXT_LINKS_1">
                      <b>
                        <u>
                          <span>
                            ‘DIESELGATE’ SCANDAL: 1.2 million diesel vehicles in
                            the UK have been impacted in what could become the
                            UK’s largest consumer group action.
                          </span>
                        </u>
                      </b>
                    </AnchorAdv>
                  </div>
                </div>
                <h3>Ever Owned A Mercedes-Benz Vehicle?</h3>
                <p>
                  If you answered yes to the question above, you could be
                  eligible to claim £1,000s in compensation.
                </p>

                <p>
                  In 2017, the KBA (Germany’s vehicle approval authority)
                  identified that Mercedes-Benz had been cheating emissions
                  tests and lying to customers about their diesel cars. It was
                  found that Mercedes-Benz had been installing cheat devices in
                  many of their diesel vehicles to falsely pass emissions tests.
                  They misled their customers by telling them the diesel cars
                  were more environmentally friendly and more efficient than
                  they actually were. In doing so, customers paid more for these
                  vehicles than they really should have. Claimants could be owed £1,000s for each affected vehicle.
                </p>

                <p>
                  It is estimated that more than 1 million individuals in the UK
                  have been impacted by what is being called the{" "}
                  <AnchorAdv dataId="PARA_TEXT_LINK_2">
                    <u>
                      <b>Dieselgate Scandal</b>
                    </u>
                  </AnchorAdv>
                  .
                </p>

                <div className="cta_sec_dsk">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                      <div className="col-lg-12 d-block d-sm-block d-md-none d-lg-none banner11 p-0">
                        <AnchorAdv dataId="RIGHT_SITE_BANNER_1">
                          <img
                            src={img02}
                            alt=""
                            style={{ width: "100%", paddingBottom: "8px" }}
                          />{" "}
                        </AnchorAdv>
                      </div>
                      <div className="banner11 d-none d-sm-none d-md-block d-lg-block">
                        {/* <h2>HOW TO GET STARTED:</h2> */}
                        <p></p>
                        <h6>
                          Select your vehicle model to find out if you could be
                          eligible for compensation
                        </h6>
                        <div className="age_brdr">
                          <div className="arow">
                            <img
                              src={img03}
                              alt=""
                            />
                          </div>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn"
                          >
                            A-CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn"
                          >
                            B-CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn"
                          >
                            C-CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            CLA
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            CITAN
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            CLS CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            E-CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            G-CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            GLC
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            GLE
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            GLK
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            GLS 350D
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            M CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            ML
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            S-CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            SLK
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            SPRINTER
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            V-CLASS
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            VITO
                          </AnchorAdv>
                          <AnchorAdv
                            dataId="MID_PARA_NUM"
                            className="btn firstgoldbtn "
                          >
                            Other
                          </AnchorAdv>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p>
                  Thousands of motorists with diesel vehicles in the UK{" "}
                  <AnchorAdv dataId="PARA_TEXT_LINK_3">
                    <u>
                      <b>have already signed up to make a claim.</b>
                    </u>
                  </AnchorAdv>{" "}
                  If you have ever owned, leased or financed a Mercedes-Benz diesel model that was manufactured between 2008 - 2020, you could be eligible to claim thousands per vehicle. If you have owned
                  more than one affected vehicle you could be entitled to claim
                  for each.
                </p>
                <AnchorAdv dataId="boxers">
                  <button type="button" className="boxer">
                    <div className="btnhed">
                      <span>
                        <b>
                          If your vehicle is listed below, there is a good
                          chance you are eligible for £1,000s in compensation.
                        </b>
                      </span>
                    </div>
                    <div className="texter">
                      <p>
                        <b>Mercedes:</b> A-CLASS, B-CLASS, C-CLASS, CLA, CITAN,
                        CLS CLASS, E-CLASS, G-CLASS, GLC, GLE, GLK, GLS 350D, M
                        CLASS, ML, S-CLASS, SLK, SPRINTER, V-CLASS, VITO, Many
                        Others
                      </p>
                    </div>
                  </button>
                </AnchorAdv>

                <h5>How To Submit A Claim </h5>
                <p>
                  If you haven’t submitted your claim yet, join the thousands of
                  other motorists and take action.
                </p>
                <p>
                  <AnchorAdv dataId="FOLLWS">
                    <b>
                      <u>Follow the steps below to start your claim today.</u>
                    </b>
                  </AnchorAdv>
                </p>
                <p>
                  <b>Step 1: </b>Select your country on the map below.
                </p>
                <p>
                  <b>Step 2: </b>Answer a few simple questions to find out if
                  you can claim compensation.
                </p>
                <div className="cta_sec_dsk">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12 banner11 text-center">
                      <AnchorAdv dataId="MAIN_CTA_MAP">
                        {" "}
                        <img
                          src={img04}
                          alt=""
                          className="mt-2 map_image"
                        />{" "}
                      </AnchorAdv>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <AnchorAdv className="yellow_btn btn" dataId="MAIN_CTA_BTN">
                    START YOUR CLAIM
                  </AnchorAdv>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12 d-lg-block d-md-none d-sm-none d-none text-center">
                <div className="sidebar fixedsticky">
                  <AnchorAdv dataId="RIGHT_SITE_BANNER_2">
                    <img
                      src={img05}
                      alt=""
                      className="side_ban2"
                    />
                  </AnchorAdv>
                  <AnchorAdv dataId="RIGHT_SITE_BANNER_1">
                    <img
                      src={img02}
                      alt=""
                      className="side_ban"
                    />
                  </AnchorAdv>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AdvFooter />
      </div>
    </>
  );
};

export default TCG_YH_DE_V1;
