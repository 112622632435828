import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';
import logoimg from '../../../../assets/img/logo.png';

const Header = () => {
  return (
    <>
       <header>
         <div class="container">
            <div class="row">
               <div class="col-lg-4 logo">
                  <img src={logoimg} alt=""/>
               </div>
               <div class="col-lg-2 d-md-block d-sm-none d-none text-center ">
                
               </div>
               <div class="col-lg-6 d-md-block d-sm-none d-none text-center">
                  <h3>Mercedes Diesel Emission Claim Registration</h3>
               </div>
            </div>
         </div>
      </header>
    </>
  );
};

export default Header;