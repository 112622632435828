import React from "react";
import AnchorAdv from "../../../UI/AnchorAdv";
import FbViewPixel from "../../../../Utility/FbViewPixel";
import logoimg from '../../../../assets/img/logo.png';
import img02 from '../../../../assets/img/DISELAD.png';

const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <header style={{ border: "none" }} className="flex-header">
        <div className="container">
          <div className="col-lg-12 col-md-12 d-none d-md-block text-center">
            <p>Advertorial</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-7 logo flex-column">
              <AnchorAdv dataId="SITE_lOGO" className="flex-column-inner">
                <img src={logoimg} alt="" />
              </AnchorAdv>
            </div>

            <div className="col-lg-8 col-md-8 d-none d-md-block bnr_top">
              <AnchorAdv dataId="TOP_SITE_BANNER">
                <img
                  src={img02}
                  className="t_bnr img-fluid"
                />
              </AnchorAdv>
            </div>

            <div className="d-md-none col-sm-6 col-5 mob_view text-center flex-column">
              <p className="flex-column-inner">Advertorial</p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default AdvHeader;
