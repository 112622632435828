import React ,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import logoImg from "../../assets/img/logo.png";
import thumbImg from "../../assets/img/thumb.png";
import foot_logoImg from "../../assets/img/foot_logo.jpg";
import PrivacyPolicy from "../Includes/Layouts/PrivacyPolicy";
import Transparency from "../Includes/Layouts/Transparency";
import Terms from '../Includes/Layouts/Terms';


const Thankyou = () => {
  localStorage.clear();
  const [showprivacy, setshowprivacy] = useState(false);
  const [showpcookies, setshowpcookies] = useState(false);
  const [showterms, setshowterms] = useState(false);

  const handleprivacyShow = () => {
    console.log("privacy");
    setshowprivacy(true);
  };
  const handleprivacyClose = () => {
    setshowprivacy(false);
  };
  const handleCookieShow = () => {
    console.log("cookie");
    setshowpcookies(true);
  };
  const handleCookieHide = () => {
    setshowpcookies(false);
  }; 
  const handleTermsShow = () => {
    console.log("terms");
    setshowterms(true);
  };
  const handleTermsHide = () => {
    setshowterms(false);
  };
  return (
    <>
    <div className='kelllenkner'>
       <header>
         <div className="container">
            <div className="row">
               <div className="col-lg-4 logo">
                  <img src={logoImg} alt=""/>
               </div>
               <div className="col-lg-2 d-md-block d-sm-none d-none text-center ">
                 
               </div>
               <div className="col-lg-6 d-md-block d-sm-none d-none text-center">
                  <h3>Mercedes Diesel Emission Claim Registration</h3>
               </div>
            </div>
         </div>
      </header>

      <section className='bnr_part thanku'>
         <div className="container">
            <div className="row">

            <div className="col-lg-10 offset-lg-1 col-sm-02 col-12 mp0  text-center animated bounceInDown">
                  <img src={thumbImg} alt=""/>
                  <h1 className="animated zoomInUp">Thank you</h1>
                  <p>You have successfully submitted your claim</p>
              </div>
                  
            </div>
         </div>
      </section>


      <footer>
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-left">
                  <ul>
                     {/* <li><a href="javascript:void(0)"  onClick={handleTermsShow}>Terms & Conditions  </a> </li>
                     <li><a href="javascript:void(0)"  onClick={handleprivacyShow}>Privacy Policy</a> </li>
                     <li><a href="javascript:void(0)" onClick={handleCookieShow}>Transparency Statement</a></li> */}
                  </ul>
                  <p style={{paddingTop : "67px"}}>©johnsonlawgroup.co.uk 2022 </p>
               </div>
               <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                  <img src={logoImg}/>
               </div>
               <div className="col-lg-2 col-md-2 col-sm-12 col-6">
                  <p>
                     <strong>Johnson Law Group</strong><br/>
                    76 King Street, Manchester<br/>
                    England, M2 4NH<br/>
                    Tel: 0161 5132305<br/><br/>
                    <a href="mailto:info@johnsonlawgroup.co.uk" target="_blank">info@johnsonlawgroup.co.uk</a>

                  </p>
               </div>
               {/* <div className="col-lg-2 col-md-2 col-sm-12 col-6">
                  <img src={foot_logoImg} alt="" className="foot_logo" />
               </div> */}
            </div>
         </div>
      </footer>
      <PrivacyPolicy showstatus={showprivacy} hidepopup={handleprivacyClose} />
      <Transparency showstatus={showpcookies} hidepopup={handleCookieHide} />
      <Terms showstatus={showterms} hidepopup={handleTermsHide} />
        </div>
    </>
  )
}

export default Thankyou;
