import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../UI/InputButton";
import { useSignature } from "../../Hooks/useSignature";
import { signCheckText } from "../../Constants/Constants";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../Utility/CheckUUID";
import  logoImg  from "../../assets/img/logo.png";
import  logofoto  from "../../assets/img/foot_logo.jpg";
import Footer from "../Includes/Layouts/DE_JLG_1/Footer";
import Header from "../Includes/Layouts/DE_JLG_1/Header";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
import DBA from "../../assets/docs/DBA_explanation.pdf";
import ClientCare from "../../assets/docs/London_Bridge Emissions_DBA_CP_(final)_18_November_2022.pdf";
import GTMDataLayer from "../Includes/Layouts/GTMDataLayer";

const initialState = {
  isChecked: true,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const Signature = () => {
  const  {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('','signature');
   console.log(DynamicRouteNextPage);
  const { isCheck} = CheckUUID();
  //isCheck();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        GTMDataLayer({
          question: "Signature", 
        });
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (response.data.status === "Success") {
          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == '') {
            localStorage.setItem("formData", JSON.stringify(formdata));
          }else{
            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
          history.push(
            "/"+DynamicRouteNextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName
          );
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
    <GetVisitorsParams />
      <Header/>
        <div className="kelllenkner">
          <section className="bnr_part">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                      <div className="signature-part">
                        <div className="col-12 text-center">
                            <h2 className="h2_style text-center text-white mb-0 fw-bolder py-3 px-2"> Finally, we need your electronic signature below to proceed. Please note your electronic signature will be included on all forms below</h2>
                        </div>  
                          <div className="sign-popstyle border-0">
                              <div className="ensure_li">
                                    <div className="offset-lg-4 col-lg-5 col-12 sign-question nopadmob">
                                      <ul className="signature-ul text-center p-0 border-0">
                                        <li className="d-block text-start fs-6"> Sign using your finger, mouse or stylus</li>
                                        <li className="d-block text-start fs-6"> Keep it fully contained within the box</li>
                                        <li className="d-block text-start fs-6"> Ensure it is a true likeness of your signature</li>
                                      </ul>
                                    </div>
                                    <div className="signature_canvaseWrap">
                                      <div>
                                          {(() => {
                                            if (deviceType === "mobile") {
                                              return (
                                                <MobileOnlyView className="mob-canvas">
                                                  <SignatureCanvas
                                                    canvasProps={{
                                                      id:"myID",
                                                      width: 320,
                                                      height:225,
                                                      className:
                                                        "signature-pad sign-bg anim_bt d-lg-block mob-canvas",
                                                    }}
                                                    ref={signPadRef}
                                                    onEnd={() => {
                                                      if (!signPadRef.current.isEmpty()) {
                                                        dispatch({
                                                          type: "signPadChange",
                                                          payload: { value: false },
                                                        });
                                                        dispatch({
                                                          type: "validSignature",
                                                          payload: { value: false },
                                                        });
                                                        // document
                                                        //   .getElementById("acceptterms")
                                                        //   .classList.add("anim_ylw");
                                                      }
                                                    }}
                                                  />
                                                </MobileOnlyView>
                                              );
                                            } else if (deviceType === "tablet" || isIPad13) {
                                              return (
                                                <TabletView className="tab-canvas">
                                                  <SignatureCanvas
                                                    canvasProps={{
                                                      id:"myID",
                                                      width: 580,
                                                      height: 300,
                                                      className:
                                                        "signature-pad sign-bg anim_bt d-lg-block tab-canvas",
                                                    }}
                                                    ref={signPadRef}
                                                    onEnd={() => {
                                                      if (!signPadRef.current.isEmpty()) {
                                                        dispatch({
                                                          type: "signPadChange",
                                                          payload: { value: false },
                                                        });
                                                        dispatch({
                                                          type: "validSignature",
                                                          payload: { value: false },
                                                        });
                                                        // document
                                                        //   .getElementById("acceptterms")
                                                        //   .classList.add("anim_ylw");
                                                      }
                                                    }}
                                                  />
                                                </TabletView>
                                              );
                                            } else {
                                              return (
                                                <BrowserView className="web-canvas">
                                                 
                                                  <SignatureCanvas
                                                    canvasProps={{
                                                      id:"myID",
                                                     // width: 950,
                                                      height: 490,
                                                      className:
                                                        "signature-pad sign-bg  anim_bt d-lg-block web-canvas",
                                                    }}
                                                    ref={signPadRef}
                                                    onEnd={() => {
                                                      if (!signPadRef.current.isEmpty()) {
                                                        dispatch({
                                                          type: "signPadChange",
                                                          payload: { value: false },
                                                        });
                                                        dispatch({
                                                          type: "validSignature",
                                                          payload: { value: false },
                                                        });
                                                        // document
                                                        //   .getElementById("acceptterms")
                                                        //   .classList.add("anim_ylw");
                                                      }
                                                    }}
                                                  />
                                                  
                                                </BrowserView>
                                              );
                                            }
                                          })()}

                                          {state.isAgreed && (
                                            <div className="sign_msg col-12 text-center">
                                              Please tick to show your authorisation for us to
                                              proceed.
                                            </div>
                                          )}
                                          {state.isCanvasEmpty && (
                                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_required">
                                              Signature is required!
                                            </div>
                                          )}
                                          {state.isValid && (
                                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">
                                              Draw valid signature!
                                            </div>
                                          )}
                                      </div>
                                    </div>
                              </div>
                              
                              <div className="text-center bord" id="acceptterms_outer">
                                <div className="custom-control custom-checkbox">
                                {/* <CheckBox
                                    dataId="acceptterms"
                                    anchorText=""
                                    anchorStyle=""
                                    href=""
                                    onChange={(e) => setIsChecked(!isChecked)}
                                    className="form-check-input"
                                    labelClassName="form-check-label sign-custom-control-label"
                                    value={isChecked}
                                  /> */}
                                  <p><strong><em>Signed with an intention to enter into a legally binding contract</em></strong></p>
                                 
                                  <p>A copy of the <a href={`${ClientCare}`}
                                           target="_blank">Client Care Pack</a> & <a target="_blank" href={`${DBA}`}>DBA explanation </a> will be sent to you via email</p>
                                    {/* <label className="custom-control-label d-inline" for="acceptterms"> Signed with an intention to enter into a legally binding contract A copy of the Client Care Pack & DBA explanation will be sent to you via email.
                                    </label> */}
                                </div>
                              </div>
                  
                          </div>
                          <div className="modal-footer" style={{display: 'block'}}>
                              <input
                                type="button"
                                id=""
                                name=""
                                className="btn-clear"
                                value="Clear"
                                onClick={clearSign}
                              />
                              <input
                                className="btn-submit"
                                type="button"
                                onClick={handleClick}
                                value="Submit"
                              />
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          </section>
        <Footer/>
    </div>
    </>
  );
};
export default Signature;
