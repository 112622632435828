import { Api } from "../api/Api";

export const useFollowupUserIdUpload = () => {
  const saveFollowupUserIdUpload = async (
    upload_data,
    followup_data,
    message_type
  ) => {
    const idUploadSubmit = await Api.post("/api/v1/data-ingestion-pipeline", {
      upload_data,
      followup_data,
      message_type,
    });
    return idUploadSubmit;
  };
  return {
    saveFollowupUserIdUpload
  };
};
