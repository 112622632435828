import React, { useContext, useEffect, useState, useRef } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { userInfo } from "../../Hooks/userInfo";
import { FormData } from "../../Utility/FormData";
import logoImg from "../../assets/img/logo.png";
import signImg from "../../assets/img/sign-1.jpg";
import logoImg1 from "../../assets/img/logo.jpg";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
import { Link, useHistory } from "react-router-dom";
import { useAgreeTermsIngestion } from "../../Hooks/useAgreeTermsIngestion";

const PreviewLoa = () => {
  const { saveAgreeTermsIngestion } = useAgreeTermsIngestion();
  const { isCheck } = CheckUUID();
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [address, setAddress] = useState();
  const [userDob, setUserDob] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [userSign, setUserSign] = useState();
  const { getUserInfo } = userInfo();
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('', 'preview');
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [currentUuid, setCurrentUuid] = useState();
  const [bankName, setBankName] = useState();
  const { setFormData } = FormData();
  const [userSortCode, setUserSortCode] = useState([]);
  const [vehicleMake, setVehicleMake] = useState();
  const [fName, setFname] = useState();
  const [lName, setLname] = useState();
  const [title, setTitle] = useState();
  const [signShow, setSignShow] = useState(0);
  const signref_1 = useRef();
  const signref_2 = useRef();
  const signref_3 = useRef();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData) {
        const uuid = visitorData.uuid;
        setCurrentUuid(uuid);
      }
    })();
  }, [visitorParameters]);
  let customUuid = '';
  let customSource = '';
  const query = new URLSearchParams(window.location.search)
  const query_uuid = query.get("uuid");
  const query_source = query.get("source");
  const local_uuid = localStorage.getItem('uuid');
  customUuid = (query_uuid) ? query_uuid : local_uuid;
  customSource = (query_source) ? query_source : "live";

  useEffect(async () => {
    const formdata = JSON.parse(localStorage.getItem('formData'));
    const customPreviousData = JSON.parse(localStorage.getItem('customPreviousData'));
    const userBank = localStorage.getItem('bankName');
    if (formdata === undefined || formdata === null || formdata == '') {
      (async () => {
        const response = await getUserInfo(
          customUuid

        );
        const set_formdata = setFormData(
          response
        );
        let dataLength = (response.data.response).length;
        if (dataLength > 0) {
          const userId = (response.data.response[0].id) ? response.data.response[0].id : '';
          const userName = (response.data.response[0].first_name) ? response.data.response[0].title + ' ' + Capitalize(response.data.response[0].first_name) + ' ' + Capitalize(response.data.response[0].last_name) : '';
          const usersign = (response.data.response[0].signature_image) ? response.data.response[0].signature_image : '';
          const userdob = (response.data.response[0].user_dob) ? response.data.response[0].user_dob : '';
          let userAddress = '';
          if (response.data.response[0].address3 !== '') {
            userAddress = (response.data.response[0].housenumber) + ', ' + (response.data.response[0].address3) + ', ' + (response.data.response[0].town) + ', ' + (response.data.response[0].county) + ', ' + (response.data.response[0].country) + ', ' + (response.data.response[0].postcode).toUpperCase();
          } else {
            userAddress = (response.data.response[0].housenumber) + ', ' + (response.data.response[0].town) + ', ' + (response.data.response[0].county) + ', ' + (response.data.response[0].country) + ', ' + (response.data.response[0].postcode).toUpperCase();
          }
          const bankname = (response.data.response[0].bank_name) ? response.data.response[0].bank_name : null;
          const vehicle_make = (response.data.response[0].vehicle_make) ? response.data.response[0].vehicle_make : null;
          const f_name = (response.data.response[0].first_name) ? Capitalize(response.data.response[0].first_name) : null;
          const l_name = (response.data.response[0].last_name) ? Capitalize(response.data.response[0].last_name) : null;
          const user_title = (response.data.response[0].title) ? response.data.response[0].title : null;
          setBankName(bankname);
          setUserName(userName);
          setUserId(userId);
          setUserSign(usersign);
          setUserDob(userdob);
          setAddress(userAddress);
          setVehicleMake(vehicle_make);
          setFname(f_name);
          setLname(l_name);
          setTitle(user_title);
          let timeNow = new Date().toLocaleString();
          timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
          setCurrentDate(timeNow);
        }
      })();
    } else {
      const userName = (formdata) ? formdata.lstSalutation + ' ' + Capitalize(formdata.txtFName) + ' ' + Capitalize(formdata.txtLName) : '';
      const userdob = (formdata) ? formdata.DobDay + '/' + formdata.DobMonth + '/' + formdata.DobYear : '';
      const userSign = (formdata) ? formdata.user_sign : '';
      let userAddress = '';
      if (formdata) {
        if (formdata.txtAddress3 !== '') {
          userAddress = (formdata.txtHouseNumber + ', ' + formdata.txtAddress3 + ', ' + formdata.txtTown + ', ' + formdata.txtCounty + ', ' + formdata.txtCountry + ', ' + (formdata.txtPostCode).toUpperCase());
        } else {
          userAddress = (formdata.txtHouseNumber + ', ' + formdata.txtTown + ', ' + formdata.txtCounty + ', ' + formdata.txtCountry + ', ' + (formdata.txtPostCode).toUpperCase());
        }
      }
      const f_name = (formdata) ? Capitalize(formdata.txtFName) : null;
      const l_name = (formdata) ? Capitalize(formdata.txtLName) : null;
      const user_title = (formdata) ? formdata.lstSalutation : null;

      setBankName(userBank);
      setUserName(userName);
      setAddress(userAddress);
      setUserDob(userdob);
      setUserSign(userSign);
      setFname(f_name);
      setLname(l_name);
      setTitle(user_title);
      let timeNow = new Date().toLocaleString();
      timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
      setCurrentDate(timeNow);
      (async () => {
        const response = await getUserInfo(
          customUuid

        );
        let dataLength = (response.data.response).length;
        if (dataLength > 0) {
          const userId = (response.data.response[0].id) ? response.data.response[0].id : '';
          const vehicle_make = (response.data.response[0].vehicle_make) ? response.data.response[0].vehicle_make : null;
          setUserId(userId);
          setVehicleMake(vehicle_make);
        }
      })();
    }
  }, []);


  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const showSign = () => {
    let signDiv = (parseInt(signShow) + 1);
    setSignShow(signDiv);
    var scrollToRef = (signDiv == 1) ? signref_1 : ((signDiv == 2) ? signref_2 : (signDiv == 3) ? signref_3 : '');
    if (scrollToRef != '') {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      pageChange();
    }

  }

  const pageChange = async () => {
    const response = await saveAgreeTermsIngestion(customUuid, userId, 1, 'live');
    history.push(
      "/" + DynamicRouteNextPage + "?uuid=" + customUuid + "&splitName=" + DynamicRouteSplitName
    );
  }


  // const uuid = getUuid();
  return (
    <div className="previewpage pt-3" >
      <section className="container-1 my-5">
        <header>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div>
              <img className="img-fluid" src={logoImg1} alt="" />
            </div>
            <div className="text-start text-md-end">
              <p>76 King Street, Manchester, M2 4NH</p>
              <p>Tel: 0161 513 2305</p>
              <p>Fax: 0161 513 2306</p>
              <p> Email: <a href="#" className="text-decoration-none text-dark"> info@johnsonlawgroup.co.uk</a></p>
              <p>Website: <a href="#" className="text-decoration-none text-dark"> www.johnsonlawgroup.co.uk</a> </p>
            </div>
          </div>
        </header>

        <div>
          <h4 className="text-center"> Client Care Pack Explanation </h4>
          <p> This document sets out how our retainer works. A more detailed explanation is contained in our
            terms and conditions: </p>
          <ol>
            <li>
              Our agreement with you is a on a <b><i>“no-win, no-fee”</i></b> basis. This means that if we do not win,
              and you keep to our terms of business, you will not be out of pocket.
            </li>
            <li>
              This is also called a Damages Based Agreement (DBA).
            </li>
            <li>
              Under a DBA, we agree to work for you on your case and not bill you for our services until
              the end of your case – and only if you win. We will also ensure all of your disbursements are
              funded so you do not have to pay any money up front in order to pursue your case.
            </li>
            <li>
              In return, you agree to pay to us a percentage of the damages you are awarded (50%
              inclusive of VAT). We will pay for any barrister’s fees from that percentage
            </li>
            <li>
              If you win under a DBA, you should be entitled to the majority of your legal costs from the
              other side. Your legal costs consist of our basic charges plus any disbursements and
              expenses we incur on your behalf, such as: experts (but not any after the event insurance
              premium). We will limit your liability to pay these disbursements and expenses to what we
              recover from your opponent. And we will pay your barristers fees out of the percentage of
              damages you pay us. You will need to pay any legal expense insurance premium in addition.
            </li>
            <li>
              Any money we recover from the other side in respect of our basic fees and barristers fees
              will be refunded to you, reducing the size of the contribution you have to make to us out of
              the compensation you are awarded.
            </li>
            <li>
              If you lose, you do not need to worry about paying the Defendant(s) costs and any
              disbursements we incur on your behalf because we will have arranged insurance to protect
              you against this. The insurance policy will also pay off its own premium if you lose.
            </li>
          </ol>
          <p className="text-decoration-underline"> <i> <b>Summary</b></i></p>
          <ol start="8">
            <li>
              You will never be out of pocket for having instructed us: we will never ask you for money up
              front to finance your case and you will not have to pay any of your own money if you lose.
            </li>
          </ol>
          <p className="text-decoration-underline"> <i> <b> Help us to help you.</b></i></p>
          <ol start="9">
            <li>
              We need your continued cooperation so please only sign up if you are willing to provide this.
              You can appreciate we are taking a big risk with a no-win, no-fee agreement. If you fail to
              cooperate, then we will unfortunately no longer be able to help or act for you.
            </li>
            <li>
              If you change your mind about proceeding with your claim, you simply need to inform us of
              this in writing within 14 days from signing up.
            </li>

          </ol>
        </div>

        <div className="page-footer px-5">
          <p className="footer-text-1"> You are under no obligation to use our services. Other law firms may have different
            terms and conditions. Some may charge
            less. In entering an agreement with JLG you agree to the charges we apply to our retainers with clients.</p>
          <p className="footer-text-2"> JLG Legal Limited trading as Johnson Law Group
            Registered Office: 76 King Street, Manchester, Greater Manchester, England M2 4NH
            Company Registration Number: 12177815
            SRA Number: 664813
          </p>
        </div>
      </section>

      <section className="container-1 my-5">
        <header>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div>
              <img className="img-fluid" src={logoImg1} alt="" />
            </div>
            <div className="text-start text-md-end">
              <p>76 King Street, Manchester, M2 4NH</p>
              <p>Tel: 0161 513 2305</p>
              <p>Fax: 0161 513 2306</p>
              <p> Email: <a href="#" className="text-decoration-none text-dark"> info@johnsonlawgroup.co.uk</a></p>
              <p>Website: <a href="#" className="text-decoration-none text-dark"> www.johnsonlawgroup.co.uk</a> </p>
            </div>
          </div>
        </header>

        <section className="py-3">
          <p className="py-3">{userName}</p>
          <p className="pt-2"> {currentDate} </p>
          <p> Our Reference: {userId} </p>
          <h4> CONGRATULATIONS! YOU ARE NOW A CLIENT</h4>
          <p> Dear {title} {lName} </p>
          <h5> Your Diesel Emissions Claim against {vehicleMake}</h5>
          <p> Thank you for agreeing to instruct Johnson Law Group (“JLG”) in respect of the above matter. This
            letter provides you with important documentation and information about your case.</p>
          <p className="text-decoration-underline"> Your claim</p>
          <p> You have asked us to investigate if we can bring a claim on your behalf against {vehicleMake} for compensation in relation to them fitting a prohibited “defeat device” to a vehicle
            you purchased which gave false test readings to cheat EU and UK emission laws.</p>
          <p className="text-decoration-underline"> Responsibility for your case</p>
          <p>Our Managing Director, Jamie Patton, will be responsible for overall conduct of your file. He is a
            senior solicitor with over 25 years litigation experience and a Grade A fee earner. The grading of our
            fee earners and their charge out rates is explained in more detail in the attached documentation. He
            will however be assisted on your file by other members of our Emissions Team who will assume day
            to day responsibility for the progression of your case.</p>
          <p className="text-decoration-underline"> Our agreement with you</p>
          <p> Johnson Law Group has agreed to enter into a <b>“No win – No fee”</b> arrangement with you to help you
            pursue this claim. The formal term for this is a “Damages Based Agreement” (“DBA”). We have
            already explained to you that this means we will not charge you if you lose your case and we will
            never ask you for money upfront to help finance your claim. In return, we take a proportion of your
            damages if you win.</p>
          <p>This letter, the DBA, the Litigation Management Agreement (“LMA”), our Terms of Business and our
            two forms of authority all form the basis of your contract with us (“the Retainer”) and create a
            legally binding agreement with JLG.</p>
          <p>We have attached a leaflet with this letter called “Client Care Pack Explanation” which provides a
            helpful reminder on how our no win - no fee agreement works in practice but would recommend</p>
        </section>

        <div className="page-footer">
          <p className="footer-text-2"> JLG Legal Limited trading as Johnson Law Group<br />
            Registered Office: 76 King Street, Manchester, Greater Manchester, England M2 4NH <br />
            Company Registration Number: 12177815<br />
            SRA Number: 664813.</p>
        </div>
      </section>

      <section className="container-1 my-5" >
        <p> you take independent legal advice in respect of the same should you wish to, as this documentation
          is very important.</p>
        <p className="text-decoration-underline"> Our agreement with you</p>
        <p> Please see a schedule attached to this letter setting out the documents that go to make up your
          Retainer with us.</p>
        <p>In addition, we attach: The Emissions Group Litigation – How it Works - an information sheet to
          explain how we anticipate this group litigation will play out as well as answer some basic questions.
          We will however provide you with regular updates on your case.</p>
        <p className="text-decoration-underline">Your acceptance of our Terms and Conditions</p>
        <p> Your continued instructions to us after signing this Agreement, constitutes your acceptance of the
          terms set out in our Retainer and continued agreement to the contents the Retainer unless you tell
          us to the contrary.</p>
        <p> If you are contacted by another firm of solicitors or someone else claiming to represent your
          opponent, please tell them that you have already appointed JLG to act on your behalf. You can only
          appoint one firm to act for you, and any contact from a third party may lead to an attempt to settle
          your claim for less than it is worth.</p>

        <p className="text-decoration-underline">Communication with us – welcome to “Touchpoint”</p>
        <p> You will shortly be receiving an email from us confirming we have now set your case up on our
          system. This also enables you access to our client portal, Touchpoint.</p>
        <p>Touchpoint is your client portal that allows you direct access to our system as a client. You can view
          certain communications to you; check your own details are correct and up to date; upload
          documents direct onto our case management system and even sign documents such as witness
          statements.</p>
        <p>Our plan will be to send out regular communications to you via both email and Touchpoint so you
          will not need to chase us up for any updates into the progress of your case.</p>

        <p className="text-decoration-underline">Electronic verification</p>
        <p> All solicitors must now carry out certain due diligence and identification checks against new clients
          to ensure they are who they say they are. We call this our Know Your Client (“KYC”) procedure. JLG
          carries out an electronic check against each new client that comes to us directly through its own
          platform. This leaves a small “footprint” on your records but will not affect your credit rating. The
          checks are however standard industry practice and are nothing to be worried about. We will need
          your date of birth in order to use this service.</p>
        <p>We note however in your instance you have come to us through a third-party claims management
          company who has already electronically verified your address and vehicle details using the same
          method we do prior to you being introduced to us. We may, from time to time, update our own
          records and carry out further checks or ask you to provide us with copy ID documentation. We
          reserve the right to cease acting for you should you refuse to provide this when requested.</p>
        <p className="text-decoration-underline">Time Frame</p>
        <p> It is difficult to estimate how quickly it will take your matter to resolve. This timeframe is largely
          dependent on regulatory timescales, evidence gathering and the complexities of the case. With any
          group litigation, a timetable will also be set by the court and when that happens we will provide you</p>
      </section>

      <section className="container-1 my-5" >
        <p> with further information on timescales. The process could conclude within 6 months but is much
          more likely to take several years given the many thousands of claimants involved in this action.</p>
        <p> JLG will strive to settle your claim as quickly as possible without compromising the integrity of your
          claim and will always keep you regularly updated on the progress of your case throughout..</p>
        <p>In addition, we attach: The Emissions Group Litigation – How it Works - an information sheet to
          explain how we anticipate this group litigation will play out as well as answer some basic questions.
          We will however provide you with regular updates on your case.</p>
        <p className="text-decoration-underline">Referral Arrangement</p>
        <p> You have been referred to us as a client by a marketing company called LBM trading as a number of different trading styles including dieselfunds.co.uk. We have a financial arrangement with this company whereby we pay them a conditional fee of £150 at the point at which we accept your claim.You are not responsible for paying that referral fee and the fees you will be charged under your Retainer in respect of this matter will not be affected by this financial arrangement.</p>

        <p className="text-decoration-underline">Next steps</p>
        <p> We will continue to update you as to the progress of your matter and what additional assistance we
          need from you to enable us to adequately represent your interests in your claim. In the meantime
          however, if you have any queries or concerns, then please do not hesitate to get in touch.</p>
        <p>Kind regards</p>
        <p>Yours sincerely</p>
        <div>
          <img src={signImg} className="img-fluid" alt="" />
        </div>
        <p> <b> Jamie Patton</b></p>
        <p> <b> Managing Director</b></p>
        <p> <b> Johnson Law Group</b></p>
        <p> <b> Email:</b> <a href=""> emissions@johnsonlawgroup.co.uk</a></p>


        <div className="page-footer pt-5">
          <p className="footer-text-2 mt-5 pt-5"> JLG Legal Limited trading as Johnson Law Group<br />
            Registered Office: 76 King Street, Manchester, Greater Manchester, England M2 4NH <br />
            Company Registration Number: 12177815<br />
            SRA Number: 664813.</p>
        </div>

      </section>

      <section className="container-1 my-5" >
        <h5 className="text-center text-decoration-underline"> YOUR CLIENT CARE PACK</h5>
        <p> We set out below the list of documents in this client care pack. If you are receiving our client care
          pack via email to “e-sign” then the system will insert your signatures where they need to be. If you
          are receiving our pack by post, this table may help:</p>
        <table className="my-5">
          <tbody>
            <tr>
              <th style={{ width: '5%' }} > </th>
              <th style={{ width: '25%' }} className="text-center"> Document</th>
              <th style={{ width: '45%' }} className="text-center"> What it’s for</th>
              <th style={{ width: '25%' }} className="text-center"> What to do with it</th>
            </tr>
            <tr>
              <td> 1</td>
              <td> Damages Based
                Agreement</td>
              <td> Your No Win-No Fee arrangement. It
                sets out what you pay for the work we
                do for you.</td>
              <td className="text-danger">
                <span> <b>Your signature is required</b></span>
              </td>
            </tr>
            <tr>
              <td> 2</td>
              <td> Litigation
                Management
                Agreement</td>
              <td>
                This sets out how we can accept
                instructions on your behalf from a
                “client committee” and also how our
                common costs work in particular when
                we are seeking to recover them from
                your opponent.
              </td>
              <td> Please keep a copy</td>
            </tr>
            <tr>
              <td> 3</td>
              <td> Funding Information
                Sheet</td>
              <td>
                To provide you with as much
                information about the various options
                you have for funding your case and for
                you to confirm that you are happy to
                instruct us under the agreed DBA model.
              </td>
              <td> Please keep a copy</td>
            </tr>
            <tr>
              <td> 4</td>
              <td> Legal Expense
                Insurance and Union
                Cover Checker</td>
              <td> To explain the need to check for
                litigation insurance and confirm if you
                have none.</td>
              <td> Please keep a copy</td>
            </tr>
            <tr>
              <td> 5</td>
              <td> Payment Calculation
                Sheet</td>
              <td> This explains why we have calculated
                our 50% DBA payment on your case.</td>
              <td> Please keep a copy</td>
            </tr>
            <tr>
              <td>6</td>
              <td> Form of Authority (1)</td>
              <td> This enables us to request documents on
                your behalf from third parties who hold
                information about you.</td>
              <td className="text-danger">
                <span> <b>Your signature is required</b></span>
              </td>
            </tr>
            <tr>
              <td> 7</td>
              <td>
                Form of Authority (2)
              </td>
              <td>
                This confirms that you are happy to
                enter into our Retainer with us without
                taking independent legal advice and
                agree to us signing statements of truth
                on your behalf based on the information
                you provide.
              </td>
              <td className="text-danger">
                <span> <b>Your signature is required</b></span>
              </td>
            </tr>
            <tr>
              <td>
                8
              </td>
              <td>
                JLG’s General Terms
                of Business that apply
                to all clients
              </td>
              <td>
                This is an important document that
                explains our responsibilities to you and
                such things as how we handle your
                money.
              </td>
              <td> Please keep a copy</td>
            </tr>
          </tbody>
        </table>
        <p > Your signature on our DBA is confirmation of your agreement to all other documents
          listed above
          that go to make up our Retainer.</p>
      </section>

      <section className="container-1 my-5">
        <h5 className="text-center text-decoration-underline"> DAMAGES-BASED AGREEMENT</h5>
        <p> <b> This agreement (the ‘Agreement’) is made on the {currentDate} </b></p>
        <p> <b> Between: {fName} {lName}
          (“You”) of {address}</b></p>
        <p> <b> </b></p>
        <p> <b>and</b></p>
        <p> <b> JLG Legal Limited, trading as Johnson Law Group (“We” or “Us”) </b> of 76 King Street,<br />
          Manchester, M2 4NH <br /> (each a <b> “Party”</b> and together the <b>“Parties”</b> ).</p>
        <p> <b> Preamble</b></p>
        <p>
          This Agreement is a legally binding contract and, in so far as they apply to any part of this
          Agreement, is made pursuant to the Damages Based Agreements Regulations 2013 (SI
          2013/609) (the <b>“DBA Regulations”)</b>. It contains the terms and conditions under which We
          agree to work on Your Claim.
        </p>
        <p> The definitions in Schedule 1 to this Agreement are part of this Agreement.</p>
        <p> <b> Summary of Agreement</b></p>
        <p> This Summary is provided for general guidance only. It is subject to the terms of this
          Agreement as set out below. In the event of any inconsistency it will give way to those
          terms. It should not be relied on in place of reading the full Agreement.</p>
        <p> In summary, this Agreement is intended to provide that;</p>
        <ul>
          <li> If You Win Your Claim;</li>
          <ul>
            <li> You pay Us 50% (including any VAT) of the Damages You recover from Your
              Opponent;</li>
            <li> Clause 4 explains why We think 50% is fair and reasonable and if You enter into
              this Agreement, You are saying You agree;</li>
            <li> You don’t have to pay us anything extra for Counsel Fees. We will pay those out
              of the percentage amount that you pay us, not out of the remainder of your
              compensation;</li>
            <li>
              You also pay Us our Expenses, but Your liability to pay our Expenses is limited to
              sums recovered from your Opponent for our Expenses as Between the Parties
              Costs or any payments from any ATE insurance policy. This means that in practice
              You will never have to pay us anything for our Expenses;
            </li>
            <li>
              If your Opponent has to pay anything for Our Fees or Counsel Fees, You get the
              benefit of that and whatever the Opponent has to pay reduces the amount You
              have to pay Us;
            </li>
            <li className="text-decoration-underline">
              <b> In practice, this should mean that if You Win, all You have to pay Us is a
                maximum of 50% of the damages and usually less than that depending on how
                much the Opponent has to pay for our Fees and our Counsel Fees;</b>
            </li>
            <li>
              You will also have to pay the costs of any ATE insurance policy, which will be
              deducted from Your Damages (or reimburse Us for it if We have paid it);
            </li>
          </ul>
          <li> If You Lose;</li>
          <ul>
            <li> You don’t pay anything for our Fees – this is a No Win, No Fee agreement. Your
              Expenses will be covered by any ATE insurance policy;</li>
          </ul>
          <li> There may be circumstances in which our relationship ends before You Win or Lose.
            Whether You have to pay Us anything if that happens depends on why that happens and
            is set out in detail in this Agreement.</li>
        </ul>
      </section>


      <section className="container-1 my-5" >
        <h5> AGREED TERMS</h5>


        <div className="d-flex ps-5">
          <div>
            <p>1</p>
          </div>
          <div className="px-3">
            <p><b>What is covered by this Agreement</b></p>
            <ul>
              <li>Your Claim for Damages against the Opponent in relation to a “defeat device” fitted to a
                vehicle that you purchased;</li>
              <li>any appeal made by the Opponent against an Interim Order;</li>
              <li>any appeal You make against an Interim Order or an assessment of costs on Our advice or
                with Our agreement; and</li>
              <li>negotiations in relation to, and/or a court assessment of, the costs of Your Claim.</li>
            </ul>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>2</p>
          </div>
          <div className="px-3">
            <p><b>What is not covered by this Agreement</b></p>
            <ul>
              <li>any Counterclaim;</li>
              <li>any appeal You or the Opponent make against the first instance final judgment order unless
                We agree with You in writing that it will be covered by this Agreement; and</li>
              <li>any steps or proceedings to enforce a judgment, order or settlement against the Opponent,
                unless We agree with You in writing that it will be covered by this Agreement.</li>
            </ul>
            <div className="d-flex ps-3">
              <div>
                <p>2.1</p>
              </div>
              <div className="px-3">
                <p>We may be prepared to enter into a separate agreement with You to cover work in
                  relation to these situations should they arise and will discuss that with You at the
                  relevant time.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>3</p>
          </div>
          <div className="px-3">
            <p><b>Paying Us if You Win</b></p>
            <div className="d-flex ps-3">
              <div>
                <p>3.1</p>
              </div>
              <div className="px-3">
                <p>If You Win Your Claim, You pay Us the Payment, which is calculated as a percentage
                  of the Damages recovered from Your Opponent (or from another party on behalf of
                  the Opponent).</p>
              </div>
            </div>
            <div className="d-flex ps-3">
              <div>
                <p>3.2</p>
              </div>
              <div className="px-3">
                <p>The Payment payable by You will be 50% (inclusive of VAT). VAT will be payable at
                  the rate which applies at the material time (currently 20%), but, as stated, this is
                  included in the 50% percentage Payment and is not payable in addition.</p>
              </div>
            </div>
            <div className="d-flex ps-3">
              <div>
                <p>3.3</p>
              </div>
              <div className="px-3">
                <p>The Payment You are liable to pay will be reduced by any Fees or Counsel Fees that
                  have been paid by your Opponent (or which the Opponent has to pay) as Between
                  the Parties Costs, so that You only pay the Payment net of those sums. We are
                  entitled to keep those sums, as set out below. In practice, this means that We are
                  entitled to keep any Between the Parties Costs, but that You only then pay the extra,
                  if any, to take the sum payable to Us up to 50% of the Damages We have recovered
                  for You on Your behalf. Usually, this will mean that You will keep more than 50% of
                  the Damages.</p>
              </div>
            </div>
            <div className="d-flex ps-3">
              <div>
                <p>3.4</p>
              </div>
              <div className="px-3">
                <p>Whatever happens, the Payment cannot, in any circumstances, exceed an amount
                  (including VAT) equal to 50% of the sums ultimately recovered by You. Any contrary
                  statement in this Agreement is wrong and shall be subject to this clause and read
                  down accordingly.</p>
              </div>
            </div>
            <div className="d-flex ps-3">
              <div>
                <p>3.5</p>
              </div>
              <div className="px-3">
                <p>Whether You Win or Lose, You pay our Expenses. However;</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div>
            <p>3.5.1</p>
          </div>
          <div className="px-3">
            <p> The amount You are liable to pay for our Expenses is net of any sums in
              relation to our Expenses that have been paid (or in respect of which there
              is a liability on the Opponent to pay) as Between the Parties Costs and</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>3.5.2</p>
          </div>
          <div className="px-3">
            <p> Except where Clause 10 below applies (Termination), your liability to pay
              our Expenses shall be limited to the sum of any Expenses that have been
              paid (or which your Opponent has to pay) as Between the Parties Costs or
              which are met by any After the Event insurance policy.</p>
          </div>
        </div>

        <div className="d-flex">
          <div>
            <p>3.6</p>
          </div>
          <div className="px-3">
            <p> We will be entitled to keep any interest your Opponent pays in relation to our
              Expenses.</p>
          </div>
        </div>

        <div className="d-flex">
          <div>
            <p>3.7</p>
          </div>
          <div className="px-3">
            <p> In the event that You Win Your Claim, You may be entitled to seek to recover some
              of our Fees, Counsel Fees and Expenses from Your Opponent as Between the Parties
              Costs. However, even if this is the case:</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>3.7.1</p>
          </div>
          <div className="px-3">
            <p> You are unlikely to recover the full amount of the Payment payable by You
              under this Agreement from the Opponent; and</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>3.7.2</p>
          </div>
          <div className="px-3">
            <p> Any parts of our Fees which are payable by Your Opponent will usually be
              assessed or agreed by reference to a different method of calculation than
              the percentage Payment method provided for in this Agreement (the
              Court will usually assess those costs by reference to the time spent at a
              reasonable hourly rate, plus reasonable expenses incurred). This is a
              notional calculation only and does not affect Your liability under this
              Agreement (except to the extent that it is relevant to the amount of Fees
              the Opponent may be liable to pay);</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>3.7.3</p>
          </div>
          <div className="px-3">
            <p> For the purposes of this notional calculation it is agreed that the
              calculation should be approached on the same basis as set out in Clause 4
              of the Litigation Management Agreement You have agreed with Us. In
              summary, and subject to the terms of that Litigation Management
              Agreement, this is as if You (and any other client involved in the same
              collective Claim of group of Claims who has Won their Claim) has;</p>
            <div className="d-flex">
              <p> 3.7.3.1 a</p>
              <p className="ps-2"> Several Liability in respect of the Individual Costs of their Claim;</p>
            </div>
            <div className="d-flex">
              <p> 3.7.3.2a</p>
              <p className="ps-2"> Several Liability for their Proportionate Share (calculated in
                accordance with the terms of the Litigation Management
                Agreement) of any Common Costs of the Claim.</p>
            </div>

          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>3.7.4</p>
          </div>
          <div className="px-3">
            <p> You remain liable for any balance of the Payment. Your liability to pay our
              Expenses will usually be limited to the sum of any Expenses that have been
              paid (or which your Opponent has to pay) as Between the Parties Costs or
              which are met by any After the Event insurance policy.</p>
          </div>
        </div>

        <div className="d-flex">
          <div>
            <p>3.8</p>
          </div>
          <div className="px-3">
            <p> You agree that any sums in respect of our Fees, Counsel Fees and/or Expenses that
              are paid by any other party to the Claim or Proceedings (or in respect of which there
              is a liability for that other party to pay) by agreement or order of the court (i.e.
              Between the Parties Costs) shall be paid to Us in addition to the net Payment and net
              Expenses as provided for by the preceding provisions of this Agreement.</p>
          </div>
        </div>

        <div className="d-flex">
          <div>
            <p>3.9</p>
          </div>
          <div className="px-3">
            <p>You agree that any sums payable to You by way of Damages or Between the Parties
              Costs shall be paid directly to our client account by Your Opponent or any other third
              party responsible for paying those sums. If the Opponent refuse to make payment
              to Us and insist on paying You directly, You agree immediately to transfer those
              sums to our client account. Upon receipt of those sums, We are entitled to deduct
              any sums due to Us in accordance with the terms of this Agreement and any</p>
          </div>
        </div>
      </section>


      <section className="container-1 my-5" >
        <div className="ps-5">
          <p>remaining premium for ‘After the Event insurance’ which You are liable to pay. The
            balance will be paid to You.</p>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>3.10</p>
          </div>
          <div className="px-3">
            <p> If Your Opponent does not pay any Damages or Between the Parties Costs that are
              owed to You in accordance with any relevant judgment, order or agreement, You
              agree that We have the right, at our election, to take recovery action in Your name
              to enforce such liability. Our services in that regard do not form part of the services
              provided under this Agreement unless We have expressly agreed with You in writing
              that they do. In the event that We decide to take such recovery action and We have
              not agreed to do so under the terms of this Agreement, We will agree separate
              terms with You in respect of the same. In default of express further agreement, such
              services will be provided on the basis that We will be paid for such services on an
              hourly rate basis, plus expenses (which, in that instance, may include Counsels’
              Fees), in accordance with our standard terms and at the hourly rates set out in
              Schedule 1 which shall apply for the purposes of those standard terms.</p>
          </div>
        </div>

        <div className="d-flex ps-5 pt-3">
          <div>
            <p>4</p>
          </div>
          <div className="px-3">
            <p><b> The reasons for setting the amount of the Payment at the level agreed</b></p>
          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>4.1</p>
          </div>
          <div className="px-3">
            <p> The Payment is set at the level set out in clause 3 for the following reasons:</p>
            <ul>
              <li>the fact that if You Lose Your Claim We will not be paid our Fees in respect of the
                work We will have undertaken in respect of Your Claim;</li>
              <li>the fact that our entitlement to the Payment is limited to a percentage of the sums
                ultimately recovered by You (if any) in respect of Damages;</li>
              <li> our arrangements with You about paying our expenses. We have agreed that We
                will fund Expenses during the claim and your liability for Expenses is limited as set
                out in clause 3.</li>
              <li> the risks that We consider exist in relation to claims of this type. Your claim is one of
                a large number of similar claims. We have considered both the risks for this type of
                claim, and the amount of work that we expect to have to do as a whole. In
                particular:</li>
              <ul>
                <li>The upfront investment that we have to make when acting in cases like this
                  is very substantial;</li>
                <li> The law in relation to this type of claim is relatively new; and</li>
                <li>Our exposure in terms of unrecoverable costs if the claims are unsuccessful
                  is considerable.</li>
              </ul>
              <li> However, the level of payment is not set by reference to the specific facts of
                your claim, or the prospect of your individual case succeeding or failing. It is
                a fixed, standard fee which we apply to all claims of this type. It is the only
                basis on which we are prepared to offer our services to you for a claim of this
                type. You should be aware that other firms of solicitors may offer different
                forms of funding or forms of funding with different percentages of damages
                payable as part of other funding packages. We are not able to advise in
                relation to the funding arrangements other firms may offer. We consider this
                Agreement and the percentage Payment to be reasonable in all the
                circumstances.</li>
            </ul>
          </div>
        </div>
        <div className="d-flex ps-5">

          <div className="px-3">
            <p> For the reasons above, We believe that this is a reasonable percentage to receive as the
              Payment and, by agreeing to this Agreement, You confirm that You have understood that
              the level of Payment is set on this basis and that You agree with the level of Payment.</p>
          </div>
        </div>



      </section>


      <section className="container-1 my-5" >
        <div className="d-flex ps-5 pt-4">
          <div>
            <p>5</p>
          </div>
          <div className="px-3">
            <p><b> Paying Us if You Lose</b></p>
          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>5.1</p>
          </div>
          <div className="px-3">
            <p> If You Lose Your Claim, such that You do not recover any Damages or Between the
              Parties Costs from Your Opponent, then You do not have to pay Us the Payment or
              anything in respect of our Fees or Counsel Fees.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>5.2</p>
          </div>
          <div className="px-3">
            <p> If You Lose Your Claim, You are likely to be liable to pay Your Opponent’ costs (and
              may be liable for some such costs even if You Win Your Claim). If You have taken out
              an After the Event insurance policy, this will usually pay those costs for You.</p>
          </div>
        </div>

        <div className="d-flex ps-5 pt-4">
          <div>
            <p>6</p>
          </div>
          <div className="px-3">
            <p> <b>Counsel Fees</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>6.1</p>
          </div>
          <div className="px-3">
            <p> Any disbursements We incur in respect of Counsel Fees are included within the
              Payment. This means that You do not have to pay Us separately for Counsel Fees.</p>
          </div>
        </div>

        <div className="d-flex ps-5 pt-4">
          <div>
            <p>7</p>
          </div>
          <div className="px-3">
            <p><b>Expenses</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>7.1</p>
          </div>
          <div className="px-3">
            <p>You are liable to pay our Expenses (which for the avoidance of doubt do not include
              our Fees or Counsel Fees) whether You Win or Lose Your claim.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>7.2</p>
          </div>
          <div className="px-3">
            <p> However, except where Clause 10 below applies (Ending the Agreement), whether
              you Win or Lose your Claim, your liability to pay our Expenses is limited to the sum of
              any Expenses that have been paid (or which your Opponent has to pay) as Between
              the Parties Costs or which are met by any After the Event insurance policy</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>7.3</p>
          </div>
          <div className="px-3">
            <p> We will not require You to pay any of our Expenses during the course of Your Claim
              and We will fund such Expenses during the claim.</p>
          </div>
        </div>


        <div className="d-flex ps-5">
          <div>
            <p>7.4</p>
          </div>
          <div className="px-3">
            <p> If You Win Your Claim You should be able to recover some of the Expenses from Your
              Opponent as part of any Between the Parties Costs order or agreement. The amount
              You are liable to pay for our Expenses is net of any sums in relation to our Expenses
              that have been paid (or which your Opponent has to pay) as Between the Parties
              Costs.</p>
          </div>
        </div>

        <div className="d-flex ps-5 pt-4">
          <div>
            <p>8</p>
          </div>
          <div className="px-3">
            <p> <b>Interim Costs</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>8.1</p>
          </div>
          <div className="px-3">
            <p> If, on the way to Winning or Losing You are awarded any Between the Parties Costs,
              by Agreement or Court Order, then We are entitled to payment of those costs at that
              point regardless of whether You Win or Lose overall. If You Win Your Claim, credit
              will be given against the Payment due in accordance with Clause 3.3 of this</p>
          </div>
        </div>
      </section>

      {/* <!-- page-11 --> */}
      <section className="container-1 my-5" >

        <div className="d-flex ps-5">
          <div className="px-3">
            <p> Agreement in respect of any Fees or Counsel Fees received as part of the interim
              Between the Parties Costs order or agreement.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>8.2</p>
          </div>
          <div className="px-3">
            <p> If You Win overall but, on the way, Lose an interim hearing, You are likely to be
              required to pay Your Opponent’s charges of that hearing. Such charges may be
              covered by any After the Event insurance policy and You should consider the terms
              of any such policy in this regard.</p>
          </div>
        </div>

        <div className="d-flex ps-5 pt-4">
          <div>
            <p>9</p>
          </div>
          <div className="px-3">
            <p> <b>‘After the Event’ insurance</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>9.1</p>
          </div>
          <div className="px-3">
            <p> You agree that we will take out and maintain a policy of After the Event’ insurance
              on your behalf in respect of the Claim. Such policy shall be sufficient to meet any
              reasonable risk of any liability to pay Your Opponent’ costs, if ordered to do so, in
              respect of the Claim and shall provide adequate security for costs if required.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>9.2</p>
          </div>
          <div className="px-3">
            <p>We will require there to be After the Event insurance in respect of the Claim before
              we will issue Court proceedings on your behalf.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>9.3</p>
          </div>
          <div className="px-3">
            <p> <b className="text-decoration-underline">The cost of such a policy cannot, in any circumstances, be recovered
              from your
              Opponent</b> and if You Win Your Claim the cost of that policy (the premium) will be
              payable by You in addition to the sums payable to Us under this Agreement.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>9.4</p>
          </div>
          <div className="px-3">
            <p> We will pay the reasonable cost of any upfront (i.e. not deferred) premium for any
              such policy of After the Event insurance. You agree to take all reasonable steps to
              ensure that the premium, or as much of the premium as is reasonably possible, is
              payable on the basis that such payment is deferred until the conclusion of Your
              Claim and is contingent on the successful outcome of Your Claim. You will pay Us for
              any premium that we have paid at the conclusion of the Claim in addition to any
              other sums payable under this Agreement. If the premium has been deferred, You
              will be liable to pay the premium to the insurer of You win Your Claim and You agree
              that we may deduct the cost of that premium from Your Damages.</p>
          </div>
        </div>

        <div className="d-flex ps-5 pt-4">
          <div>
            <p>10</p>
          </div>
          <div className="px-3">
            <p><b>Ending this Agreement</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>10.1</p>
          </div>
          <div className="px-3">
            <p>You may end this Agreement at any time on providing notice to Us. Unless
              You exercise Your right to cancel within 14 days pursuant to Schedule 2 of this
              Agreement, if You end this Agreement, whether under this Clause 10.1 or
              otherwise:</p>

            <div className="d-flex ">
              <div>
                <p>10.1.1</p>
              </div>
              <div className="px-2">
                <p>You are liable to pay Us forthwith our Expenses (net of any Expenses that
                  have been paid or which Your Opponent has to pay, as Between the
                  Parties Costs) incurred up to the end of the calendar month in which this
                  Agreement is ended; and</p>
              </div>
            </div>

            <div className="d-flex">
              <div>
                <p>10.1.2</p>
              </div>
              <div className="px-2">
                <p>You are liable to pay Us forthwith any Counsels’ Fees we have incurred
                  (net of any Counsels’ Fees that have been paid or which Your Opponent
                  has to pay, as Between the Parties Costs) up to</p>
              </div>
            </div>

            <div className="d-flex">
              <div>
                <p>10.1.3 </p>
              </div>
              <div className="px-2">
                <p>You are liable to pay Us forthwith our Fees for work undertaken by Us up
                  to the date that this Agreement is ended. Such Fees will be calculated by
                  reference to the time reasonably spent by us at our Hourly Rates in
                  accordance with the sections ‘Fees’ and ‘Hourly Rates’ in Schedule 1.</p>
              </div>
            </div>
          </div>
        </div>

      </section>

      {/* <!-- page-12 --> */}
      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div className="px-5">
            <div className="d-flex ">
              <div>
                <p>10.1.4</p>
              </div>
              <div className="px-2">
                <p>Your liability for such Fees, Expenses and Counsels’ Fees may and probably
                  will include a share of any Common Costs, calculated in accordance with
                  the terms of the Litigation Management Agreement. Since the liability for
                  Common Costs is calculated on a quarterly basis, this may include some
                  costs incurred in the quarter in which You ended the Agreement, but after
                  the date on which You did so;</p>
              </div>
            </div>

            <div className="d-flex">
              <div>
                <p>10.1.5</p>
              </div>
              <div className="px-2">
                <p>In addition, in the event that You Win Your claim, You will be liable to pay
                  Us the Payment, as set out under ‘Paying Us if You Win’ above, but only to
                  the extent that the amount of the Payment exceeds any sums you have
                  paid us under Clauses 10.1.2. 10.1.3 and 10.1.4 in respect of our Fees and
                  any Counsels’ Fees.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>10.2</p>
          </div>
          <div className="px-3">
            <p> We may end this Agreement immediately on providing notice to You;</p>
            <div className="d-flex">
              <div>
                <p>10.2.1</p>
              </div>
              <div className="px-2">
                <p> if, in our reasonable opinion, the prospects of You achieving a Win fall
                  below 51%;</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>10.2.2</p>
              </div>
              <div className="px-2">
                <p> if there is no third-party funding in place (in circumstances where it is
                  required) to fund the continued pursuit of Your Claim;</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>10.2.3</p>
              </div>
              <div className="px-2">
                <p> if We reasonably believe that Your Claim is no longer commercially viable;
                  or</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>10.2.4</p>
              </div>
              <div className="px-2">
                <p> if to continue acting would involve a material breach of a relevant rule of
                  professional conduct which has arisen as a result of events which are
                  outside of Your control.</p>
              </div>
            </div>
            <p>
              If We end this Agreement under this clause 10.2 , You will be liable to pay Us our
              Expenses (net of any Expenses that have been paid - or in respect of which there is
              a liability on the Opponent to pay - by way of Between the Parties Costs) incurred
              up to date on which You ended the Agreement, but You will not be liable to pay Us
              the Payment. Your liability for such Expenses may and probably will include a share
              of any Common Costs Expenses, calculated in accordance with the terms of the
              Litigation Management Agreement. Since the liability for Common Costs Expenses
              is calculated on a quarterly basis, this may include some Expenses incurred in the
              quarter in which You ended the Agreement, but after the date on which You did so;
            </p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>10.3</p>
          </div>
          <div className="px-3">
            <p> We may also end this Agreement immediately on providing notice to You:</p>
            <div className="d-flex">
              <div>
                <p>10.3.1</p>
              </div>
              <div className="px-2">
                <p> if You are in material breach of Your obligations under this Agreement
                  (including, but not limited, to those listed under Clause 1 3) or the
                  engagement letter; or</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>10.3.2</p>
              </div>
              <div className="px-2">
                <p> if to continue acting would involve a material breach of a relevant rule of
                  professional conduct which has arisen as a result of events which are
                  within Your control.</p>
              </div>
            </div>
            <p className="pt-2"> If We end this Agreement under this clause 10.3 :</p>
            <div className="d-flex">
              <div>
                <p>10.3.3</p>
              </div>
              <div className="px-2">
                <p> You are liable to pay Us forthwith our Expenses (net of any Expenses that
                  have been paid - or in respect of which there is a liability on the Opponent
                  to pay - by way of Between the Parties Costs) incurred up to the date on
                  which You ended the Agreement; and</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>10.3.4</p>
              </div>
              <div className="px-2">
                <p> You are liable to pay Us forthwith any Counsels’ Fees we have incurred
                  (net of any Counsels’ Fees that have been paid or which Your Opponent</p>
              </div>
            </div>
          </div>
        </div>

      </section>

      {/* <!-- page-13 --> */}
      <section className="container-1 my-5" >

        <div className="d-flex ps-5">

          <div className="px-5">
            <div className="d-flex ">

              <div className="px-5">
                <p>has to pay, as Between the Parties Costs) up to the date on which You
                  ended the Agreement; and</p>
              </div>
            </div>

            <div className="d-flex">
              <div>
                <p>10.3.5</p>
              </div>
              <div className="px-2">
                <p>You are liable to pay Us forthwith our Fees for work undertaken by Us up
                  to the date that this Agreement is ended. Such Fees will be calculated by
                  reference to the time reasonably spent by us at our Hourly Rates in
                  accordance with the sections ‘Fees’ and ‘Hourly Rates’ in Schedule 1;</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>10.3.6</p>
              </div>
              <div className="px-2">
                <p>Your liability for such Fees, Expenses and Counsels’ Fees may and probably
                  will include a share of any Common Costs, calculated in accordance with
                  the terms of the Litigation Management Agreement. Since the liability for
                  Common Costs is calculated on a quarterly basis, this may include some
                  costs incurred in the quarter in which You ended the Agreement, but after
                  the date on which You did so;</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>10.4</p>
          </div>
          <div className="px-3">
            <p> Following termination of this Agreement under Clauses 10.1 or 10.3 , You
              agree to keep Us regularly informed of the progress of the Claim and You
              irrevocably agree that any new firm of solicitors You appoint shall provide Us with
              regular information on request as to the progress of Your Claim and answer such
              questions as We may reasonably ask. You also agree to notify Us immediately of
              any sums received (including any Damages or Between the Parties Costs) and to
              give irrevocable instructions to any new firm of solicitors to any such sums on trust
              for Us for payment of any outstanding sums due under this Agreement.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>11</p>
          </div>
          <div className="px-3">
            <p> <b>What happens after the Agreement ends</b></p>
          </div>
        </div>
        <div className="d-flex ps-5">
          <p>Following termination of this Agreement, We may apply to have our name removed
            from the court record in the Proceedings. We also have the right to preserve a Lien over
            any property of Yours (including Your papers) in our possession until any sums owed to
            Us under this Agreement are paid in full.</p>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>12</p>
          </div>
          <div className="px-3">
            <p> <b>Our responsibilities</b></p>
          </div>
        </div>
        <div className=" ps-5">
          <p className="px-3">
            We must:
          </p>
          <ul>
            <li>always act in Your best interests, subject to our professional duties to the court; and</li>
            <li>explain to You the risks and benefits of taking legal action.</li>
          </ul>

        </div>
        <div className="d-flex ps-5">
          <div>
            <p>13</p>
          </div>
          <div className="px-3">
            <p> <b>your responsibilities</b></p>
          </div>
        </div>
        <div className="ps-5">
          <p className="px-3">
            you must:
          </p>
          <ul>
            <li>give Us full, honest and timely instructions that allow Us to do our work properly;</li>
            <li>promptly provide any information and documents that We may reasonably request;</li>
            <li> not ask Us to work in an improper or unreasonable way;</li>
            <li> pay all amounts due to Us in accordance with this Agreement;</li>
            <li> attend any court hearings in relation to Your Claim as required by the court or as
              reasonably requested by Us;</li>
            <li> inform Us immediately if You become aware of any circumstances that may reasonably
              require Us to review the merits of Your Claim;</li>
            <li> agree not to represent Yourself or instruct anyone other than We advise to represent
              You at any court hearings or settlement meetings;</li>
            <li> not deliberately mislead Us; and</li>
            <li> co-operate fully with Us at all times.</li>
          </ul>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>14</p>
          </div>
          <div className="px-3">
            <p> <b>In the event of a dispute about ‘Damages recovered’</b></p>
          </div>
        </div>

      </section>

      {/* <!-- page-14 --> */}
      <section className="container-1 my-5" >

        <div className="d-flex ps-5">
          <div>
            <p>14.1</p>
          </div>
          <div className="px-3">
            <p>If there is any dispute as to the calculation of the ‘Damages recovered’ for the purposes
              of calculating the Payment payable under this Agreement (which may, for example, occur
              if Your Opponent seek to settle the Claim by way of payment of a lump sum inclusive of
              both Damages and Between the Parties Costs) then;</p>
          </div>
        </div>

        <div className="d-flex ps-5">

          <div className="px-5">


            <div className="d-flex">
              <div>
                <p>14.1.1</p>
              </div>
              <div className="px-2">
                <p>We will endeavour to agree with You a figure representing the Damages
                  recovered by You which shall stand as the ‘sums recovered’ for the
                  purposes of this Agreement;</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>14.1.2</p>
              </div>
              <div className="px-2">
                <p>If You do not agree with our proposed figure representing the Damages
                  recovered by You, You agree to jointly instruct with Us a barrister of
                  suitable standing and seniority to determine an appropriate figure and to
                  be bound by their opinion;</p>
              </div>
            </div>

            <div className="d-flex">
              <div>
                <p>14.1.3</p>
              </div>
              <div className="px-2">
                <p>In default of our agreeing suitable counsel, You and We agree they should
                  be nominated by the Chair of the Bar Council;</p>
              </div>
            </div>

            <div className="d-flex">
              <div>
                <p>14.1.4</p>
              </div>
              <div className="px-2">
                <p>The barrister’s costs for assessing this issue are to be paid by You if the
                  barrister agrees with Us, but otherwise are to be paid by Us.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>10.4</p>
          </div>
          <div className="px-3">
            <p> Following termination of this Agreement under Clauses 10.1 or 10.3 , You
              agree to keep Us regularly informed of the progress of the Claim and You
              irrevocably agree that any new firm of solicitors You appoint shall provide Us with
              regular information on request as to the progress of Your Claim and answer such
              questions as We may reasonably ask. You also agree to notify Us immediately of
              any sums received (including any Damages or Between the Parties Costs) and to
              give irrevocable instructions to any new firm of solicitors to any such sums on trust
              for Us for payment of any outstanding sums due under this Agreement.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>14.2</p>
          </div>
          <div className="px-3">
            <p>If Your Opponent seeks to settle claims brought by You and any other claimant or
              group of claimants for whom We act in relation to the same dispute on a global
              basis, then We have agreed specific arrangements with You in relation to the
              calculation of each claimant’s respective share in a Litigation Management
              Agreement and those terms, set out at Clause 8 of that agreement, shall apply.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>15</p>
          </div>
          <div className="px-3">
            <p><b>Part 36 additional sums</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>15.1</p>
          </div>
          <div className="px-3">
            <p>In accordance with the procedural rules that apply to claims of this type, if You
              make an offer, known as a Part 36 offer, and Your Opponent does not accept that
              and at trial You obtain judgment for a sum at least as advantageous to You than
              Your offer, the Court may award You an additional percentage of that judgment
              sum, known as an ‘additional sum’. Such additional sums may be ordered in
              respect of damages or in respect of Your costs (in detailed assessment
              proceedings). If an additional sum is awarded then;</p>
            <div className="d-flex ">
              <div>
                <p>15.1.1</p>
              </div>
              <div className="px-3">
                <p>If that additional sum is in respect of Your Damages, the additional sum
                  will be payable to You as an additional amount of Damages and shall form
                  part of the Damages for the purposes of this Agreement;</p>
              </div>
            </div>
            <div className="d-flex ">
              <div>
                <p>15.1.2</p>
              </div>
              <div className="px-3">
                <p>If that additional sum is in respect of costs, You agree that the additional
                  sum shall be paid to Us. Credit will be given for that additional sum against
                  any sums You are otherwise liable to pay Us in accordance with the
                  remaining terms of this Agreement such that, in practice, it will serve to
                  reduce the amount of any sums You are liable to pay Us under this
                  Agreement.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>16</p>
          </div>
          <div className="px-3">
            <p><b>s.74(3) Solicitors Act 1974</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>16.1</p>
          </div>
          <div className="px-3">
            <p>In the event that Your Claim proceeds in the County Court (as opposed to the High
              Court), or in any other circumstances in which s.74(3) Solicitors Act 1974 is said to
              apply, there are limits in certain circumstances on the maximum sums the Court
              may award against Your Opponent by way of costs. These may include;</p>
          </div>
        </div>



      </section>

      {/* <!-- page-15 --> */}
      <section className="container-1 my-5">

        <div className="d-flex ps-5">

          <div className="px-5">

            <div className="d-flex">
              <div>
                <p>16.1.1</p>
              </div>
              <div className="px-2">
                <p>Some elements of costs where the maximum sum Your Opponent can be
                  ordered to pay is fixed by rules of procedure;</p>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <p>16.1.2</p>
              </div>
              <div className="px-2">
                <p>Some elements of costs – such as the Payment and part or all of the ATE
                  premium – where the law says that You Opponent cannot be ordered to
                  pay those costs in any circumstances or that the basis on which you will be
                  able to recover costs is different to that provided for under this
                  Agreement.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>16.2</p>
          </div>
          <div className="px-3">
            <p>By entering into this Agreement, You expressly agree that the sums which You are
              liable to pay under this Agreement remain payable regardless of any such limits
              that might apply in respect of the sums that could have been allowed against Your
              Opponent in the County Court, subject only to the remaining terms of this
              Agreement. If you require any further information in relation to this please
              contact us.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>17</p>
          </div>
          <div className="px-3">
            <p> <b> Your statutory rights to have our costs considered by the Court</b></p>
          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>17.1</p>
          </div>
          <div className="px-3">
            <p> You have the right to ask the Court to consider whether this agreement is fair and
              reasonable in terms of the sums payable under it, which may include a right to
              have our costs assessed by the Court. Your rights in this regard are set out in ss.
              59 to 61 Solicitors Act 1974 and We will provide reasonable further information as
              to Your rights in this regard in response to any reasonable request.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>17.2</p>
          </div>
          <div className="px-3">
            <p> You should be aware that strict time limits may apply to Your rights in this regard,
              and to any other rights under s.70 Solicitors Act 1974 which might be said to apply
              to any costs you are liable to pay, in particular once any sums due under this
              Agreement have been paid by you by way of such sums being deducted from any
              Damages, as is provided for in this agreement. If you do wish to engage your
              rights and do not do so promptly once your Claim concludes and any such
              payment is made, then your rights may be affected or lost.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>18</p>
          </div>
          <div className="px-3">
            <p> <b>Variation of the Agreement</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>18.1</p>
          </div>
          <div className="px-3">
            <p> To be effective, any variation of or supplement to this Agreement must be made
              in writing and be signed by duly authorised officers or agents of both Parties.</p>
          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>18.2</p>
          </div>
          <div className="px-3">
            <p> For the avoidance of doubt, any such variation may be retrospective to the date
              of this Agreement.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>19</p>
          </div>
          <div className="px-3">
            <p> <b>Contracts (Rights of Third Parties) Act 1999</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <p> A person who is not a Party to this Agreement has no right under the Contracts (Rights
            of Third Parties) Act 1999 or any applicable successor legislation or otherwise to enforce
            or enjoy the benefit of any term of this Agreement. The consent of any person who is
            not a Party is not required to rescind or vary this Agreement at any time.</p>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>20</p>
          </div>
          <div className="px-3">
            <p> <b>Assignment and transfer of obligations</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>20.1</p>
          </div>
          <div className="px-3">
            <p> We shall be entitled to assign or subcontract any or all of Our rights, interests and
              obligations pursuant to this Agreement upon giving You written notice of our
              intention to do so.</p>
          </div>
        </div>

      </section>

      {/* <!-- page-16 --> */}
      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div>
            <p>20.2</p>
          </div>
          <div className="px-3">
            <p> Save as set out in Clause 20.1 above, neither Party shall be permitted to assign,
              subcontract or transfer this Agreement or any of the rights or interests under it,
              nor to assign, transfer or subcontract any or all of the obligations under it, without
              first having obtained the written consent of the other Party.</p>
          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>21</p>
          </div>
          <div className="px-3">
            <p> <b>Waiver</b></p>
          </div>
        </div>
        <div className="d-flex ps-5">
          <p>
            No forbearance or delay by a Party in enforcing its rights shall prejudice or restrict the
            rights of that Party, and no waiver of any such rights or of any breach of any contractual
            terms will be deemed to be a waiver of any other right or of any later breach.
          </p>
        </div>


        <div className="d-flex ps-5">
          <div>
            <p>22</p>
          </div>
          <div className="px-3">
            <p><b>Enforceability and severance</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>22.1</p>
          </div>
          <div className="px-3">
            <p> It is the mutual intention of the Parties to this Agreement that it shall comply with
              all applicable legislation and in particular that to the extent that they apply to any
              part of this Agreement, the relevant parts of this Agreement shall comply with
              s.58AA Courts and Legal Services Act 1990 and any regulations made thereunder,
              including the DBA Regulations, and any relevant and applicable successor
              legislation or regulations. The terms of this Agreement shall be construed
              accordingly and, where necessary, shall be read down to ensure compliance with
              such legislative or regulatory provisions to the extent necessary to ensure that
              this Agreement shall remain enforceable in accordance with the same.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>22.2</p>
          </div>
          <div className="px-3">
            <p> If any provision of this Agreement is held by any competent court to be invalid,
              illegal or unenforceable in whole or in part for whatever reason, then:</p>
            <div className="d-flex ps-5">
              <div>
                <p>22.2.1</p>
              </div>
              <div className="px-3">
                <p> for the purposes of the Proceedings, the provision shall be deemed to be
                  severed from this Agreement to the extent only of such invalidity, illegality
                  or unenforceability, and the remaining provisions of this Agreement shall
                  continue in full force and effect unimpaired by such severance; and</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>22.2.2</p>
              </div>
              <div className="px-3">
                <p> the Parties shall negotiate in good faith in order to agree the terms of a
                  mutually satisfactory provision, achieving as nearly as possible the same
                  commercial effect, to be substituted for the provision so found to be
                  invalid, illegal or unenforceable and each Party shall take any step
                  required, including executing any further or other document, in order to
                  give effect to the Parties’ intention in entering into this Agreement.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>23</p>
          </div>
          <div className="px-3">
            <p> <b>Successors</b></p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <p>
            This Agreement shall be binding on the Parties, their successors and assigns and the
            name of a Party appearing herein shall be deemed to include the names of any such
            successor or assign.
          </p>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>24</p>
          </div>
          <div className="px-3">
            <p> <b>Data Protection</b></p>
          </div>
        </div>
      </section>

      {/* <!-- page-17 --> */}
      <section className="container-1 my-5">
        <div className="d-flex ps-5">
          <p> In performing their respective obligations and exercising their respective rights under
            this Agreement, each of the Parties agrees to comply with all applicable obligations
            under the Data Protection Legislation. For the purposes of this Clause 18 , “Data
            Protection Legislation” means all applicable data protection and privacy legislation in
            force from time to time in the UK including the General Data Protection Regulation ((EU)
            2016/679); the Data Protection Act 2018; the Privacy and Electronic Communications
            Directive 2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy and
            Electronic Communications Regulations 2003 (SI 2003/2426) as amended.</p>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>25</p>
          </div>
          <div className="px-3">
            <p> <b>Notices</b></p>
          </div>
        </div>



        <div className="d-flex ps-5">
          <div>
            <p>25.1</p>
          </div>
          <div className="px-3">
            <p>Any notice to be served under this Agreement shall be made in writing and may be:</p>
            <div className="d-flex ps-5">
              <div>
                <p>25.1.1</p>
              </div>
              <div className="px-3">
                <p>delivered by hand or sent by pre-paid first class recorded delivery post to the
                  Party to be served at the relevant address set out in the signature block to this
                  Agreement or any such other address as the Party to be served may have notified
                  to the other Party for the purposes of this Clause 25.1.1 ; or</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>25.1.2</p>
              </div>
              <div className="px-3">
                <p>sent by us at the email address set out in the signature block to this Agreement
                  or to such other email address as We may have notified to You for the purposes
                  of this Clause 25.2.2; or</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>25.1.3</p>
              </div>
              <div className="px-3">
                <p>sent by email to You, at the email address that you provide us with for
                  communicating with you or to such other email address as You may have notified
                  to Us for the purposes of this Clause 25.3 .</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>25.2</p>
          </div>
          <div className="px-3">
            <p> Any notice shall be deemed to have been served:</p>
            <div className="d-flex ps-5">
              <div>
                <p>25.2.1</p>
              </div>
              <div className="px-3">
                <p>if delivered by hand, at the time of delivery to the Party;</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>25.2.2</p>
              </div>
              <div className="px-3">
                <p>if posted, at 10.00am on the second Business Day after it was posted to
                  the Party; or</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>25.2.3</p>
              </div>
              <div className="px-3">
                <p>if sent by email, at the time of sending the email.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>25.3</p>
          </div>
          <div className="px-3">
            <p> In proving service of a notice, it shall be sufficient to prove that delivery by hand
              was made, that the envelope containing the notice was properly addressed and
              posted as a pre-paid first class recorded delivery letter or that the email was sent
              to the email address and no error or bounce-back message was received.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>25.4</p>
          </div>
          <div className="px-3">
            <p> If Notice is served by means of pre-paid first class recorded delivery post, the
              Party serving such notice shall, on or before posting the Notice, send a copy of it
              by email to the receiving Party’s email address specified in clause 25.1.2 .</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>26</p>
          </div>
          <div className="px-3">
            <p> <b>Signature</b></p>
          </div>
        </div>

        <div className=" ps-5">
          <p>
            This Agreement may be signed in any number of counterparts, each of which taken
            together shall be deemed to constitute one and each of which individually shall be
            deemed to be an original, with the same effect as if the signature on each counterpart
            were on the same original.
          </p>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>27</p>
          </div>
          <div className="px-3">
            <p> <b>Applicable Law</b></p>
          </div>
        </div>

        <div className=" ps-5">
          <p>
            This Agreement, and any non-contractual obligations arising from it or in connection
            with it, is governed by and is to be construed in accordance with the law of the England
            and Wales and each Party irrevocably submits to the exclusive jurisdiction of the courts
          </p>
        </div>
      </section>


      {/* <!-- page-18 --> */}
      <section className="container-1 my-5" ref={signref_1}>
        <div className=" ps-5">
          <p> of England and Wales over any claim or matter arising out of or in connection with this
            Agreement.</p>
          <hr />
        </div>

        <p> <b> Signatures:</b></p>

        <div className="d-flex w-100" >
          <div style={{ width: '50%' }}>
            <img src={signImg} className="img-fluid" style={{ width: '40%' }} alt="" />
            <p> <b> SIGNED:</b></p>
          </div>

          <div style={{ width: '50%' }}>
            <img src={` ${userSign}`} style={{ width: '57%' }} alt="" />
            <p> <b> SIGNED:</b></p>
          </div>

        </div>

        <div className="d-flex">
          <div className="w-50">
            <p> <b>NAME:</b> Jamie Patton – Director</p>
            <p> For and On Behalf of Johnson Law Group</p>
            <p> <b>Email Address:</b> emissions@johnsonlawgroup.co.uk</p>
          </div>
          <div className="w-50">
            <p> PRINT NAME: {fName} {lName}</p>
          </div>
        </div>




      </section>

      {/* <!-- page-19 --> */}
      <section className="container-1 my-5" >
        <h4 className="text-decoration-underline"> Schedule 1 – Definitions</h4>
        <p> <b> “Between the Parties Costs”</b></p>
        <p> All amounts paid or payable by the Opponent (or from another party on behalf of the
          Opponent) in respect of: (i) our Fees; (ii) Counsel Fees; and/or (iii) Expenses. Between the
          Parties Costs are sometimes referred to as “inter partes costs”.</p>
        <p><b>“Claim”</b></p>
        <p>Your claim for Damages against the Opponent, whether or not Proceedings are issued, as
          further described in Clause 1 of this Agreement.</p>
        <p> <b> “Common Costs”</b></p>
        <p> Where Your Claim is being brought collectively or in a group with the similar Claims of
          others, Common Costs are costs incurred by and/or in respect of those clients collectively
          for the purposes of the claim / group of claims generally. These will include:</p>
        <ol type="i">
          <li> Costs incurred in administering the claim / claims;</li>
          <li> Costs of any individual client claims which are ordered to proceed as a test
            claim;</li>
          <li> Costs in relation to issues the court identifies as generic or common issues to
            such claims;</li>
        </ol>
        <p> <b>“Counsel Fees”</b></p>
        <p>Fees charged by the barristers engaged in respect of Your Claim. Counsel Fees are used for
          the purposes of calculating and seeking recovery of costs from the Opponent by way of
          Between the Parties Costs.</p>
        <p> <b>“Counterclaim”</b></p>
        <p>A claim that Your Opponent make against You.</p>
        <p><b>“Damages”</b></p>
        <p>Money or other proceeds that are paid or payable to You in respect of Your Claim by the
          Opponent (or from another party on behalf of the Opponent), whether by a court decision
          or settlement, other than Between the Parties Costs. Damages shall include any interest
          paid or payable by your Opponent in respect on other monies or other proceeds payable by
          them in respect of Your Claim (other than between the Parties Costs).</p>
        <p> <b>“Expenses”</b></p>
        <p>Any disbursements We incur on Your behalf in connection with the Claim within the
          meaning of “expenses” in the DBA Regulations, including (but not limited to):</p>
        <ul>
          <li>court fees;</li>
          <li>experts’ fees;</li>
          <li>photocopying costs;</li>
          <li>travelling expenses,</li>
        </ul>

      </section>

      {/* <!-- page-20 --> */}
      <section className="container-1 my-5" >
        <p>but, for the avoidance of doubt, excluding our Fees and Counsel Fees.</p>
        <p><b>“Fees”</b></p>
        <p>The time reasonably spent by Us in respect of Your Claim multiplied by our Hourly Rates of
          remuneration (in accordance with the section “Hourly Rates” below). Our Fees are used for
          the purposes of calculating and seeking recovery of costs from the Opponent by way of
          Between the Parties Costs and, in certain circumstances (as set out in this Agreement), Your
          liability to Us in respect of work We have undertaken in the event that You choose to end
          this Agreement before You Win or Lose Your Claim.</p>
        <p> <b> “Hourly Rates”</b></p>
        <p>Our standard Hourly Rates are:</p>

        <table>
          <tbody>
            <tr>
              <td> Category of Fee Earner</td>
              <td>Level or
                Grade</td>
              <td> Hourly Rate</td>
            </tr>
            <tr>
              <td> Solicitors and Legal Executive with over 8 years experience
                after qualification, or fee earners with equivalent
                experience:</td>
              <td> A</td>
              <td> £410</td>
            </tr>
            <tr>
              <td> Solicitors and Legal Executive with over 4 years experience
                after qualification, or fee earners with equivalent
                experience</td>
              <td> B</td>
              <td> £350</td>
            </tr>
            <tr>
              <td> Solicitors and Legal Executive with up to 4 years experience
                after qualification, or fee earners with equivalent
                experience</td>
              <td> C</td>
              <td> £275</td>
            </tr>
            <tr>
              <td>Paralegals, Trainee Solicitors, Trainee Legal Executives and
                other staff of equivalent experience</td>
              <td> D</td>
              <td> £175</td>
            </tr>
          </tbody>
        </table>

        <p> Routine letters and telephone calls will be charged as units of one tenth of an hour. Other
          letters and telephone calls will be charged on a time basis</p>
        <p>These hourly rates exclude VAT, which is payable in addition.</p>
        <p> We review our Hourly Rates annually, usually in January, and if they change we will tell you
          in writing.</p>
        <p><b>“Individual Costs”</b></p>
        <p>Costs incurred by and/or in respect of your Claim which are not Common Costs. In the event
          that your Claim is not being brought collectively with other Claims, this will usually be all of
          the Costs. If your Claim is being brought collectively or in a group with other similar claims,
          Individual Costs will normally be in relation to matters which are personal to your Claim and
          may include, for example, any individual assessment of Damage or Other Reward after any
          collective admission or finding of liability.</p>
        <p> <b>“Interim Order”</b></p>
        <p>An order in the Proceedings that is not final.</p>
        <p> <b>“Lien” </b></p>


      </section>

      {/* <!-- page-21 --> */}
      <section className="container-1 my-5">
        <p>Our right to keep all papers, documents, money or other property held on Your behalf until
          all sums due to Us under this Agreement are paid. A Lien may be applied before or after
          termination of this Agreement.</p>
        <p><b>“Lose”</b></p>
        <p>The court or tribunal with conduct of the Proceedings has dismissed Your Claim or You have
          ceased to pursue it on our advice.</p>
        <p> <b> “Opponent”</b></p>
        <p><b>Mercedes Benz Cars UK Limited, Mercedes Benz Financial Services UK Limited and Daimler
          AG</b> (or such other party or parties as We may reasonably identify as potentially being liable
          in respect of Your Claim).</p>
        <p><b>“Payment”</b></p>
        <p> That percentage of the sums recovered from the Opponent (or from another party on
          behalf of the Opponent) in respect of Damages which is payable by You to Us under clause 3
          of this Agreement.</p>
        <p> <b>“Proceedings”</b></p>
        <p>Each and every litigation, tribunal or arbitral proceeding issued or arising out of or in
          connection with Your Claim.</p>
        <p> <b>“Several Liability”</b></p>
        <p>A Several Liability is one where each Claimant liability is separate from that of any other
          Claimant, rather than one where all the Claimants are jointly responsible for the total. For
          example, in relation to Common Costs, each Claimant will only usually be liable for their
          own share of those Common Costs (their Several Liability) and will not have any liability for
          the rest of those Common Costs. With Individual Costs, each Claimant’s Several Liability will
          usually be for the total of their own Individual Costs (subject to the remaining terms of this
          Agreement) and they will have no liability for the Individual Costs of other Claimants.</p>
        <p> <b> “Win”</b></p>
        <p>You become entitled to Damages and/or Between the Parties Costs, whether by a final court
          decision or a settlement agreement. For the purposes of this definition, a court decision is
          'final' if Your Opponent:</p>
        <ul>
          <li>is not able or permitted to appeal against the court decision; or</li>
          <li>has not appealed within the required deadline.</li>
        </ul>
      </section>

      {/* <!-- page-22 --> */}
      <section className="container-1 my-5" >
        <h4 className="text-decoration-underline"> Schedule 2 – Notice of the Right to Cancel</h4>
        <table>
          <tbody>
            <tr>
              <td className="p-4">
                <p>This notice of cancellation applies to the entirety of your Agreement to instruct Johnson Law Group
                  – in other words, your whole retainer with us. You have the right to cancel without liability if you
                  signed our paperwork:</p>
                <ol type="i">
                  <li> At your home, workplace or at someone else’s home; or</li>
                  <li> At our offices but following a visit by us (or by someone acting on our behalf)
                    to your home, workplace or someone else’s home; or</li>
                  <li>At our offices but following a meeting between us away from our offices.</li>
                </ol>
                <p>
                  You have the right to cancel this contract, without reason, if you wish and can do so by delivering
                  personally or sending (including electronic mail) a cancellation notice to the person mentioned
                  below at any time within 14 days from the date of the above-mentioned agreement.
                </p>
                <p>
                  The person to whom a cancellation notice may be given is Jamie Patton of Johnson Law
                  Group at 76 King Street, Manchester M2 4NH.
                </p>
                <p>
                  Notice of cancellation is deemed to be served as soon as it is posted or sent to us. You can
                  use the cancellation form provided below if you wish.
                </p>
                <p>
                  If you wish to cancel the contract, you <b>must do so in writing</b> and deliver personally or send (which
                  may be by electronic mail) this to the person named below. You may use this form if you want to,
                  but you do not have to.
                </p>
              </td>
            </tr>
            <tr>
              <td className="p-4">
                <p>(Complete, detach and return this form <b>ONLY IF YOU WISH TO CANCEL</b> )</p>
                <p> To: Jamie Patton of Johnson Law Group, 76 King Street, Manchester, M2 4NH</p>
                <p>Case Reference No:</p>
                <p><i>I hereby give notice that I wish to cancel my Agreement with your firm</i></p>
                <div className="d-flex w-50 pt-3">
                  <p className="w-50">Signed:</p>
                  <p>…………………………………………</p>
                </div>
                <div className="d-flex w-50">
                  <p className="w-50">Name (please print):</p>
                  <p>…………………………………………</p>
                </div>
                <div className="d-flex w-50">
                  <p className="w-50">Address:</p>
                  <p>………………………………………… <br /> …………………………………………</p>
                </div>
                <div className="d-flex w-50">
                  <p className="w-50">Date:</p>
                  <p>…………………………………………</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* <!-- page-23 --> */}
      <section className="container-1 my-5" >
        <h4 className="text-decoration-underline"> LITIGATION MANAGEMENT AGREEMENT</h4>
        <p> This Litigation Management Agreement (“the Agreement”) is made between:</p>
        <ol>
          <li>
            The <b>Claimants</b> being each claimant or proposed claimant in litigation or proposed litigation
            raising common or related issues (“the <b>Proceedings”</b> ) who enters into this Agreement,
            together with all other Claimants who are parties, or will become parties, to this Agreement
            in respect of the same Proceedings; and
          </li>
          <li> Johnson Law Group <b> of 76 King Street, Manchester, M2 4NH</b> (the <b>Solicitors</b> )</li>
        </ol>
        <p> collectively referred to below as the <b>Parties</b> .</p>
        <p>This Agreement is to be read in conjunction with and is part of the Damages Based Agreement
          you, the client, has entered into of same date and should be read as part of the terms and
          conditions of the overall retainer with Johnson Law Group. It regulates:</p>
        <ol type="a">
          <li>How the Proceedings will be managed in the best interests of the Claimants (including
            provision for a Committee to be appointed to give instructions to the Solicitors on the
            Claimants’ behalf where that is appropriate);</li>
          <li> Responsibility for costs;</li>
          <li>How recoveries will be shared; and</li>
          <li>The obligations of Claimants between themselves;</li>
        </ol>
        <p className="pt-5 pb-2"> <b>DEFINITIONS</b></p>
        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Committee</b></p>
          </div>
          <p className="w-50">A committee representing the Claimants which
            is appointed pursuant to the terms of this
            Agreement in the event that the Solicitors deem
            this to be in the interests of the Claimants as a
            group. There is no obligation to appoint a
            Committee, and references which assume the
            existence of a Committee below will be
            construed as references to the Committee “(if
            any)”;</p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Committee Member</b></p>
          </div>
          <p className="w-50">1 each individual who is appointed a member of
            a Committee pursuant to this Agreement;</p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Common Costs</b></p>
          </div>
          <p className="w-50">2 (a) costs incurred in Proceedings in relation to
            any issues that are common to all or a
            substantial number of the Claimants;<br />
            3<br />
            (b) costs incurred in a claim after it is nominated
            as a test or lead claim until the point that it
            ceases to be such; and<br />
            (c) costs incurred in administering the
            Proceedings;</p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Common Costs Counsel Fees</b></p>
          </div>
          <p className="w-50">Fees charged by barristers engaged in respect of
            the proceedings that relate to Common Costs;</p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Committee Member</b></p>
          </div>
          <p className="w-50">1 each individual who is appointed a member of
            a Committee pursuant to this Agreement;</p>
        </div>


      </section>

      {/* <!-- page-24 --> */}
      <section className="container-1 my-5">

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Common Costs Expenses</b></p>
          </div>
          <div className="w-50">
            <p>Any Disbursements the Solicitors incur on behalf
              Claimants in connection with the Proceedings
              within the meaning of “expenses” in the DBA
              Regulations that relate to Common Costs,
              including (but not limited to):</p>
            <ul>
              <li>court fees;</li>
              <li>experts’ fees;</li>
              <li>photocopying costs;</li>
              <li>travelling expenses,</li>
            </ul>
            <p>
              but, for the avoidance of doubt, excluding Profit
              Costs or Solicitors’ Fees and Counsel Fees;
            </p>


          </div>

        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Common Costs Profit Costs</b></p>
          </div>
          <p className="w-50">The time reasonably spent by the Solicitors
            multiplied by their Hourly Rates of
            remuneration, calculated for the purposes of
            seeking recovery of costs from the Opponent in
            relation to Common Costs</p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Key Development</b></p>
          </div>
          <p className="w-50">means any development or likely development
            in the Proceedings which, in the reasonable
            professional judgement of a solicitor
            experienced in litigation, would be likely to have
            a significant impact on (i) the prospects of a
            Successful Outcome; (ii) the cost effectiveness of
            the Proceedings; (iii) the risk of paying
            Opponent’s Costs; (iv) the amount of
            Opponent’s Costs; (v) the validity or sufficiency
            of any indemnity against Opponent’s costs; (vi)
            the validity or sufficiency of any litigation
            funding; or (vii) the prospects of settlement;</p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Majority</b></p>
          </div>
          <p className="w-50"><b>means, upon a vote by the Committee, a voting
            majority in excess of 50% of Committee
            Members present;</b></p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Opponent</b></p>
          </div>
          <p className="w-50"><b>means a defendant or proposed defendant in
            the Proceedings, or any other entity from which
            a remedy (including costs) is sought in the
            Proceedings on the advice of the Solicitors;</b></p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Proceedings</b></p>
          </div>
          <p className="w-50"><b>The court action or dispute that is referred to
            within the Retainer that the claimant has
            entered into with the Johnson Law Group to
            which this Litigation Management Agreement
            forms part;</b></p>
        </div>

        <div className="d-flex w-100  px-5">
          <div className="w-50">
            <p><b>Retainer</b></p>
          </div>
          <p className="w-50">The combined documents including the
            introductory letter, Damages Based Agreement,
            Litigation Management Agreement, JLG’s Terms
            of Business and forms of authority that go to
            make up the full contract entered into between JLG and the Claimant;</p>
        </div>
      </section>

      {/* <!-- page-25 --> */}
      <section className="container-1 my-5" >

        <p> <b> THE PARTIES AGREE AS FOLLOWS</b></p>

        <div className="d-flex ps-5">
          <div>
            <p>1</p>
          </div>
          <div className="px-3">
            <p><b>COMMON INTEREST AND PURPOSE</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>1.1</p>
              </div>
              <div className="px-3">
                <p>The Claimants are likely to be pursuing the same or similar issues to those
                  of other Claimants in the group, although the facts and value of their claims may
                  differ.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>1.2</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree that they have a common interest in pursuing the
                  Proceedings and warrant, by joining the Proceedings, that they have no interest
                  adverse to the success of the Proceedings (including any financial interest in or
                  relationship with any known Opponent).</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>1.3</p>
              </div>
              <div className="px-3">
                <p>The Claimants’ common interest is in attempting to achieve a successful
                  outcome in the Proceedings and recovering the largest possible sum in
                  compensation for the group of Claimants as a whole. It is understood that if the
                  Proceedings are successful at trial, the court may order that some Claimants be
                  compensated in a different way from others.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>1.4</p>
              </div>
              <div className="px-3">
                <p>If there is a settlement, the Claimants further understand and agree that
                  no detailed account will be taken of the individual merits or otherwise of individual
                  Claimants’ cases and they accept that to do so would be disproportionately
                  expensive and burdensome.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>1.5</p>
              </div>
              <div className="px-3">
                <p>Accordingly, the overriding purpose will be to treat the Claimants fairly and
                  (unless inappropriate) equally. In appropriate circumstances (e.g. if some
                  Claimants have claims which are readily and cheaply identifiable as significantly
                  more or less valuable than others), the Claimants accept that to achieve broad
                  fairness it may be necessary to distinguish between different categories of
                  Claimant, e.g. by providing for different damages to be paid to Claimants who fall
                  within particular categories.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>2</p>
          </div>
          <div className="px-3">
            <p><b>SHARING OF INFORMATION, CONFIDENTIALITY AND PRIVILEGE</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>2.1</p>
              </div>
              <div className="px-3">
                <p>Information which is confidential to the Claimants is referred to as <b>Confidential
                  Information.</b> The Claimants agree that the Confidential Information they supply to
                  the Solicitors in the course of the Proceedings may be shared with other
                  Claimants, the Committee, and with other interested parties who act in the
                  Claimants’ interests [collectively <b>Stakeholders</b> ] including (without limitation, but
                  only where and to the extent appropriate) counsel, experts, litigation funders
                  (including ATE insurers), and other firms of solicitors who act in the Proceedings
                  for clients with the same or similar interests as the Claimants and with whom it is
                  in the Claimants’ interests for the Solicitors to co-operate.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>2.2</p>
              </div>
              <div className="px-3">
                <p>The Claimants also agree that the duty of confidentiality owed to them individually
                  by the Solicitors in respect of their Confidential Information shall be waived as
                  against their fellow Claimants, the Committee and any other Stakeholders. The
                  Claimants’ Confidential Information will only be shared on terms of confidentiality
                  and without any waiver of privilege.</p>
              </div>
            </div>



          </div>
        </div>

      </section>

      {/* <!-- page-26 --> */}
      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div className="px-3">
            <div className="d-flex ps-5">
              <div>
                <p>2.3</p>
              </div>
              <div className="px-3">
                <p>The Solicitors shall be authorised to report to the Claimants as a body (or where
                  appointed to the Committee) on the facts underlying each claimant’s claim,
                  including the facts stated in the evidence disclosed by the Opponents.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>2.4</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree that the fact and terms of any offer to settle made to any
                  individual claimant in the Proceedings will be disclosed by them to the Solicitors
                  and may be disclosed by the Solicitors to any other claimant, the Committee and
                  other Stakeholders.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>2.5</p>
              </div>
              <div className="px-3">
                <p>The Claimants further agree that, if the Solicitors consider it to be necessary or
                  helpful, it may use information or documents derived from one claimant’s
                  individual claim in any other claimant’s individual claim or in the Proceedings in
                  general.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>2.6</p>
              </div>
              <div className="px-3">
                <p>Any information shared amongst the Claimants and between the Claimants and
                  the Committee and other Stakeholders shall remain fully confidential and subject
                  to common interest privilege (wherever possible) as against any person who is not
                  either a claimant or a professional adviser of a claimant with a duty of
                  confidentiality to that claimant, and the Claimants undertake that they will keep
                  all such information fully confidential and will not disclose any such information to
                  any third party except for the purposes of obtaining professional advice or where
                  disclosure is required by law.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>2.7</p>
              </div>
              <div className="px-3">
                <p>If a claimant ceases to be a party to this Agreement, his or her duty of
                  confidentiality and common interest in any privilege shall continue with full force
                  and effect.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>2.8</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree that it is in the best interests of the Claimants as a collective
                  group for communications with them to be limited and circumspect, both in the
                  interests of economy and in order to ensure confidentiality. Where a Committee is
                  appointed, it is further agreed that the Solicitors’ duty to report to their individual
                  clients shall be satisfied by the Solicitors reporting to the Committee, and each
                  claimant further agrees that their entitlement to information and documents
                  relating to the Proceedings will be satisfied by the Solicitors’ production of the
                  same to the Committee.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>2.9</p>
              </div>
              <div className="px-3">
                <p>All communications between the Solicitors and the Claimants shall be subject to
                  legal professional privilege and the Claimants irrevocably agree that solicitor client
                  privilege shall not be waived or abrogated in any way by the passing of
                  Confidential Information amongst the Claimants.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>2.10</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree that they will not disclose to any person who is not either a
                  claimant or a professional adviser of a claimant with a duty of confidentiality to
                  that claimant, any advice received from the Solicitors, or any other communication
                  received from the Solicitors, another claimant, the Committee or other
                  Stakeholders in connection with the Proceedings.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>2.11</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree that the Solicitors may, without any obligation to do so, for
                  reasons of cost efficiency or otherwise, instruct a third party firm to manage some
                  of the administrative burden of the Proceedings and, in particular:</p>
              </div>
            </div>

            <div className="d-flex ps-2">
              <div>
                <p>a</p>
              </div>
              <div className="px-3">
                <p>to maintain a secure website and related software to facilitate on-boarding,
                  claim filtering and data capture, communication and the exchange of information between
                  the Claimants, the Committee and the Solicitors; and</p>
              </div>
            </div>
          </div>
        </div>


      </section>

      {/* <!-- page-27 --> */}
      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div className="px-3">
            <div className="d-flex ps-2">
              <div>
                <p>b</p>
              </div>
              <div className="px-3">
                <p>to instruct a third party claims administrator to administer and distribute any
                  compensation.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>3</p>
          </div>
          <div className="px-3">
            <p><b>CLAIMANTS’ COSTS</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>3.1</p>
              </div>
              <div className="px-3">
                <p>Each claimant’s liability for their own costs will be provided for by the terms of the
                  retainer entered into by each of them with the Solicitors.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>3.2</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree to the Solicitors conducting, so far as may be possible, the
                  common elements of their separate claims jointly, and for the Common Costs to
                  be shared in accordance with this Agreement. For the avoidance of doubt, the
                  Claimants’ Common Costs shall be deemed to include, without limitation: the costs
                  and disbursements incurred in building the Claimant group; any premiums for ATE
                  insurance; the costs of managing the Claimants as a group, including the costs of
                  attending on potential Claimants, whether or not they subsequently join the
                  group; together with the costs of promoting the Proceedings to other potential
                  Claimants and the costs of establishing and maintaining an on-line portal; PR and
                  advertising costs; and the expenses of the Committee. The Claimants acknowledge
                  and agree their liability to pay such costs notwithstanding that they would be
                  deemed to be costs of an unusual nature within the meaning of rule 46.9(3)(c) of
                  the Civil Procedure Rules 1998, and are in any event costs which may be
                  irrecoverable from the Opponents in the event of a successful outcome.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>3.3</p>
              </div>
              <div className="px-3">
                <p>In the event that a Committee is appointed, it is agreed that the Solicitors’
                  reporting on costs to the Committee will be a sufficient report, and that the
                  Solicitors will have no further duty to report on costs to claimants individually.</p>
              </div>
            </div>

          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>4</p>
          </div>
          <div className="px-3">
            <p><b>COSTS SHARING</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>4.1</p>
              </div>
              <div className="px-3">
                <p>The provisions in this section of the Agreement are subject to the court ordering
                  otherwise, or the Parties subsequently agreeing different costs sharing provisions.
                  In the event of inconsistency, the provisions in this section in respect of sharing
                  Common Costs will prevail over the terms of the Solicitors’ retainers save to the
                  extent necessary to ensure that any relevant parts of the Solicitors’ retainers
                  comply with s.58AA Courts &amp; Legal Services Act 1990 and the DBA Regulations
                  2013 (or any successor legislation).</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>4.2</p>
              </div>
              <div className="px-3">
                <p>Each claimant shall be solely liable for their Individual Costs.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>4.3</p>
              </div>
              <div className="px-3">
                <p>In the event of a successful outcome to the Proceedings:</p>
                <ol type="i">
                  <li> Each Claimant shall be liable for their respective Payment in accordance with the
                    terms of their Damages Based Agreement;</li>
                  <li> Where any part of the Claimants’ Expenses relate to Common Costs, liability
                    for those Common Costs Expenses shall be shared by those Claimants who recover
                    compensation and/or who are awarded their share of the Common Costs
                    [ <b>Successful Claimants</b> ];</li>
                  <li>Each Successful Claimant shall be liable for a Proportionate Share of any
                    Common Costs Counsels’ Fees. In accordance with the terms of the Damages Based
                    Agreements, each Successful Claimant’s liability for Counsels’ Fees (whether on an
                    Individual or Common Costs basis) shall be satisfied by the Solicitors out of their DBA
                    Payment;</li>
                </ol>
              </div>
            </div>

          </div>
        </div>

      </section>

      {/* <!-- page-28 --> */}
      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div className="px-3">
            <div className="d-flex ps-5">
              <div className="px-3">
                <ol type="i" start="4">
                  <li> For the purposes of CPR 44.18 and Between the Parties Costs Recovery, each
                    Successful Claimant shall be treated as having a liability for a Proportionate Share of
                    Common Costs Profit Costs;</li>
                </ol>
              </div>
            </div>


          </div>
        </div>

        <div className="d-flex ps-5">
          <div className="px-3">
            <div className="d-flex ps-5">
              <div>
                <p>4.4</p>
              </div>
              <div className="px-3">
                <p>In so far as any Successful Claimants are liable for Common Costs, whether in
                  relation to Common Costs Expenses or Counsels’ Fees or whether on a notional
                  basis for the purpose of CPR 44.18 in respect of Profit Costs, the Successful
                  Claimants shall share Common Costs as follows:</p>
                <div className="d-flex ps-3">
                  <div>
                    <p>4.4.1</p>
                  </div>
                  <div className="px-3">
                    <p>Liability for Common Costs will be divided between the
                      Successful Claimants in Proportionate Shares (as defined
                      below);</p>
                  </div>
                </div>

                <div className="d-flex ps-3">
                  <div>
                    <p>4.4.2</p>
                  </div>
                  <div className="px-3">
                    <p>Irrespective of the date on which any given Successful Claimant
                      first instructed the Solicitors, liability for Common Costs will be
                      shared (i) in the event that court specifies a date for inter-
                      Claimant costs sharing to begin, from that date; or otherwise
                      (ii) from the earliest date that Common Costs began to be
                      incurred;</p>
                  </div>
                </div>

                <div className="d-flex ps-3">
                  <div>
                    <p>4.4.3</p>
                  </div>
                  <div className="px-3">
                    <p>Liability for Common Costs shall then be calculated in quarterly
                      accounting periods, and for ease of calculation a Successful
                      Claimant whose claim is concluded (e.g. by settlement) during
                      any given quarter will remain liable to pay their share of
                      Common Costs until the end of that quarter.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>4.5</p>
              </div>
              <div className="px-3">
                <p>For the avoidance of doubt, nothing in these Common Costs sharing provisions
                  shall be taken to impose a liability on any Claimant to pay any sum greater than
                  the sums due under the terms of the Damages Based Agreement.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>4.6</p>
              </div>
              <div className="px-3">
                <p>A Successful Claimant’s <b>Proportionate Share</b> of Common Costs will be determined
                  as follows:</p>
                <div className="d-flex ps-3">
                  <div>
                    <p>4.6.1</p>
                  </div>
                  <div className="px-3">
                    <p>The default position is that Common Costs will be divided
                      equally between each Successful Claimant in each quarter, so
                      that each such Claimant pursuing a claim within any part of
                      that quarter pays an equal share;</p>
                  </div>
                </div>
                <div className="d-flex ps-3">
                  <div>
                    <p>4.6.2</p>
                  </div>
                  <div className="px-3">
                    <p>But where appropriate in the interests of fairness, the
                      Solicitors may specify in writing some other methodology and
                      communicate that (i) to the Committee if appointed; or (ii) the
                      Claimants by a circular email, in which case the Proportionate
                      Share shall instead be determined in accordance with that
                      methodology.</p>
                  </div>
                </div>
                <p className="py-4">By way of illustration of clause 4.5.2, in a case where the Proceedings relate to
                  defective products, it may be fairer for costs to be shared with reference to the
                  number of defective products in respect of which each claimant claims, so that for
                  example the Proportionate Share of a claimant bringing proceedings in respect of
                  three such products is three times greater than the share of a claimant with one
                  such product. Alternatively, in a claim relating to mis-sold investments, it may for
                  example be fairer for costs to be shared with reference to the value of each claimant’s
                  investment, so that the Proportionate Share of a claimant who invested
                  £3,000 is three times greater than the share of a claimant who invested £1,000.</p>
              </div>
            </div>

          </div>
        </div>


      </section>

      {/* <!-- page-29 --> */}
      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div className="ps-5">
            <p>4.7</p>
          </div>
          <div className="px-3">
            <p>The Claimants agree that the Solicitors may apply to the court for a costs sharing
              order (if appropriate as part of a group litigation order). In the event of such an
              order, costs shall then be shared in accordance with its terms pursuant to clause
              4.1 above,</p>
          </div>
        </div>
        <div className="d-flex ps-5">

          <div>
            <p>5</p>
          </div>
          <div className="px-3">
            <p><b>SHARING OF THE RISK OF PAYING ADVERSE COSTS</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>5.1</p>
              </div>
              <div className="px-3">
                <p>Notwithstanding any indemnity against adverse costs from a Stakeholder, the
                  Claimants may be ordered to pay an Opponent’s Common Costs. Each Claimant
                  may also become liable for any Opponent’s Individual Costs attributable to their
                  claim.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>5.2</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree collectively that as between themselves the Claimants’
                  liability for Opponent’s Common Costs should be several and not joint, and (to the
                  extent that such liability is not indemnified by a Stakeholder) insofar as any
                  claimant is ordered to pay more than a Proportionate Share of such costs, each
                  other claimant will contribute a Proportionate Share of that claimant’s resulting
                  excess liability. Save that this obligation will not arise if the costs order against the
                  relevant claimant results from their unreasonable conduct.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>5.3</p>
              </div>
              <div className="px-3">
                <p>Clause 5.2 shall be subject to any contrary costs sharing order made by the Court.</p>
              </div>
            </div>

          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>6</p>
          </div>
          <div className="px-3">
            <p><b>AUTHORITY OF THE SOLICITORS AND AUTHORITY AND CONSTITUTION OF THE
              COMMITTEE</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>6.1</p>
              </div>
              <div className="px-3">
                <p>In order to facilitate the economical and efficient pursuit of the Proceedings, the
                  Claimants authorise the Solicitors to conduct the proceedings without recourse to
                  the Claimants for specific instructions, except in the event of a Key Development.
                  In exercising their authority under this clause, the Solicitors will at all times (i)
                  exercise reasonable professional judgement, care and skill; (ii) pay due regard to
                  the reasonable advice of counsel and inform the Claimants (or if appointed the
                  Committee) of any significant disagreement between the Solicitors and counsel;
                  and (iii) act in the best interests of the Claimants as a group, even if that may be
                  unfavourable to the interests of a particular Claimant (subject always to the
                  Solicitors’ professional obligations to the court and their regulatory obligations).</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>6.2</p>
              </div>
              <div className="px-3">
                <p>Without derogation from the generality of clause 6.1 , the authority of the
                  Solicitors shall include:</p>
                <div className="d-flex ps-2">
                  <div>
                    <p>6.2.1</p>
                  </div>
                  <div className="px-3">
                    <p>Entering and executing any litigation funding agreement
                      previously approved by the Claimants or the Committee;</p>
                  </div>
                </div>
                <div className="d-flex ps-2">
                  <div>
                    <p>6.2.2</p>
                  </div>
                  <div className="px-3">
                    <p>Dealing with any litigation funder, to include (with limitation)
                      obtaining or agreeing any necessary authorities or budget
                      variations;</p>
                  </div>
                </div>
                <div className="d-flex ps-2">
                  <div>
                    <p>6.2.3</p>
                  </div>
                  <div className="px-3">
                    <p>Determining case strategy and pursuing the Proceedings
                      accordingly;</p>
                  </div>
                </div>
                <div className="d-flex ps-2">
                  <div>
                    <p>6.2.4</p>
                  </div>
                  <div className="px-3">
                    <p>Dealing with all third party and inter partes correspondence;</p>
                  </div>
                </div>
                <div className="d-flex ps-2">
                  <div>
                    <p>6.2.5</p>
                  </div>
                  <div className="px-3">
                    <p>Instructing counsel and experts;</p>
                  </div>
                </div>
                <div className="d-flex ps-2">
                  <div>
                    <p>6.2.6</p>
                  </div>
                  <div className="px-3">
                    <p>Incurring reasonable disbursements;</p>
                  </div>
                </div>
                <div className="d-flex ps-2">
                  <div>
                    <p>6.2.7</p>
                  </div>
                  <div className="px-3">
                    <p>Procuring and incepting ATE insurance (and thereby incurring
                      liability for the resulting premium(s));</p>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>

      </section>

      {/* <!-- page-30 --> */}
      <section className="container-1 my-5" >
        <div className="d-flex ps-5">
          <div className="px-3">
            <div className="d-flex ps-5">
              <div className="px-3">
                <div className="d-flex ps-4">
                  <div>
                    <p>6.2.8</p>
                  </div>
                  <div className="px-3">
                    <p>Procuring security to satisfy any court order or requirement to
                      provide security in the Proceedings;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>6.2.9</p>
                  </div>
                  <div className="px-3">
                    <p>Issuing and serving Court proceedings and attending hearings;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>6.2.10</p>
                  </div>
                  <div className="px-3">
                    <p>Engaging in settlement negotiations;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>6.2.11</p>
                  </div>
                  <div className="px-3">
                    <p>In the event of a successful outcome, allocating and
                      distributing the compensation (subject to the terms of any
                      priorities agreement and/or funding agreements);</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>6.2.12</p>
                  </div>
                  <div className="px-3">
                    <p>Taking any reasonable steps necessary to enforce any order or
                      settlement.</p>
                  </div>
                </div>

              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>6.3</p>
              </div>
              <div className="px-3">
                <p>When exercising the authority granted in clauses 6.1 and 6.2, the Solicitors shall
                  nevertheless update the Claimants on an at least a quarterly basis on the progress
                  of the Proceedings (unless the proceedings are stayed).</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>6.4</p>
              </div>
              <div className="px-3">
                <p>Clauses 6.1 to 6.3 shall not apply in the event that a Committee is appointed
                  pursuant to clause 6.5.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>6.5</p>
              </div>
              <div className="px-3">
                <p>Where, in the reasonable professional judgement of the Solicitors, the
                  appointment of a Committee would further the best interests of the Claimants by
                  enabling the Proceedings to be conducted more effectively and efficiently, the
                  Claimants irrevocably and unconditionally authorise the Solicitors to appoint the
                  Committee (and each Committee Member) to act as the Claimants’ agent in
                  relation to the Proceedings and the management thereof. The agency includes,
                  without limitation, authority to:</p>
                <div className="d-flex ps-4">
                  <div>
                    <p>6.5.1</p>
                  </div>
                  <div className="px-3">
                    <p>take any step or make any decision that the Solicitors would be entitled to
                      take or make under clause 6.1 above;</p>
                  </div>
                </div>

                <div className="d-flex ps-4">
                  <div>
                    <p>6.5.2</p>
                  </div>
                  <div className="px-3">
                    <p>instruct the Solicitors to discontinue, the Proceedings, whether on behalf
                      of one or more of the Claimants;</p>
                  </div>
                </div>

                <div className="d-flex ps-4">
                  <div>
                    <p>6.5.3</p>
                  </div>
                  <div className="px-3">
                    <p>instruct the Solicitors to make all necessary payments to fully discharge all
                      liabilities and obligations owed by the Claimants under any litigation
                      funding agreement or ATE insurance policy.</p>
                  </div>
                </div>

                <div className="d-flex ps-4">
                  <div>
                    <p>6.5.4</p>
                  </div>
                  <div className="px-3">
                    <p>reconstitute the Committee, including but not limited to the removal or
                      replacement of a Committee Member, in the event that the Solicitors
                      agree (agreement not to be unreasonably withheld) that to be necessary in
                      the interests of the Claimants as a group;</p>
                  </div>
                </div>

                <div className="d-flex ps-4">
                  <div>
                    <p>6.5.5</p>
                  </div>
                  <div className="px-3">
                    <p>enter, amend or terminate retainers for the Solicitors, litigation funding
                      agreements, priorities agreements or ATE insurance policies, whether on
                      behalf of the Claimants as a group or any individual Claimant.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex ps-4">
              <div>
                <p>6.6</p>
              </div>
              <div className="px-3">
                <p>The Claimants further agree to ratify and affirm anything done by the Committee in
                  respect of the Proceedings in the proper performance of its role.</p>
              </div>
            </div>

          </div>
        </div>

        <div className="d-flex ps-4">
          <div>
            <p>7</p>
          </div>
          <div className="px-3">
            <p><b>THE COMMITTEE</b></p>

            <div className="d-flex ps-4">
              <div>
                <p>7.1</p>
              </div>
              <div className="px-3">
                <p>Where the Solicitors determine that a Committee should be appointed, they shall as
                  soon as practicable appoint at least three and not more than five Committee
                  Members [ <b>Initial Committee Members</b> ], who shall themselves be Claimants (or in
                  the case of claimants who are not individuals, employees, directors or officers of a
                  claimant with executive authority within that claimant), who have expressed a
                  willingness to act as Committee Members.</p>
              </div>
            </div>
          </div>
        </div>

      </section>

      {/* <!-- page-31 --> */}
      <section className="container-1 my-5">
        <div className="d-flex ps-5">
          <div className="px-3">
            <div className="d-flex ps-5">
              <div>
                <p>7.2</p>
              </div>
              <div className="px-3">
                <p>Save for the appointment of the Initial Committee Members pursuant to clause 7.1 ,
                  a person shall be appointed as a Committee Member by the resolution of a Majority
                  of the Committee at a properly convened meeting of the Committee, each
                  appointment to be subject to the consent of the Solicitors (which is not to be
                  unreasonably withheld).</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>7.3</p>
              </div>
              <div className="px-3">
                <p>A person shall cease to be a Committee Member as soon as:</p>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.3.1</p>
                  </div>
                  <div className="px-3">
                    <p>he or she retires by notifying each Committee member and the Solicitors in
                      writing;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.3.2</p>
                  </div>
                  <div className="px-3">
                    <p>he or she dies;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.3.3</p>
                  </div>
                  <div className="px-3">
                    <p>he or she becomes incapable by reason of a mental disorder, illness, injury,
                      of managing and administering his or her affairs;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.3.4</p>
                  </div>
                  <div className="px-3">
                    <p>he or she has a bankruptcy order made against him or her; or</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.3.5</p>
                  </div>
                  <div className="px-3">
                    <p>a Majority of the Committee Members resolve at a properly convened
                      meeting of the Committee, that he or she should cease to be a Committee
                      Member and the Solicitors approve that decision.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>7.4</p>
              </div>
              <div className="px-3">
                <p>The following rules shall govern Committee meetings:</p>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.4.1</p>
                  </div>
                  <div className="px-3">
                    <p>Committee meetings must be held in the presence of the Solicitors, may
                      be called by the Solicitors or by any Committee Member and may be
                      held in person on seven days’ notice, or by telephone/video conference
                      on 24 hours’ notice, such notice to be given by email, or by other means
                      if so agreed by a Majority of Committee Members;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.4.2</p>
                  </div>
                  <div className="px-3">
                    <p>Committee meetings held by the Committee Members shall be
                      considered quorate only when more than 50 per cent of Committee
                      Members plus the Solicitors are in attendance, whether in person or by
                      telephone/video conference.</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.4.3</p>
                  </div>
                  <div className="px-3">
                    <p>no person who is not a Committee Member or a representative of the
                      Solicitors shall be entitled to attend a Committee meeting other than by
                      the invitation of the Committee or the Solicitors;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.4.4</p>
                  </div>
                  <div className="px-3">
                    <p>minutes must be kept of all meetings by the Solicitors and thereafter
                      supplied to the Committee for approval within 5 working days;</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.4.5</p>
                  </div>
                  <div className="px-3">
                    <p>the Committee shall agree by a Majority of the votes cast to appoint a
                      Chairperson from time to time, and the Chairperson may exercise a
                      casting vote in the event of a tied vote (in addition to his or her ordinary
                      deliberative vote);</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.4.6</p>
                  </div>
                  <div className="px-3">
                    <p>the Committee may dismiss the Chairperson for the time being and
                      appoint a new Chairperson by a Majority of the votes cast; and</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>7.4.7</p>
                  </div>
                  <div className="px-3">
                    <p>the Committee shall act reasonably and in the best interests of the
                      Claimants as a group, and shall make decisions based on, and (absent
                      reasonable grounds for disagreement) in accordance with, the advice
                      from the Solicitors.</p>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>


      </section>

      {/* <!-- page-32 --> */}
      <section className="container-1 my-5">
        <div className="d-flex ps-5">
          <div className="px-3">

            <div className="d-flex ps-5">
              <div>
                <p>7.5</p>
              </div>
              <div className="px-3">
                <p>All business of the Committee will be resolved (where necessary) by the vote of a
                  Majority.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>7.6</p>
              </div>
              <div className="px-3">
                <p>Subject to clause 7.4.7 above and to complying with their obligations of
                  confidentiality, no Committee Member shall be liable to the Claimants (or any of
                  them) for their own acts, neglects or defaults or for any loss to the Claimants
                  incurred in connection with their role as a Committee Member, unless caused
                  through his/her own fraud, fraudulent misrepresentation and/or dishonesty. Nor,
                  subject to the same qualifications, will any Committee Member be liable for costs
                  by virtue of their participation in the Committee, and in the event of any order for
                  Opponent’s costs being made against a Committee Member by virtue of their
                  membership of the Committee, the Claimants shall, absent unreasonable conduct
                  by that Committee Member, indemnify them.</p>

              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>7.7</p>
              </div>
              <div className="px-3">
                <p>No Committee Member shall be liable for the acts, neglects or defaults of any other
                  Committee Member.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>7.8</p>
              </div>
              <div className="px-3">
                <p>The Committee Members shall be reimbursed reasonable out of pocket expenses
                  incurred by them as a result of their duties as Committee Members. As a condition
                  of payment, any expenses shall (where reasonably possible and exceeding the sum
                  of £20) be submitted to the Solicitors for approval prior to the expenses being
                  incurred. Such expenses shall on approval be paid by the Solicitors as a
                  disbursement and shall be part of the Common Costs.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>7.9</p>
              </div>
              <div className="px-3">
                <p>The Committee Members must submit any expenses claim to the Solicitors within
                  28 days.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>7.10</p>
              </div>
              <div className="px-3">
                <p>Committee Members shall not be entitled to payment in respect of time incurred in
                  attending Committee Meetings or in respect of related time spent.</p>
              </div>
            </div>


          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>8</p>
          </div>
          <div className="px-3">
            <p><b>APPORTIONMENT AND DISTRIBUTION OF COMPENSATION</b></p>

            <div className="d-flex ps-5">
              <div>
                <p>8.1</p>
              </div>
              <div className="px-3">
                <p>The Claimants recognise that if there are negotiations to settle the Proceedings, it is
                  likely that any offers made will be on a global basis covering all or some of the
                  claims in the Proceedings and relating to the collective/aggregated claims of the
                  Claimants. The Claimants specifically authorise the Solicitors (and where
                  appropriate the Committee) to solicit offers from the Opponents and to negotiate
                  on such a global basis.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>8.2</p>
              </div>
              <div className="px-3">
                <p>Without limitation of the authorities elsewhere granted by this Agreement, and
                  subject to the terms of any litigation funding agreement, priorities agreement, ATE
                  policy or Solicitors’ retainer, the Claimants specifically authorise the Solicitors (or
                  where appropriate the Committee) to allocate and distribute compensation in
                  whatever manner and at whatever time they deem appropriate, subject always to
                  the requirement to act fairly and reasonably, and in the best interests of the
                  Claimants as a group. For the avoidance of doubt, the Claimants therefore
                  authorise the allocation and distribution of compensation without any detailed
                  account being taken of the individual merits or demerits, or specific value, of each
                  Claimant’s claim.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>8.3</p>
              </div>
              <div className="px-3">
                <p>Where any further proceedings (e.g. for enforcement or for the assessment of
                  costs) are in contemplation, the Claimants agree that where necessary a reasonable
                  sum can be retained by the Solicitors from the compensation for the purposes of
                  funding such proceedings, with any balance being distributed at the end of those
                  proceedings.</p>
              </div>
            </div>
          </div>
        </div>


      </section>

      {/* <!-- page-33 --> */}
      <section className="container-1 my-5">
        <div className="d-flex ps-5">
          <div className="px-3">

            <div className="d-flex ps-5">
              <div>
                <p>8.4</p>
              </div>
              <div className="px-3">
                <p>The Claimants agree that the distribution of compensation may, without any
                  obligation to do so, be effected though the instruction of a claims administrator.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>9</p>
          </div>
          <div className="px-3">
            <p><b>COMMENCEMENT AND BINDING EFFECT</b></p>
            <p>This Agreement shall commence on the date that the first Claimant accepts its terms
              and shall continue in respect of each new Claimant, and shall be further executed on
              the date that each further Claimant enters into this Agreement, such that each and
              every Claimant shall become a party to it.</p>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>10</p>
          </div>
          <div className="px-3">
            <p><b>CANCELLATION AND TERMINATION</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>10.1</p>
              </div>
              <div className="px-3">
                <p>Where a Claimant enters this Agreement as a consumer, they have a right to
                  cancel it in accordance with the Consumer Contracts (Information, Cancellation
                  and Additional Charges) Regulations 2013. Please see the notice of the right to
                  cancel attached.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>10.2</p>
              </div>
              <div className="px-3">
                <p>This Agreement shall otherwise continue in full force and effect until the
                  conclusion of the Proceedings.</p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>10.3</p>
              </div>
              <div className="px-3">
                <p>In the event of one or more Claimants ceasing to be a party to this Agreement for
                  any reason, it is agreed that the obligations of the remaining Claimants to each
                  another and to the Solicitors and to the Funder will continue in all respects.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>11</p>
          </div>
          <div className="px-3">
            <p><b>COMMUNICATION, COOPERATION, CONFIDENTIALITY, DISPUTE RESOLUTION AND
              NOTICES</b></p>
            <div className="d-flex ps-5">
              <div>
                <p>11.1</p>
              </div>
              <div className="px-3">
                <p>The Claimants will respond promptly to communications from the Committee and
                  the Solicitors, and will provide all possible assistance to the Solicitors in connection
                  with the Proceedings, including as to:</p>
                <div className="d-flex ps-5">
                  <div>
                    <p>11.1.1</p>
                  </div>
                  <div className="px-3">
                    <p>the signing of necessary documents and confirmations that may be
                      required during the Proceedings; and</p>
                  </div>
                </div>
                <div className="d-flex ps-5">
                  <div>
                    <p>11.1.2</p>
                  </div>
                  <div className="px-3">
                    <p>the collation and disclosure of documents and data,</p>
                  </div>
                </div>
                <p className="ps-5">
                  recognising that if they do not, they may: (1) damage their case and those of their
                  fellow claimants; and (2) breach and potentially invalidate any ATE insurance policy.
                  Because of the importance to the Claimants of cooperation if, in the Solicitors’
                  reasonable opinion, any Claimant has persistently and unreasonably failed to
                  comply with requests for cooperation such that they are obstructing the efficient
                  progress of the Proceedings [an <b>Obstructive Claimant</b> ], the Solicitors (or the
                  Committee if appointed) may, after giving 14 days notice to the Obstructive
                  Claimant and absent any subsequent resolution of the position by that claimant,
                  without further authority, discontinue the claim of that Obstructive Claimant. The
                  Obstructive Claimant will thereby lose any right to compensation and will usually
                  become personally liable to pay the Opponent’s costs. It is agreed that in these
                  circumstances the Obstructive Claimant will have no right of recourse whatsoever
                  against the Solicitors or Committee Members, whatever the nature, cause or extent
                  of the Obstructive Claimant’s resulting loss.
                </p>
              </div>
            </div>
            <div className="d-flex ps-5">
              <div>
                <p>11.2</p>
              </div>
              <div className="px-3">
                <p>The Claimants will keep this Agreement and its terms confidential unless required
                  by the Court to disclose it in the Proceedings. This clause shall not prevent
                  disclosure of the Claimants’ costs sharing arrangements to the Opponent and the
                  Court where that is the interests of the Claimants as a group (e.g. for the purposes
                  of applying for a costs sharing order).</p>
              </div>
            </div>

          </div>
        </div>

      </section>

      {/* <!-- page-34 --> */}
      <section className="container-1 my-5">
        <div className="d-flex ps-5">
          <div className="px-3">

            <div className="d-flex ps-5">
              <div>
                <p>11.3</p>
              </div>
              <div className="px-3">
                <p>This Agreement shall be governed by the laws of England and Wales.</p>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>11.4</p>
              </div>
              <div className="px-3">
                <p>Unless otherwise agreed by the Parties in writing, any dispute, controversy or claim
                  arising out of or relating to this Agreement, including any question regarding its
                  breach, existence, validity or termination or the legal relationships established by
                  this Agreement or any non-contractual claims (whether in tort or otherwise), shall
                  be referred to and finally determined by arbitration, and the following shall apply
                  (with normal time limits subject to abridgement in the event of urgency):</p>
                <div className="d-flex ps-5">
                  <div>
                    <p>11.4.1</p>
                  </div>
                  <div className="px-3">
                    <p>The tribunal shall consist of one arbitrator who is to be a
                      Queen’s Counsel or retired judge of High Court of higher rank in
                      England and Wales, to be appointed by the Parties or, failing
                      agreement by the Parties within 14 days of service of written
                      notice by either party to the other party requesting agreement
                      to the appointment of an arbitrator, the appointing authority
                      shall be the London Court of International Arbitration.</p>

                  </div>
                </div>
                <div className="d-flex ps-5">
                  <div>
                    <p>11.4.2</p>
                  </div>
                  <div className="px-3">
                    <p>The seat of the arbitration shall be London.</p>

                  </div>
                </div>
                <div className="d-flex ps-5">
                  <div>
                    <p>11.4.3</p>
                  </div>
                  <div className="px-3">
                    <p>The governing law will be English law; and</p>

                  </div>
                </div>
                <div className="d-flex ps-5">
                  <div>
                    <p>11.4.4</p>
                  </div>
                  <div className="px-3">
                    <p>The language of the arbitration shall be English.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ps-5">
          <div>
            <p>12</p>
          </div>
          <div className="px-3">
            <p><b>NOTICES</b></p>

            <div className="d-flex ps-5">
              <div>
                <p>12.1</p>
              </div>
              <div className="px-3">
                <p>Any notice or other communication given to any party under or in connection
                  with this Agreement shall be in writing and shall be sent by email to the address
                  specified below:</p>
                <div className="d-flex w-100">
                  <div className="w-50">
                    <p><b> Party</b></p>
                    <p>The Committee</p>
                    <p>The Solicitors</p>
                  </div>

                  <div className="w-50">
                    <p><b> Email Address</b></p>
                    <p><a href="#">emissions@johnsonlawgroup.co.uk</a></p>
                    <p><a href="#">emissions@johnsonlawgroup.co.uk</a></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>12.2</p>
              </div>
              <div className="px-3">
                <p>Any notice or communication shall be deemed to have been received:</p>
                <div className="d-flex ps-4">
                  <div>
                    <p>12.2.1</p>
                  </div>
                  <div className="px-3">
                    <p>if the notice is received before 4pm, the day it was sent; or</p>
                  </div>
                </div>
                <div className="d-flex ps-4">
                  <div>
                    <p>12.2.2</p>
                  </div>
                  <div className="px-3">
                    <p>if the notice is received after 4pm, the following business day.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex ps-5">
              <div>
                <p>12.3</p>
              </div>
              <div className="px-3">
                <p>This clause does not apply to the service of any proceedings or other documents
                  in any legal action or, where applicable, any arbitration or other method of
                  dispute resolution.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex ps-5">
          <div>
            <p>13</p>
          </div>
          <div className="px-3">
            <p><b>CONTRACTS (RIGHTS OF THIRD PARTIES) ACT 1999 ETC</b></p>
            <p>The Parties agree and accept that each person that becomes a party to this Agreement
              shall be entitled to enforce its terms against any other party, including each and every
              claimant that joins the Proceedings after the first claimant.</p>
          </div>
        </div>
      </section>

      {/* <!-- page-35 --> */}
      <section className="container-1 my-5" >


        <div className="d-flex ps-5">
          <div>
            <p>14</p>
          </div>
          <div className="px-3">
            <p><b>SEVERANCE</b></p>
            <p>If any provision or part-provision of this Agreement is or becomes invalid, illegal or
              unenforceable (or would if given effect render this Agreement or any part of it invalid,
              illegal or unenforceable), it shall be deemed deleted, but that shall not affect the validity
              and enforceability of the rest of this Agreement. If any provision or part-provision of this
              Agreement is deemed deleted under this clause, the parties shall negotiate in good faith
              (for which purpose any Committee shall be so authorised by the Claimants) to agree a
              replacement provision that, to the greatest extent possible, achieves the intended
              commercial result of the original provision.</p>
          </div>
        </div>


        <div className="box-declaration">
          <p className="mb-0">By signing your agreement(s) with us you are confirming that you are happy to engage us in
            accordance with our terms and conditions and accept and agree to the above documentation.</p>
        </div>
      </section>
      {/* <!-- page-36 --> */}
      <section className="container-1 my-5">
        <h5 className="text-center text-decoration-underline"> YOUR CLIENT CARE PACK</h5>
        <p> This is an information sheet that advises the various ways in which you might be able to “fund
          litigation”. In other words, how you fund and pay for your solicitor’s fees and any disbursements we
          incur on your behalf.</p>
        <p>There are a number of ways you can do this:</p>
        <ol>
          <li> By entering into a private funding arrangement or private retainer with your solicitor.</li>
          <li>By entering into a “No-Win No-Fee” arrangement with your solicitor. There is more than one
            type of No-Win No-Fee arrangement.</li>
        </ol>
        <p className="text-decoration-underline pt-2"> <b> Private funding arrangement</b></p>
        <p>You could enter into a private funding arrangement. This is where you pay us our hourly charge out
          rate for the work we do on your case (win or lose). You will need to pay for all disbursements as they
          fall due. You would pay our fees (and fund disbursements) regardless of whether you win your case.</p>
        <p>The advantage of this form of arrangement is that you do not have to make a payment to us out of
          your compensation at the end of your case as we are getting paid regardless of the outcome of it.
          We do not usually recommend this method of funding for your type of claim because it means you
          are liable for our charges win or lose.</p>
        <p>If this is the funding option you would prefer, please do not sign this agreement and ask to speak to
          us about entering into a private retainer. By signing these papers it is taken that you have opted
          against entering into a private retainer with us and want to enter into a no win – no fee
          arrangement.</p>
        <p className="text-decoration-underline pt-2"><b>No Win – No Fee arrangement</b></p>
        <p>There are two main types of no win no fee arrangement.</p>
        <p>The first is called a Conditional Fee Agreement (‘CFA’). This is where;</p>
        <ol type="i">
          <li>If you Win, you pay our costs on a time spent hourly rate basis, together with our
            disbursements. This includes a share of any common costs incurred;</li>
          <li>If you Lose, you don’t pay our costs, but do pay our expenses, though these should
            be covered by your ATE policy.</li>
        </ol>
        <p>We do not offer a CFA for claims of this type, for a variety of reasons including that using an hourly
          rate basis as the usual basis for charging in a case of this type means that the amount of your liability
          for our fees and our entitlement to payment is very uncertain. Other firms of solicitors may offer
          CFAs.</p>
        <p>The second type of no win no fee agreement is a <b>Damages Based Agreement</b> (‘DBA’). This is the
          agreement we have asked you to enter into.</p>
        <p>Under a DBA;</p>
        <ol type="i">
          <li>you only pay our fees if you win your case;</li>
          <li>If you win, you pay our fees as a percentage of the of the damages you recover, not
            on a time spent hourly rate basis;</li>
          <li>That percentage is agreed by us at the outset so that from the outset you know if
            you win what part of your damages you will have to pay for our fees;</li>
        </ol>
        <p>Under the DBA, you will not be asked to pay any money up-front and we will finance all
          disbursements on your case whilst it is ongoing so you do not need to use your own personal
          savings.</p>

      </section>

      {/* <!-- page-37 --> */}
      <section className="container-1 my-5">


        <p>In return you agree to pay us a percentage of your recovered compensation. We call this “the
          Payment”. The Payment we charge is based on a commercial decision. It takes into account our view
          of the risks of this type of case and the work we expect to do. It is not based on specific facts or risks
          of your individual case, but it is the only way that we would agree to act for you on a &quot;No Win - No
          Fee&quot; basis.</p>
        <p><i>Funding disbursements in a Damages Based Agreement</i></p>
        <p>We agree to fund the disbursements ourselves for you. There is obviously a cost to this which is part
          of the commercial considerations in setting the percentage Payment that is payable.</p>

        <div className="box-declaration">
          <p className="text-decoration-underline"><b>Your Agreement with us</b></p>
          <p className="mb-0">By entering into this agreement(s) with Johnson Law Group you are confirming that you fully
            understand the various litigation funding options available to you as outlined above and that you are
            happy to engage us based on the “no win – no fee arrangement” we offer.</p>
        </div>
      </section>

      {/* <!-- page-38 --> */}
      <section className="container-1 my-5">
        <h5 className="text-center text-decoration-underline"> Insurance and Union Checker</h5>



        <p>Where litigation is likely, or we have to incur disbursements on your behalf, we will always make
          sure you are insured (or fully indemnified) beforehand so you will not have to pay those (or your
          opponent’s) costs if you lose your case. There are three possible ways in which you can get covered:</p>
        <ol>
          <li>You may already have litigation insurance yourself through your household insurance or a credit
            card and we ask you to check this. We call this <b> “Before the Event” insurance.</b> This will enable
            you to save the cost of paying for a policy specifically taken out for your case called “After the
            Event” insurance. You may also have the benefit of Union cover which can cover legal costs.</li>
          <li>
            If you have no litigation insurance or union cover, we may set up a policy of <b>After the Event
              insurance</b> to protect you against having to pay your opponent’s legal costs and any
            disbursements. The policy premium is not payable until the end of your case and will pay itself
            off if you lose – so you will not be out of pocket. If you win, you pay the premium out of your
            winnings. This is payable in addition to the Payment you are obliged to pay.
          </li>
          <li>
            Where cover is not available but we believe you have a viable claim and feel your access to
            justice would be otherwise hindered, JLG may agree to indemnify you against all adverse costs
            and disbursements in the event you do not win your case. This would only happen if the above
            two options are not available and we feel your case merits such support.
          </li>
        </ol>
        <p className="text-decoration-underline"><b> Before the Event (BTE) Legal Expense Insurance and Union Cover</b></p>
        <p>You will no doubt know if you are a member of a Union. You may have <b>Before the Event Legal
          Expense Insurance cover</b> and not realise. It may be available through your car, credit card or
          household insurance.</p>
        <p className="text-center"> <b> <i>You need to check and confirm to us whether or not you have any such cover.</i></b>
        </p>
        <p>If this is available <b>and</b> can be <b>used by us</b> for your benefit, then we would recommend you take
          advantage of it.</p>
        <p> If you think you may have BTE insurance or trade union cover, we are more than happy to consider
          any relevant documents on your behalf to try to establish this and determine if any such insurance is
          suitable for your case. <b>However, it remains your responsibility to let us know if you think you may
            have such funding.</b></p>
        <p> There can be disadvantages to funding a case through BTE Insurance. An important disadvantage is
          BTE Insurers often do not allow Claimants to choose their own solicitor, (at least until Court
          proceedings start). Whilst we will not usually act on such terms, it is important that you should
          consider whether you might have such BTE insurance. We would be happy to assist with any
          reasonable enquiries in this regard.</p>
        <p>Please note that if you do not bring any possible BTE insurance or trade union cover to our attention,
          but nevertheless you chose to instruct us under our DBA and Terms and Conditions of Business, you
          will be agreeing that you either:</p>
        <ol type="i">
          <li>do not have such insurance or union cover; or</li>
          <li>do not wish to make use of any such insurance or union cover that might be available to
            you.</li>
        </ol>

        <div className="box-declaration mt-5">
          <p className="text-decoration-underline"><b>Your Agreement with us</b></p>
          <p className="mb-0">By signing your agreement(s) with us you are confirming that you are happy to engage us in
            accordance with our terms and conditions and will advise us immediately if you already have
            litigation insurance so we can save you the cost of setting up “After the Event” insurance.</p>
        </div>
      </section>

      {/* <!-- page-39 --> */}
      <section className="container-1 my-5" >
        <h5 className="text-center text-decoration-underline"> Payment Calculation</h5>

        <p>The Payment is the amount you agree to pay out of any compensation you are awarded if you
          win your case. It is expressed as a percentage of any awarded compensation.</p>
        <p>The Payment is <b>50% inclusive of VAT</b> of the compensation or damages you recover in the
          proceedings covered by this Agreement.</p>
        <p>If we have to instruct a barrister then their fees will be part of the Payment. This means that you will
          not have to pay barristers fees in addition. Our other expenses are not part of the payment, but we
          limit your liability for them to the amounts that we can recover from your opponent or can claim
          under your policy of insurance.</p>
        <p>The Damages Based Agreement explains why the Payment is set at 50%. However, it is crucial we
          make clear that the calculation of the Payment is a fixed, standard fee which we apply to all claims of
          this type and is not set by reference to the risks of your individual case succeeding or failing. As
          such, the Payment may be higher than it would be if it were set solely by reference to the risk of
          your individual case and other firms of solicitors may offer no win – no fee agreements that are set
          by reference to the risks of the claim succeeding or failing. However, the only basis on which we
          are prepared to offer our services to you in a case of this type is on the basis of our Damages Based
          Agreement and its schedules and our Terms and Conditions of Business, which includes this fixed,
          standard percentage payment.</p>
        <p className="text-decoration-underline"> <b><i>Your right to choose</i></b></p>
        <p>JLG only offers this type of no win – no fee arrangement for the sort of work you require us to do.
          Other firms may offer slightly different agreements and different rates (some may be cheaper and
          others more expensive). You are of course under no obligation to instruct a solicitor at all and you
          may prefer to make complaints and claim direct. By agreeing to instruct us you are confirming that
          you are happy to enter into this arrangement with us and pay us a proportion of your damages for
          the work we do.</p>


        <div className="box-declaration mt-5">
          <p className="mb-0">By signing your agreement(s) with us you are confirming that you are happy to engage us in
            accordance with
            our terms and conditions and accept and agree to the above Payment Calculation.</p>
        </div>
      </section>

      {/* <!-- page-40 --> */}
      <section className="container-1 my-5">
        <header>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div>
              <img className="img-fluid" src={logoImg1} alt="" />
            </div>
            <div className="text-start text-md-end">
              <p>76 King Street, Manchester, M2 4NH</p>
              <p>Tel: 0161 513 2305</p>
              <p>Fax: 0161 513 2306</p>
              <p> Email: <a href="#" className="text-decoration-none text-dark"> info@johnsonlawgroup.co.uk</a></p>
              <p>Website: <a href="#" className="text-decoration-none text-dark"> www.johnsonlawgroup.co.uk</a> </p>
            </div>
          </div>
        </header>

        <section className="py-5">
          <h4 className="text-decoration-underline"> Form of Authority (1)</h4>
          <table>
            <tbody>
              <tr>
                <td className="w-25">
                  Full Name:
                </td>
                <td>
                  {fName} {lName}
                </td>
              </tr>
              <tr>
                <td>
                  Maiden or former
                  Name (if applicable):
                </td>
                <td>

                </td>
              </tr>
              <tr>
                <td>
                  Address:
                </td>
                <td>
                  {address}
                </td>
              </tr>
            </tbody>
          </table>
          <p>I, {fName} {lName} of the above address confirm as follows:</p>
          <p>In respect of my claim for compensation against {vehicleMake}, I hereby
            authorise it to correspond directly with, disclose documents to and discuss any and all confidential
            information and documentation it holds relating to any dealings with myself, to my instructed
            solicitors: JLG Legal Ltd t/a Johnson Law Group (“JLG”), of 76 King Street, Manchester M2 4NH. I
            further authorise such organisations to make any payments of compensation or refunds due to me
            direct to JLG.</p>
          <p>
            For the avoidance of doubt, this authority includes any formal Data Subject Access Request (“DSAR”)
            for any personal information held about me pursuant to the General Data Protection Regulations
            (GDPR) and the Data Protection Act 2000 (“DPA”) if so indicated by JLG.
          </p>
          <p ref={signref_2}>
            I further authorise JLG to make a formal report to the Information Commissioners Office in respect
            of any non-compliance with any subject access requests made pursuant to the GDPR and DPA.
          </p>

          <div className="d-flex flex-column flex-md-row py-5" >
            <div className="w-50">
              <img src={` ${userSign}`} style={{ width: '50%' }} alt="" />
              <p> Signed: <br />
                {fName} {lName}</p>
            </div>
            <div className="w-50">
              <p style={{ paddingTop: '100px' }}> Dated : {currentDate} </p>
            </div>
          </div>

        </section>

        <div className="page-footer">
          <p className="footer-text-2"> JLG Legal Limited trading as Johnson Law Group<br />
            Registered Office: 76 King Street, Manchester, Greater Manchester, England M2 4NH <br />
            Company Registration Number: 12177815<br />
            SRA Number: 664813.</p>
        </div>
      </section>

      {/* <!-- page-41 --> */}
      <section className="container-1 my-5">
        <header>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div>
              <img className="img-fluid" src={logoImg1} alt="" />
            </div>
            <div className="text-start text-md-end">
              <p>76 King Street, Manchester, M2 4NH</p>
              <p>Tel: 0161 513 2305</p>
              <p>Fax: 0161 513 2306</p>
              <p> Email: <a href="#" className="text-decoration-none text-dark"> info@johnsonlawgroup.co.uk</a></p>
              <p>Website: <a href="#" className="text-decoration-none text-dark"> www.johnsonlawgroup.co.uk</a> </p>
            </div>
          </div>
        </header>

        <section className="py-5">
          <h4 className="text-decoration-underline"> Form of Authority (2)</h4>
          <p>I confirm that I have read and understood the DBA explanation leaflet and that I have read,
            understand and agree irrevocably to be bound to and abide by the following documents that
            collectively combine to form my Retainer with Johnson Law Group (“JLG”):</p>
          <ol type="A">
            <li>Introductory Letter.</li>
            <li>The Damages Based Agreement (‘DBA’)</li>
            <li>Litigation Management Agreement (“LMA”).</li>
            <li>JLG’s Terms of Business.</li>
          </ol>

          <p>I have either taken independent legal advice in relation to the arrangements set out in documents
            referred to above at A - D or am content to proceed without independent legal advice.</p>
          <p>
            I further confirm:.
          </p>
          <ol>
            <li>I authorise Jamie Patton (or another suitable Partner/Principal lawyer) of JLG to sign a
              statement of truth in respect of the claim form and in respect of the Generic Particulars of
              Claim and any statement of truth in respect of any amendments or re- amendments to the claim
              form or Generic Particulars of Claim and in respect of any Reply my legal team may advise is
              appropriate for the Claimants to serve.</li>
            <li>I agree that JLG may check my identity electronically.</li>
            <li>I agree that the Solicitors may request documents from third parties to help with the case, and
              that such documents may include copies of finance agreements relating to my vehicle.</li>
            <li>I understand that if the Proceedings are successful, any settlement or award made in my favour
              will be reduced by the Payment I have agreed to pay to JLG under the DBA.</li>
            <li>I authorise Jamie Patton (or another suitable Partner/Principal lawyer) of JLG to sign a
              Statement of Truth in relation to the information that I have provided and will provide over
              email, through electronic or other means and confirm that such information is true to the best
              of my knowledge and belief.</li>
          </ol>
          <p ref={signref_3}>I understand that I am instructing JLG to be my solicitors for the purpose of the Claim and in so
            doing have entered into a legally binding contract.</p>
          <img className="mt-5" src={` ${userSign}`} style={{ width: '30%' }} alt="" />
          <p> Signed:<br />
            {fName} {lName}</p>

          <p className="mt-5"> Date : {currentDate}
          </p>
        </section>
      </section>

      {/* <!-- page-42 --> */}
      <section className="container-1 my-5">
        <h5 className="text-center text-decoration-underline"> Johnson Law Group Terms of Business</h5>
        <p>Thank you for instructing JLG Legal Ltd trading as Johnson Law Group (“JLG”). We will do our best to ensure
          you are very satisfied with the standard of legal service we provide. This document sets out the general terms
          that apply to all clients regardless of the type of case we are dealing with on their behalf.</p>
        <ol>
          <li>
            <p className="text-decoration-underline"><b>About us</b></p>
            <p>JLG is licenced and regulated by the Solicitors Regulation Authority under Licence Number 664813. Our
              company registration number, which can be found on the Companies House website, is 12177815. We
              hold a policy of professional indemnity insurance that covers us against claims up to £3,000,000. Details of
              our insurer are available upon request.</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Place and hours of business</b></p>
            <p>Our registered office and our principal place of business is 76 King Street Manchester M2 4NH. Our usual
              office hours are 09:00 to 17.30 Monday to Friday but we do reach out to clients and deal with inquiries
              outside of these hours. Appointments outside our usual working hours can be made by arrangement.</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Our Regulator</b></p>
            <p>The Law Society is a designated professional body for the purposes of the Financial Services and Markets
              Act 2000 but responsibility for regulation and complaints handling has been separated from the Law
              Society’s representative functions. The Solicitors Regulation Authority is the independent regulatory arm
              of the Law Society and the Legal Ombudsman is the independent body responsible for handling
              complaints about solicitors. Please refer to Complaints at section 8 below for the procedure in how to do
              so.</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Scope of our services and your authority to instruct</b></p>
            <p>When you indicate that you wish to instruct us on a matter we will ask you to sign a formal “Client Care
              Pack” which will consist of an introductory letter and contractual documentation that we will ask you to
              sign. This documentation will be the basis of our terms of engagement with you but may be subject to
              change on notification by us in writing.</p>
            <p>If you do go on to instruct us we will <b className="text-decoration-underline">NOT</b> be able to undertake any substantive action on your case until
              we have satisfied ourselves of your identity in accordance with our own “ID check rules”. We cannot be
              held responsible for any crucial missed deadlines that may preclude you from bringing a claim which has
              expired during the time between your signing our agreement and sending to us identification
              documentation to verify your identity. We therefore strongly recommend you provide this when returning
              signed documentation to us.</p>
            <p>Unless advised otherwise, we will assume that we are authorised to accept instructions from any person
              whom we reasonably believe to have authority to give instructions to us.</p>
            <p>By entering into an agreement to instruct JLG you give your express consent for us to issue court
              proceedings on your behalf and in your name and take any subsequent action in such court proceedings
              and in the lead up to them, such as issuing interlocutory applications and instructing counsel to attend any
              court hearings as we deem appropriate and necessary for the progression of your case.</p>
            <p>You also expressly authorise us to obtain Legal Expense Insurance on your behalf on the best terms
              possible. It should be stressed however that we are not insurance brokers and under no circumstances do
              we act for you in that role or any other insurance intermediary. We cannot therefore carry out an
              extensive search of the market in order to choose from a number of differing policies. We will simply
              make the decisions to enter into policies that we feel best suits your needs from the products we are
              aware of or from legal expense insurance companies that we are acquainted with or already have a
              business relationship with.</p>
          </li>
          <li> <p className="text-decoration-underline"><b>Billing and payment terms: Responsibility for our costs</b></p></li>
          <p><i>When we render a bill and why</i></p>
        </ol>
      </section>

      {/* <!-- page-43 --> */}
      <section className="container-1 my-5">
        <div className="ps-4">
          <p>We will usually only render a bill at the conclusion of your case and, in the case of a No Win – No Fee
            Arrangement, only if you win. There are two versions of this: a Conditional Fee Agreement and a Damages
            Based Agreement. The decision as to which agreement is offered to our clients is entirely ours based on
            our assessment of your case and the commercialities involved. You will be advised which agreement
            applies to the type of case you instruct us on.</p>
          <p><i>Conditional Fee Agreements</i></p>
          <p>In the event of you winning your case, and you become liable to pay our Basic Charges, our Success Fee
            and/or our Disbursements, we will send you an invoice. This will usually only happen at the end of the
            case once you have received any compensation, but we reserve the right to do so earlier at an appropriate
            point. Examples of the circumstances when this may happen can be found in the Law Society Conditions
            (as amended) document. Also, earlier billing may occur if our agreement with you comes to an end earlier
            (see the Section ‘What happens when this agreement ends before your claim for damages ends?’ in the
            Law Society Conditions (as amended) document).</p>
          <p>We are still entitled to receive a percentage of your damages (the “ <b>Success Fee</b> ”) but this is based on the
            amount of work that we have done (limited to a maximum percentage of compensation you recover and
            the Overall Cap applicable to your case). We will also be able to recover the majority of our basic charges
            from your opponent. It is for that reason we keep a record of the time we spend on your file and why we
            have explained to you in our contractual documentation our hourly charge out rate.</p>
          <p>You are responsible for any unrecovered basic charges but that will always be subject to an “Overall Cap”
            which we cannot charge above, which protects your compensation.</p>
          <p><i>Charging you costs greater than those you may recover from an opponent under a CFA (s.74 Solicitors Act
            1974)</i></p>
          <p>If your case is conducted in the County Court, then Section 74(3) of the Solicitors Act 1974 does not allow
            us to charge you an amount for any item of costs which exceeds the amount that the Court could have
            allowed for that item if your opponent was ordered to pay it, unless you and this firm agree specifically
            that such costs can be charged.</p>
          <p>By instructing us on the terms of this CFA and the accompanying Terms and Conditions of Business, you
            agree and acknowledge that certain items of costs, such as the Success Fee, any unrecovered Basic
            Charges and any unrecovered Disbursements remain payable by you even though they may not have been
            recovered/be capable of recovery, from your opponent and, by agreeing the terms of the CFA and the
            Terms and Conditions of Business, you confirm your express agreement to the hourly rates, Basic Charges
            and the fixed Success Fee as set out in this agreement.</p>
          <p><i>Damages Based Agreements</i></p>
          <p>Just like a CFA, we will not usually render a bill until the conclusion of your case and only in the event of a
            win. Unlike a CFA, the proportion of damages that you pay to us for agreeing to act for you (the
            “Payment”) is calculated by reference to a percentage of the damages you recover. We will still however
            be required to record our Basic Charges – the amount of time we spend on your file - as you will be
            entitled to claim those costs from your opponent in the event you win your case.</p>
          <p>Like a CFA, you are primarily responsible for any unrecovered basic charges, expenses and disbursements.
            However any unrecovered Counsels fees will form part of the agreement Payment you are obliged to pay
            on the successful conclusion of your case.</p>
          <p><i>Format of invoice</i></p>
          <p>Any such invoice will set out the total amount of our Basic Charges, Disbursements and Success Fee for
            either the entirety of your claim or for any relevant period it covers, together with any Overall Cap or
            limitation we have agreed with you on those sums. We will give credit for any sums received from your
            opponent or elsewhere. However, you will be liable for the net sum due shown on the invoice.</p>
          <p>Unless we expressly state to the contrary at the time, any invoice we render to you will be a full and final
            invoice for the period it covers.</p>
        </div>
      </section>

      {/* <!-- page-44 --> */}
      <section className="container-1 my-5">
        <div className="ps-4">
          <p>By agreeing to the terms of our Retainer and the Terms and Conditions of Business you agree that we may
            delivery invoices to you by email.</p>
          <p>You may have certain rights to seek an independent review by a Court of any invoice, details of which are
            set out below. Unless we expressly state to the contrary at the time, your time for seeking such a review
            will start on the date of delivery of any invoice to you.</p>
          <p>Our Basic Charges are calculated on hourly basis and broken down into ten, 6-minute units per hour.
            Routine letters and phone calls are charged as one 6-minute unit (one tenth of an hour). Longer letters,
            telephone calls and time spent working on your file (attendances drafting or reviewing documents or
            engagement in longer meetings) will be charged on a timed basis rounded up to the nearest unit.</p>
          <p>The hourly charge out rate we charge is dependant upon the seniority of the fee earner dealing with your
            case and the value of it.</p>
          <p>Details that sets out the rate of Basic Charges applicable to your case can be found in the applicable
            agreement you enter into with JLG.</p>
          <p>We review our hourly rates on 1 st January each year and will notify you of any change to them in writing.</p>
          <p>It is important you understand that you will be responsible for paying our bills. Even if the claim is
            successful, the other party may not be ordered to pay all our charges and expenses, or these may not be
            recovered from them in full after any detailed assessment by the court. If this happens, you are
            responsible for unrecovered basic charges (subject to that liability being limited by the Overall Cap in our
            CFA or the limit placed on the “Payment” in our DBA – to ensure a proportion of your damages is “ring
            fenced” and protected against any of our costs levied against it) but we may ask you to pay for any
            unrecovered disbursements and expenses.</p>
          <p>Any interest awarded or agreed in respect of the compensation you receive will form part of the total
            damages recovered – which you get to keep. Any interest payable in respect of awarded costs is regarded
            as part of our overall recoverable costs charged – which we get to keep.</p>
          <p>You will also be responsible for paying the charges and expenses of seeking to recover money that the
            court orders your opponent to pay if enforcement proceedings are necessary.</p>
          <p>Any disbursements unrecovered from your opponent if you win will not be covered by your after the
            event insurance policy and may need to be funded out of your recovered compensation. If you lose, all
            disbursements will be covered by your after the event insurance policy. This is subject to you complying
            with the terms of the policy.</p>
          <p><i>Your rights to have your costs assessed by a Court</i></p>
          <p>If you are liable to pay our Basic Charges, Disbursements and/or our Success Fee, we will deliver an invoice
            to you and you may be entitled to have the amount of that invoice reviewed and independently assessed
            by a Court.</p>
          <p>The rules relating to such an assessment are set out in Part III of the Solicitors Act 1974 and we would be
            happy to answer any reasonable enquiries you may have in that regard. Please note, there are quite tight
            time limits from the date of delivery of any invoice relating to any such assessment and if they are not
            complied with any right to such an assessment may be lost.</p>
          <p>You may have a separate right to complain to the Legal Ombudsman and you should note that the Legal
            Ombudsman may not consider a complaint about a bill if you have applied to the Court for assessment of
            the bill.</p>
          <p>If you are liable to pay any insurance premium, you would not be able to have any insurance premium
            assessed since your liability for the premium would have arisen directly from the insurance contract
            between you and the insurer. You may have separate rights under that contract or through the Financial
            Ombudsman Service in relation to the premium.</p>
          <p><i>VAT</i></p>
        </div>
      </section>

      {/* <!-- page-45 --> */}
      <section className="container-1 my-5">
        <div className="ps-4">
          <p>Unless otherwise stated, Value Added Tax (VAT) will be added to the amounts we charge in accordance
            with HM Revenue and Customs requirements. We may have to add VAT to some expenses if VAT is not
            added by the supplier. We will deliver an appropriate VAT invoice to you.   </p>
          <p><i>Expert fees</i></p>
          <p>We may instruct companies to prepare independent expert reports relating to some of the more technical
            aspects of your claim. These reports will incur a fee which we will attempt to recover, from your opponent
            but continue to remain your responsibility.</p>
          <p>We will advise you further in relation to these fees should such reports be required. We will not incur any
            fee unless we are sure the cost is covered by a policy of litigation insurance or backed by a Trade Union.</p>
        </div>
        <ol>
          <li value="6">
            <p className="text-decoration-underline"><b>Litigation Insurance</b></p>
            <p>Although we may set up “After the Event” insurance to protect you against having to pay your opponent’s
              costs, we must advise you that we are not insurance brokers. The legal expenses insurance market is
              complex and changes frequently. Accordingly, we do not offer professional advice on all policies available
              in the market.</p>
            <p>We have advised that you take out an After the Event insurance policy unless you already have an existing
              legal expense insurance policy.</p>
            <p>Most importantly, should you instruct us to proceed with a policy it will constitute your irrevocable
              agreement to allow us to receive your damages and to deduct the appropriate premium from the
              damages and to send it to the insurer in settlement of the liability to pay the premium.</p>
            <p>The premium for the policy is only payable upon the conclusion of the case. If you win your claim, then the
              policy cost is taken from your compensation. If you lose your claim, then the cost of the policy is covered.
              Unlike most other disbursements and expenses, the policy premium is not a cost that is recoverable from
              your opponent.</p>
            <p>You are of course entitled to explore the availability of ATE insurance arrangements.</p>
            <p>Where you already have legal expense insurance or trade union legal protection, the insurer or union may
              insist on their customer using their appointed solicitors or that no deductions are taken from a client’s
              damages (but without adequately paying the solicitor for the work they do).</p>
            <p>Where you have such cover and we can enter into an arrangement with either your Legal Expense Insurer
              or Union so that they cover any risk of adverse costs to you but allow us to continue with our No-Win No-
              Fee arrangement, we will engage them. If, however, they refuse to allow us to operate in this way then
              you will have to choose between using us in line with our contractual arrangements or going elsewhere.</p>
            <p>We are not authorised by the Financial Conduct Authority however we are included on the register
              maintained by the FCA so that we can carry on insurance distribution activity, which is broadly, the
              advising on, selling and administration of insurance contracts. This part of our business, including
              arrangements for complaints or redress if something goes wrong, is regulated by the Solicitors Regulation
              Authority. The register can be accessed via the Financial Conduct Authority website at
              <a href="#">  www.fca.org.uk/register</a> .</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Advertising &amp; Recommendations</b></p>
            <p>If our details have been passed to you by a third party, we may have paid a marketing fee, suppliers fee,
              recommendation fee or signed up to a panel membership, organised road shows or responded to
              advertising.</p>
            <p>All Introducers who pass our details to you have signed commercial agreements with us.</p>
            <p>The arrangement between us and any introducer is strictly a business relationship. You are entitled to see
              any details of any payments made in respect of marketing or referral costs relating to your introduction to
              our firm.</p>
          </li>
        </ol>
      </section>

      {/* <!-- page-46 --> */}
      <section className="container-1 my-5">
        <div className="ps-4">
          <p>The arrangement does NOT constrain our ability to act impartially nor fetter our independence as a legal
            adviser and to provide you with the best advice possible. The referral also has no financial impact on you
            personally or the cost of your action.</p>
        </div>
        <ol>
          <li value="8">
            <p className="text-decoration-underline"><b>Complaints </b></p>
            <p>Please note that this firm has a written complaints procedure that is available on our website and upon
              request. We strive to ensure that all complaints are handled promptly, fairly and effectively in accordance
              with our complaints procedure.</p>
            <p>Should you have a problem or issue that cannot be resolved informally you are of course entitled to
              complain. Any complaints should be addressed to Jamie Patton, the complaints handling Manager and
              Senior Solicitor. Once your complaint has been made, we will write to confirm how your complaint will be
              handled and the likely timescale you can expect us to revert to you with our findings. We will not charge
              you for investigating your complaint.</p>
            <p>If your complaint has not been resolved to your satisfaction within 8 weeks of making the complaint, you
              may be able to complain to the Legal Ombudsman. Full details of the kinds of complaints which the
              Ombudsman will accept can be found on their website at <a href="#"> http://www.legalombudsman.org.uk/ </a> and their
              address and contact details are: PO Box 6806 Wolverhampton WV1 9WJ
              (email: <a href="#">enquiries@legalombudsman.org.uk </a>; Tel: 0300 555 0333).</p>
            <p>You may also have the right to object to your bill by applying to the Court for an assessment of the bill
              under Part III of the Solicitors&#39; Act 1974. Please be aware that the Legal Ombudsman may not consider a
              complaint about a bill if you have applied to court for an assessment of it.</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Proof of identity</b></p>
            <p>The law now requires solicitors, as well as banks, building societies and other similar organisations, to
              obtain satisfactory evidence of the identity of their clients. This is because solicitors who deal with money
              and property on behalf of their clients can be used by criminals wishing to launder money. To comply with
              the law on money laundering, we need to obtain evidence of your identity as soon as practicable.</p>
            <p>We therefore carry out electronic identity checks on you via an on-line compliance service called
              SmartSearch. Please note that the search conducted against you will leave a small ID footprint on your
              record but it will not affect your credit history. Your agreement to instruct us provides the necessary
              consent to perform these searches and checks. We have no liability for any consequential loss arising as a
              result of our compliance with statutory or regulatory obligations and our fees may include a charge for
              complying with those obligations.</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Proof of identity</b></p>
            <p>Solicitors are under a professional and legal obligation to keep the affairs of clients confidential. We will
              keep confidential any information received from you whilst acting for you in connection with a matter
              unless:</p>
            <ol type="a">
              <li className="pt-2">We have your authority to disclose it; or</li>
              <li className="pt-2">Disclosure is required in order to deal with and progress your matter;</li>
              <li className="pt-2">For the purposes of external auditing that we are required to do (as outlined below) or;</li>
              <li className="pt-2">We are required to disclose it by law: for example in order to comply with a Court Order or
                pursuant to the obligations legislation on money laundering and terrorist financing has placed
                solicitors under to disclose information to the National Crime Agency. For example: where a
                solicitor knows or suspects that a transaction on behalf of a client involves money laundering,
                the solicitor may be required to make a money laundering disclosure.</li>
            </ol>
            <p className="pt-4">If, while we are acting for you, it becomes necessary to make a money laundering disclosure, we may not
              be able to inform you that disclosure has been made or of the reasons for it because the law prohibits
              “tipping off”. Where the law permits us to do, we will tell you about any potential money laundering
              problem and explain what action we may need to take.</p>
          </li>
        </ol>
      </section>

      {/* <!-- page-47 --> */}
      <section className="container-1 my-5">
        <div className="ps-4">
          <p>In the event of litigation, you should be aware that many documents which you file in court will be open
            to public inspection. If this causes you any specific concern, please let me know as soon as possible.</p>
        </div>
        <ol>
          <li value="11">
            <p className="text-decoration-underline"><b>Third Party Auditing / Consent </b></p>
            <p>Please note from time to time it may be necessary for your file to be audited by a third party. They may
              require access to your papers on a read-only basis so that they can assess the progress being made and
              the eventual conclusion. Other parties would also include, but not exclusively, our insurers or their
              representatives, our Regulators including the Solicitors Regulation Authority, , ATE providers or funders
              who have provided Litigation Disbursement Funding to assist the practice in running your claim.</p>
            <p>By signing this letter, you are providing your ongoing consent to making your file available for these audits
              and for your file to be transparent on a read-only basis to the relevant third party.</p>
            <p>If you do not consent to any of the above, please contact us immediately.</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Data Protection Act Notice</b></p>
            <p>We place great emphasis on maintaining the highest standards of confidentiality. Our partners and staff
              are under an obligation not to disclose any confidential information to third parties, unless the Proceeds
              of Crime Act 2002 applies, or we are required to do so by Court Order.</p>
            <p>We are committed to protecting client privacy. In dealing with clients and prospective clients we require
              personal data to assist in the provision of legal services. Your personal data will either be transferred to us
              from you directly, or from one of our agents or referrers (if your claim was referred to us by them). The
              storage and disclosure of that personal information is always in accordance with the Data Protection Act
              2018 and EU General Data Protection Regulation 2016/679. Further details on how we deal with your
              personal information is contained in our Privacy Policy which is available on our website and on request.
              You are advised to read this Policy carefully before instructing us as it sets out (among other things) details
              of any personal data transfers outside of the EEA. If you have any queries or concerns about our approach
              to data protection or the processing of your personal data in connection with your claim, please contact
              us.</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>Papers documents and electronic communication</b></p>
            <p><i>Paperless files</i></p>
            <p>We may operate a “paperless” file for your matter.  If so, we will not normally keep paper copies of any
              letters or emails, or of any documents that we generate; we will encourage the use of paperless
              communication where possible; we will store copies of correspondence and documents electronically; and
              incoming mail will generally be converted into digital format.</p>
            <p>You authorise us to destroy all paper letters and documents provided we have digital copies.</p>
            <p>If you require us to return to you any letter or document that you provide to us, you must inform us in
              writing at the same time as you provide it.  General requests that are inconsistent with our paperless
              working may result in us terminating the retainer or agreeing to an additional charge, but we will first
              discuss the situation with you.</p>
            <p>We will retain digital copies in accordance with our Privacy Notice.</p>
            <p><i>Paper records</i></p>
            <p>If we retain any paper records of the work we have done for you, we will keep them for at least one year
              from the date of our final bill.</p>
            <p><i>Providing copies</i></p>
            <p>During the first year after the date of our final bill we will provide to you, on your written request, any
              documents that belong to you (or copies of any documents that are jointly owned).  If we have converted
              them into digital format, we will provide copies.  We will not provide to you copies of documents that we
              have already provided to you or that belong to us.</p>
            <p>You agree that, after that first year, all documents (or copies) will belong to us and we may choose how to
              respond to any request for copies.</p>
          </li>
        </ol>
      </section>

      {/* <!-- page-48 --> */}
      <section className="container-1 my-5">
        <div className="ps-4">
          <p>We will not supply copies or notes of communications between us, so you must keep your own records of
            anything important.</p>
          <p>We may choose to supply copy documents in electronic format.</p>
          <p>If we are willing and able to provide any documents or copies to you, you agree to pay in advance an
            administration fee of no more than £60 if we request it.  No administration fee will be payable during the
            first five years if you have previously paid an archive charge.</p>
          <p>You will be responsible for any collection or delivery charges.  We may require you to make arrangements
            for collection.</p>
        </div>
        <ol>
          <li value="14">
            <p className="text-decoration-underline"><b>Interest</b></p>
            <p>If we hold money in our Client Account on your behalf, then we will account to you for a sum in lieu of
              interest calculated as below. We will not account to you for any interest in the following situations:</p>
            <ol type="a">
              <li className="pt-2">if the amount calculated is £20 or less.</li>
              <li className="pt-2">on money held for the payment of a professional disbursement if the person to whom the
                money is owed has requested a delay in settlement.</li>
              <li className="pt-2">on an advance from us into our general client account to fund a payment on your behalf in
                excess of funds already held for you in that account.</li>
              <li className="pt-2">if there is an agreement to contract out of the provisions of this policy.</li>
            </ol>
            <p>If we hold sums of money intermittently on your behalf, in our Client Account, during the course of acting,
              and the sum in lieu of interest calculated for any single period is £20 or less, we will account to you if the
              total interest exceeds £20.</p>
            <p>We will calculate and pay interest once your matter has been concluded.</p>
            <p>In calculating interest, we will apply a rate that we believe reflects the market rate of interest paid on an
              instant access current account offered by JLG’s bank over the period when interest is due.</p>
            <p>In determining the period over which interest is to be calculated, we will look at the following: the period
              between the date when the relevant funds received by us clear our account and, if we send the funds
              electronically, the date when the funds are sent or, if we send the funds by cheque, five days after a
              cheque is raised.</p>
          </li>
          <li className="pt-4">
            <p className="text-decoration-underline"><b>In the Event of a Banking Failure</b></p>
            <p>In the event of a banking failure JLG will not be liable for any losses of client account money.</p>
            <p>We currently hold our client account funds in Santander Bank. The £85,000 Financial Services
              Compensation Scheme (FSCS) limit will apply to each individual so if you hold other personal monies
              yourself in the same bank as our client account, the limit remains £85,000 in total, so it may be advisable
              to check with your own bank as some banks now trade under different trading names.</p>
            <p>However, the FSCS provides a £1 million protection limit for temporary high balances held with a bank,
              building society or credit union if it fails. Further details relating to what constitutes a temporary high
              balance and the rules relating to the protection can be found at <a href="#">www.fscs.org.uk</a> . In the event of a bank
              failure you agree to us disclosing details to the FSCS</p>
          </li>
          <li>
            <p className="text-decoration-underline"><b>The Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013</b></p>
            <p>If you are an individual and you are instructing us for purposes which are wholly, or mainly, outside your
              trade, business, craft or profession, you will be considered a ‘consumer’ by law and will have certain
              statutory rights under consumer legislation. Under the Consumer Contracts (Information, Cancellation and
              Additional Charges) Regulations 2013, you have a statutory right to cancel your instructions to us within
              14 days (without giving a reason) if your instructions to us are as a result of a situation where we do not
              actually meet (i.e. through email and/or telephone contact) or an off-premises contract (i.e. at a meeting
              between us not held at our offices).</p>
          </li>
        </ol>
      </section>

      {/* <!-- page-49 --> */}
      <section className="container-1 my-5">
        <div className="ps-4">
          <p>The cancellation period will expire after 14 after signing it. To exercise your right to cancel, you must
            inform us of your decision to cancel this contract by a clear statement (e.g.: a letter sent by post or email)
            using the contact details in our Engagement Letter before the cancellation period has expired. You may
            use the model cancellation form contained in our Client Care Pack, but it is not obligatory. You can also
            electronically fill in and submit the model cancellation form by email to info@johnsonlawgroup.co.uk. If
            you use this option, we will communicate to you an acknowledgement of receipt of such a cancellation on
            a durable medium (e.g. by email) without delay.</p>
          <p>Where you have asked us to commence work within the 14-calendar day cancellation period and you later
            exercise your right to cancel after the 14 days has expired, you will be liable for any costs, VAT and
            disbursements incurred up to the point of cancellation. In such circumstances the costs you become
            responsible for will be charged in accordance with our Basic Charges as set out in Section 5 of these Terms
            of Business (above). If you exercise your right to cancel, subject to any costs you are liable for as set out
            above, we will reimburse any payment received on account from you without undue delay and within 14
            days after the day in which you informed us of your decision to cancel.</p>
        </div>
        <ol>
          <li value="17">
            <p className="text-decoration-underline"><b>Equality and Diversity</b></p>
            <p>We are committed to promoting equality and diversity in all of our dealings with clients, third parties and
              employees. We have a written equality, diversity and inclusion policy to ensure that discrimination and
              harassment are prevented and that equality, diversity and inclusion are promoted.</p>
            <p>We will not discriminate in the way we provide our Services to you or in the way we instruct third parties.</p>
          </li>
          <li className="pt-4">
            <p className="text-decoration-underline"><b>Force Majeure</b></p>
            <p>We will not be liable to you for any delay or failure to fulfil our obligations caused by circumstances
              outside of our reasonable control.</p>
          </li>
          <li className="pt-4">
            <p className="text-decoration-underline"><b>Severance</b></p>
            <p>If for any reason any part of our agreement with you is invalid or unenforceable for any reason that shall
              not affect the remainder of it.</p>
          </li>
          <li className="pt-4">
            <p className="text-decoration-underline"><b>Governing law and disputes</b></p>
            <p>The contract between you and JLG is deemed to be made in England and governed by the law of England
              and Wales.</p>
          </li>
          <li className="pt-4">
            <p className="text-decoration-underline"><b>Referral Arrangements with 3 rd Party Introducers</b></p>
            <p>From time to time we will enter into arrangements with Claims Management Companies or 3 rd Party
              Introducers that put us in touch with clients who wish to instruct us.</p>
            <p>When this happens, we pay them a fee for that service. We will make you aware of the fee we pay. You
              are not responsible for paying that referral fee and the fees we will charge you in respect of your matter
              will not be affected by this financial arrangement.</p>
            <p>Nothing in our arrangement with any 3 rd party introducers will affect or influence our obligation to you to
              act in your best interests and upon your wishes or to provide you with sound legal advice completely
              independent of any organisation we have dealings with. You are free to raise any questions about any
              aspect of this transaction. The information disclosed by you to us will not be disclosed to 3 rd Party
              Introducers unless you consent.  However, in signing the agreement contained within our client care pack
              (of which these terms and conditions form part) you give us your written consent to disclose to the
              relevant 3 rd party introducer certain details about the progress of your case that would enable the
              structure of the arrangement we have with them to work, for example, to advise them that we have taken
              you on as a client, when your case is likely to conclude and does conclude and the amount of success fee
              we have charged you at the conclusion of your case. You may however withdraw that consent if you so
              wish at any time.</p>
          </li>
        </ol>
      </section>

      <footer class="d-flex justify-content-center mt-5 bg-dark p-2">
        <button onClick={() => { console.log(787); showSign() }} class="btn btn-success px-3">Next</button>
      </footer>
    </div>
  )
}
export default PreviewLoa;