import React, { useState } from "react";
import { Switch } from "react-router-dom";
import AnchorAdv from "../../../UI/AnchorAdv";
import Modal from "react-bootstrap/Modal";
import logoimg from '../../../../assets/img/logo.png';
import PrivacyPolicy from '../../Layouts/PrivacyPolicy';
import Transparency from '../Transparency';

const AdvFooter = () => {
  const [showprivacy, setshowprivacy] = useState(false);
  const [showptransparency, setshowtransparency] = useState(false);

  
  const handleprivacyShow = () => {
    console.log("privacy");
    setshowprivacy(true);
  };
  const handleprivacyClose = () => {
    setshowprivacy(false);
  };
  const handletransparencyShow = () => {
    console.log("cookie");
    setshowtransparency(true);
  };
  const handletransparencyHide = () => {
    setshowtransparency(false);
  }; 
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 pb-2">
              <p>
                DISCLAIMER: The material on this website is intended only to
                provide a summary and general overview on matters of interest.
              </p>
            </div>
            <div className="col-12 end_footer">
              <div className="row m-0">
                <div className="col-lg-3 col-md-3 col-sm-4 col-12 text-center">
                  <AnchorAdv dataId="FOOTER_lOGO">
                    <img src={logoimg} alt="" />
                  </AnchorAdv>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-8 col-12">
                  <ul>
                     <li>
                       <a
                        className="rp_clk"
                        onClick={handleprivacyShow}
                        data-bs-target="#prvcy">
                        Privacy Policy   
                        </a> 
                      </li>
                     <li>
                       <a
                        className="rp_clk"
                        onClick={handletransparencyShow}
                        data-bs-target="#cookies">
                        Cookies
                      </a>
                    </li>
                    <li>
                      <a
                        href={"/Contact"}
                        className="rp_clk"
                        target="_blank"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <PrivacyPolicy showstatus={showprivacy} hidepopup={handleprivacyClose} />
      <Transparency showstatus={showptransparency} hidepopup={handletransparencyHide} />
    </>
  );
};
export default AdvFooter;
