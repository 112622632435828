import React ,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Anchor from '../../../UI/Anchor';
import foot_logo from '../../../../assets/img/foot_logo.jpg';
import logoimg from '../../../../assets/img/logo.png';
import PrivacyPolicy from '../../Layouts/PrivacyPolicy';
import Terms from '../../Layouts/Terms';
import Transparency from '../Transparency';


const Footer = () => {
    const [showprivacy, setshowprivacy] = useState(false);
    const [showptransparency, setshowtransparency] = useState(false);
    const [showterms, setshowterms] = useState(false);
  
    const handleprivacyShow = () => {
      console.log("privacy");
      setshowprivacy(true);
    };
    const handleprivacyClose = () => {
      setshowprivacy(false);
    };
    const handletransparencyShow = () => {
      console.log("cookie");
      setshowtransparency(true);
    };
    const handletransparencyHide = () => {
      setshowtransparency(false);
    }; 
    const handleTermsShow = () => {
      console.log("terms");
      setshowterms(true);
    };
    const handleTermsHide = () => {
      setshowterms(false);
    };
  return (
    <>
 
      <footer>
      <div class="container">
            <div class="row">
               <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-left">
                  <ul>
                     {/* <li>
                       <a
                      className="rp_clk"
                      onClick={handleTermsShow}
                      data-bs-target="#terms"
                    >Terms & Conditions  </a> </li>
                     <li><a
                      className="rp_clk"
                      onClick={handleprivacyShow}
                      data-bs-target="#prvcy"
                    >Privacy Policy   </a> </li>
                     <li><a
                      className="rp_clk"
                      onClick={handletransparencyShow}
                      data-bs-target="#cookies"
                    >Transparency Statement</a></li>*/}
                  </ul> 
                  <p style={{paddingTop : "67px"}}>©johnsonlawgroup.co.uk 2022 </p>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                  <img src={logoimg}/>
               </div>
               <div class="col-lg-2 col-md-2 col-sm-12 col-6">
                  <p>
                     <strong>Johnson Law Group</strong><br/>
                              76 King Street, Manchester<br/>
                              England, M2 4NH<br/>
                              Tel: 0161 5132305<br/><br/>
                              <a href="mailto:info@johnsonlawgroup.co.uk" target="_blank">info@johnsonlawgroup.co.uk</a>

                  </p>
               </div>
               {/* <div class="col-lg-2 col-md-2 col-sm-12 col-6">
                  <img src={foot_logo} alt="" class="foot_logo" />
               </div> */}
            </div>
         </div>
         <PrivacyPolicy showstatus={showprivacy} hidepopup={handleprivacyClose} />
      <Transparency showstatus={showptransparency} hidepopup={handletransparencyHide} />
      <Terms showstatus={showterms} hidepopup={handleTermsHide} />
        </footer>
      
    </>
  );
}

export default Footer;