import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Transparency = ({ showstatus, hidepopup }) => {
  return (
    <>
      <Modal show={showstatus} onHide={hidepopup} size="xl" ClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title" dialogClassName="privacykl">
        <Modal.Header>
          <Modal.Title>Transparency Statement</Modal.Title>
          <button  className="close" onClick={hidepopup}>×</button>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body">
           <p>Transparency Statement</p>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hidepopup}>
            Close
                    </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Transparency;