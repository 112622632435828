import React,{useEffect} from 'react';
import { CheckUUID } from "../../Utility/CheckUUID";

const Unqualified = () => {
  const { isCheck,} = CheckUUID();
  isCheck();
  return (
    <>
      <div className="unqualified TMS_questi">
         <section className="gradient_sec py-4">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-12 text-center logo-part">
                     <img src="/assets/img/logo.png" alt="" className="opacity-25"/>
                  </div>
                  <div className="col-lg-12 col-12 text-center gif1">
                     <img src="/assets/img/woman.png" alt=""/>
                     <h1>
                        <strong>
                            Sorry,
                        </strong>
                     </h1>
                     <h6>We are unable to assist you</h6>
                  </div>
               </div>
            </div>
         </section>
      </div>
    </>
  )
}
export default Unqualified;
