import React from 'react';

const RadioButtonFlp = ({
    id,
    value,
    name,
    className,
    labelName,
    labelClassName,
    spanName,
    onClick
  }) => {
    return (
        <>
        <input type="radio" className={className} name={name} id={id} value={value} onClick={onClick} />
        <label htmlFor={id} className={labelClassName}>
        <span className="">{spanName}</span>{labelName} 
        </label>
        </>
      );
}

export default RadioButtonFlp;