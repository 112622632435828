import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import RadioButtonFlp from "../../../UI/RadioButtonFlp";
import img01 from "../../../../assets/img/li-bulb.png";
import img02 from "../../../../assets/img/checked-followup.png";
import img03 from "../../../../assets/img/th.jpg";
import InputButton from "../../../UI/InputButton";
import { useFollowupUserIdUpload } from "../../../../Hooks/useFollowupUserIdUpload";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";


const IdUploadModal = ({
  popDisplay,
  allFollowupData,
  setPendingStatus,
}) => {
  const history = useHistory();
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [showSlide1, setShowSlide1] = useState(true);
  const [showSlide2, setShowSlide2] = useState(false);
  const [showSlide3, setShowSlide3] = useState(false);
  const [uploadFileType, setUploadFileType] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [buttonShow, setButtonShow] = useState(true);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [image, setImage] = useState(img03);
  const [image2, setImage2] = useState(img03);
  const [image3, setImage3] = useState(img03);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const { saveFollowupUserIdUpload, isLoading } = useFollowupUserIdUpload();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const userfile = {
    preview: undefined,
    title: undefined,
  };
  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setButtonShow(false);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;

      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setIsInValidfFile(false);
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let Imagename = event.target.name;
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (event) => {
          setBase64Image({
            base64TextString: event.target.result,
          });
          setImage(event.target.result);
          setShowSlide3(true);
          setShowSlide2(false);
          setShowSlide1(false);
        };
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };
  useEffect(() => {
    setShow(popDisplay);
  }, [popDisplay]);
  const fileUpload = (e) => {
    setShowSlide1(false);
    setShowSlide2(true);
    setSelectedFileType(e.target.name);
    if(e.target.value == 'National_id'){
      setUploadFileType('National ID Card');
    }
    if(e.target.value == 'Driving_license'){
      setUploadFileType('UK Driving Licence');
    }
    if(e.target.value == 'Passport'){
      setUploadFileType('UK Passport');
    }
  };
  const retakePhoto = ()=>{
    setShowSlide2(true);
    setShowSlide3(false);
    setShowSlide1(false);
  }
  const SubmitDocUpload = async(e) =>{
    e.preventDefault();
    let uploadData = "";
    if (isFilePicked && isInValidfFile == false) {
      uploadData = {
        imageFile: base64Image.base64TextString,
        captureType: selectedFileType,
        documentType: selectedFileType,
      };
      const response = await saveFollowupUserIdUpload(
        uploadData,
        allFollowupData.flpData.followup_data,
        'followup_user_docs_store'
      )
      if (response.data.status === "Success") {
        setPendingStatus({ status_btn: 'id-upload', status_flag: 'showTick' });
        setShow(false);
      } else {
        setPendingStatus({ status_btn: 'id-upload', status_flag: 'showButton' });
      }
    } else {
      setIsInValidfFile(true);
      setErrorMessage({ txt: " Please upload a file" });
    }
  }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="Followup"
      backdrop="static"
      keyboard={false}
    >
      <div className="modal-body">
        <div className="" id="idupload" style={{ paddingRight: "0px" }}>
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-body mob-pad5">
                <div
                  className="radio-box question3 animated fadeInUp"
                  id="slidedown1"
                  style={
                    showSlide1 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h3 style={{ color: "#444" }}>
                    You must provide 1 proof(s) of address to proceed.
                  </h3>
                  <p className="text-center">
                    <i>
                      You must provide 1 proof(s) of address to proceed. Tip -
                      Please provide a photo of one of the following:
                    </i>
                  </p>

                  <RadioButtonFlp
                    name="National_id"
                    id="id1"
                    value="National_id"
                    className="radio"
                    labelName="National ID Card"
                    labelClassName="br radioa l_g"
                    spanName="A"
                    onClick={fileUpload}
                  />

                  <RadioButtonFlp
                    name="Passport"
                    id="id2"
                    value="Passport"
                    className="radio"
                    labelName="UK Passport"
                    labelClassName="br radioa l_g"
                    spanName="B"
                    onClick={fileUpload}
                  />

                  <RadioButtonFlp
                    name="Driving_license"
                    id="id3"
                    value="Driving_license"
                    className="radio"
                    labelName="UK Driving Licence"
                    labelClassName="br radioa l_g"
                    spanName="C"
                    onClick={fileUpload}
                  />
                </div>

                <div
                  className="radio-box question4 animated fadeInUp"
                  id="slidedown2"
                  style={
                    showSlide2 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h3 style={{ color: "#444" }}>
                    Upload Photo Identification:{uploadFileType}
                  </h3>
                  <input
                    type="file"
                    className="radio"
                    id="idTypeFile"
                    name="IDCard_doc"
                    placeholder="Upload picture of Your Id"
                    accept="image/*"
                    onChange={changeFilesHandler}
                    onClick={(e) => (e.target.value = null)}
                  />
                  <label
                    htmlFor="idTypeFile"
                    className="bl radioa l_g"
                    data-dismiss="modal"
                  >
                    <span className="">
                      <i className="fa-solid fa-folder"></i>
                    </span>
                    Take Photo via Device
                  </label>
                  <ul className="info-list">
                    <li>
                      <img src={img01} alt="" />
                      Set the document down on a plain, dark background
                    </li>
                    <li>
                      <img src={img01} alt="" />
                      Ensure to capture all (4) corners of the document
                    </li>
                    <li>
                      <img src={img01} alt="" />
                      Use good lighting and avoid heavy shadows
                    </li>
                  </ul>
                  <input
                    type="file"
                    className="radio"
                    id="idTypeFile"
                    name="IDCard_doc"
                    placeholder="Upload picture of Your Id"
                    accept="image/*"
                    onChange={changeFilesHandler}
                    onClick={(e) => (e.target.value = null)}
                  />

                  <label
                    htmlFor="idTypeFile"
                    className="bl radioa l_g"
                    data-dismiss="modal"
                  >
                    <span className="">
                      <i className="fa-solid fa-folder"></i>
                    </span>
                    Select File from Your Device
                  </label>
                </div>

                <div
                  className="radio-box question4 animated fadeInUp"
                  id="slidedown3"
                  style={
                    showSlide3 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h3 style={{ color: "#444" }}>
                    Upload Photo Identification:{uploadFileType}
                  </h3>
                  <p className="imag">
                    <img src={image} alt="" />
                  </p>
                  <p className="m-0 check">
                    <span>
                      <img src={img02} alt="" />
                    </span>
                    Document is Clear &amp; Legible
                  </p>
                  <p className="check">
                    <span>
                      <img src={img02} alt="" />
                    </span>
                    Entire Document is Caprured
                  </p>
                  <br />
                  {isInValidfFile && (
                    <span className="error_msg" id="email_err">
                      {errorMessage.txt}
                    </span>
                  )}
                  <p className="text-center">
                    <InputButton
                      name="formSubmitBtn"
                      className="idsuccess"
                      id="upload"
                      value="Submit"
                      btnType="button"
                      onClick={(e)=>{SubmitDocUpload(e)}}
                    />
                    <br />
                    <InputButton
                      name="formRetake"
                      className="idretake"
                      id="retake"
                      value="Retake Photo"
                      btnType="button"
                      onClick={retakePhoto}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IdUploadModal;
