import React from "react";
import img01 from "../../../../assets/img/review.gif";

const Loader = ({ showLoad }) => {
  return (
    <>
      <div id="myDiv" className={`space text-center loader-div ${showLoad}`}>
        <img src={img01} alt="" className="loader-gif"/>
        <h3>Analyzing your application....</h3>
      </div>
    </>
  );
};

export default Loader;
