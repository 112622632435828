import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Followup from "./Components/Pages/Followup/Followup";
import Preview from "./Components/Pages/Preview";
import Proceed from "./Components/Pages/Proceed";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
import Thankyou from "./Components/Pages/Thankyou";
import IdUpload from "./Components/Pages/IdUpload"
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import DE_JLG_1 from "./Components/Pages/Splits/DE_JLG_1";
import DE_JLG_2 from "./Components/Pages/Splits/DE_JLG_2";
import ErCheck from "./Components/Pages/ErCheck";
import TCG_YH_DE_V1 from "./Components/Pages/Advertorials/TCG_YH_DE_V1";
import Contact from "./Components/Pages/Advertorials/Contact";
import FollowupThankyou from "./Components/Pages/Followup/FollowupThankyou";
import DE_YH_V2 from "./Components/Pages/Advertorials/DE_YH_V2";
import BlankPage from "./Components/Includes/Layouts/BlankPage";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={BlankPage} />
              <Route exact path={["/DE_JLG_1"]} component={BlankPage} />
              <Route exact path={["/DE_JLG_2"]} component={BlankPage} />
              <Route exact path={"/followup"} component={Followup} />
              <Route exact path={["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/followup-thankyou"} component={FollowupThankyou} />
              <Route exact path={["/id-upload"]} component={IdUpload} />
              <Route exact path={["/preview"]} component={PreviewLoa} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/er_check"]} component={ErCheck}/>
              <Route exact path={["/diesel/DE_V1"]} component={TCG_YH_DE_V1} />
              <Route exact path={["/diesel/DE_YH_V2"]} component={DE_YH_V2} />
              <Route exact path={"/Contact"} component={Contact} />   

            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
