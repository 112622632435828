import { useState } from "react";
import { Api } from "../api/Api";

export const useSignature = () => {
  const [isLoading, setIsLoading] = useState(false);
  const saveSignature = async (
    signature_data,
    visitor_parameters,
    form_data,
    data,
    query_string,
    message_type
  ) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/data-ingestion-pipeline", {
      signature_data,
      visitor_parameters,
      form_data,
      data,
      query_string,
      message_type,
    });
    setIsLoading(false);
    return response;
  };
  return {
    isLoading,
    saveSignature,
  };
};
