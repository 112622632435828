import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import { getParameterByName } from "../../Utility/Helper";
import ReactPixel from "react-facebook-pixel";
import VisitorParamsContext from "../../Contexts/VisitorParams";

require("dotenv").config();
const FbPixel = () => {
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("split_name");
  const nextPage = query.get("next_page");
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  let current_domain = visitorParameters.data.existingdomain;
  if (queryString != null) {
    if (queryString.indexOf("&" + field + "=") !== -1) {
      const pid = getParameterByName(field, queryString);
      // if (
      //   current_domain === visitorParameters.data.redirectDomain ||
      //   window.location.origin.includes("http://localhost")
      // ) {
        console.log("fb pixel iffff");
        console.log("next page" + nextPage);
        console.log("splitName" + splitName);
        console.log(visitorParameters);

        const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        ReactPixel.init(pid, advancedMatching, options);
        ReactPixel.pageView();
        ReactPixel.track("Lead");
        history.push(
          nextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+splitName
        );
      //} 
      // else {
      //   console.log("fb pixel elseee");
      //   const app_url = window.location.origin;
      //   let redirect_url = '';
      //   redirect_url = app_url + "/" + nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+splitName;
      //   const advRedirectDomain =
      //     "https://" + visitorParameters.data.redirectDomain;
      //   const fb_pixel_url =
      //     advRedirectDomain +
      //     "/fbpixel?" +
      //     queryString +
      //     "&redirect_url=" +
      //     redirect_url;
      //   window.location.href = fb_pixel_url;
      // }
    }
  }
  return <></>;
};

export default FbPixel;
