import { useState } from 'react';
import { Api } from '../api/Api';

export const useAgreeTermsIngestion = () => {
    const [isLoadingAgree, setIsLoadingAgree] = useState(false);

    const saveAgreeTermsIngestion = async (uuid, user_id, agreed, source) => {
        setIsLoadingAgree(true);
        const response = await Api.post("api/v1/agree-terms", {
            uuid,
            user_id,
            agreed,
            source
        });
        setIsLoadingAgree(false);
        return response;
    }
    return { saveAgreeTermsIngestion, isLoadingAgree }
}