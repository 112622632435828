import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const Terms = ({ showstatus, hidepopup }) => {
    return (
        <>
            {/* <div className="modal-custom-div"> */}
                <Modal show={showstatus} onHide={hidepopup} dialog="true" size="xl" className="footer_popup"
                    aria-labelledby="example-custom-modal-styling-title" dialogClassName="privacykl" stye={{
                        transition: 'opacity .5s linear'
                    }}>
                    <div className="in-down" role="document">
                        <Modal.Header>
                            <Modal.Title>Company Check End User Terms and Conditions</Modal.Title>
                            <button  className="close" onClick={hidepopup}>×</button>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="modal-body">
                              <p>Company Check End User Terms and Conditions</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={hidepopup}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            {/* </div> */}
        </>
    )
}

export default Terms;