export const split_Flow = 
    {
        default:{
            1:'DE_JLG_1', 
            2:'signature',
            3:'id-upload',
            4:'thankyou'
        },
        DE_JLG_1:{
            1:'DE_JLG_1', 
            2:'signature',
            3:'id-upload',
            4:'thankyou'
        },
        DE_JLG_2:{
            1:'DE_JLG_2', 
            2:'signature',
            3:'id-upload',
            4:'preview',
            5:'thankyou'
        }
    }