import React, { useContext, useState, useRef, useEffect } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import RadioButtonQuest from "../UI/RadioButtonQuest";
import InputButton from "../UI/InputButton";
import { useIdUpload } from "../../Hooks/useIdUpload";
import { queryString } from "../../Utility/QueryString";
import topImg from "../../assets/img/top-img.jpg";
import demoImg from "../../assets/img/upload-demo.jpg";
import cameraImg from "../../assets/img/camera.png";
import { useSaveErcheck } from "../../Hooks/useSaveErcheck";
import browseImg from "../../assets/img/browse.png";
import logoImg from "../../assets/img/logo.png";
import Accordion from "react-bootstrap/Accordion";
import { Link, useHistory } from "react-router-dom";
import AnalyzeModal from "../Includes/Layouts/Split_1/AnalayzeModal";
import { urlParams } from "../../Utility/QueryString";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
import GTMDataLayer from "../Includes/Layouts/GTMDataLayer";

const IdUpload = () => {
  const  {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('','id-upload');
  const { isCheck } = CheckUUID();
  const history = useHistory();
  // const isCheckResponse = isCheck();
  const { saveEr_check, isErLoading } = useSaveErcheck();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { saveIdUpload, isLoading } = useIdUpload();
  const [selectedFile, setSelectedFile] = useState();
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [buttonShow, setButtonShow] = useState(true);
  const [displayBlock, setdisplayBlock] = useState('none');
  const [loadingShow, setLoadingShow] = useState('Loading');
  const [selectedFileType, setSelectedFileType] = useState();
  const [base64Image, setBase64Image] = useState();
  const [image, setImage] = useState(demoImg);
  const [image2, setImage2] = useState(demoImg);
  const [image3, setImage3] = useState(demoImg); 
  const [modalClass, SetModalClass] = useState("modal fade");
  const [modalClass1, SetModalClass1] = useState("show");
  const [modalClass2, SetModalClass2] = useState("hide");

  const uploadRef = useRef();
  const National_id_ref = useRef();
  const Driving_license_ref = useRef();
  const Passport_ref = useRef();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: 'user',
  };
  const [picture, setPicture] = useState('')
  const webcamRef = React.useRef(null)
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc)
  });
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  useEffect(async () => {
    SetModalClass("modal show");
    const response = await saveEr_check(
      formParameters,
      uuid  
    ); 
    if(response.data === 1){
      history.push(
        "/"+DynamicRouteNextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName
      );
    } 
    else{
    SetModalClass("modal hide");
    setdisplayBlock('block');
    setLoadingShow('Uploading');
    }
  },[]);
  
  const userfile = {
    preview: undefined,
    title: undefined,
  };
  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setButtonShow(false);
    
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;

      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let Imagename= event.target.name;
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (event) => {
          setBase64Image({
            base64TextString: event.target.result,
          });
          // _handleReaderLoaded.bind(event);
          if(Imagename == 'National_id'){
            setImage(event.target.result);
          } else if (Imagename == 'Driving_license'){
            setImage2(event.target.result);
          } else if(Imagename == 'Passport'){
            setImage3(event.target.result);
          }
      }
      }
     
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };
  const showAnalyzeModal = (event) => {
    handleSubmit(event);
    }

  const handleSubmit = async (event) => {
    event.preventDefault();
    GTMDataLayer({
      question: "Verify Your identity", 
    });
    document.body.classList.add("modal-open");
    document.getElementById("analayzeModal").classList.add('show');
    document.getElementById("analayzeModal").classList.remove('hide');
    SetModalClass1("show");

    document.getElementById("id_upload1").classList.remove('show');
    document.getElementById(event.target.id).classList.remove('show');
    SetModalClass("modal show");
    setTimeout(() => { UpdateSlide1() }, 6000);
    setTimeout(() => { UpdateSlide2(event) }, 6000);
    const UpdateSlide1 = () => {
        SetModalClass1("hide");
        SetModalClass2("show");
    }
    const UpdateSlide2 = (event) => {
        SetModalClass2("hide");
        document.getElementById("analayzeModal").classList.remove('show');
        document.getElementById("analayzeModal").classList.add('hide');
        document.body.classList.remove("modal-open");
    }

    let uploadData = "";
    if (isFilePicked) {
      uploadData = {
        imageFile: base64Image.base64TextString,
        captureType: selectedFileType.substring(
          selectedFileType.indexOf("doc")
        ),
        documentType: selectedFileType,
      };
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        "user_docs_store"
      );
      history.push(
        "/"+DynamicRouteNextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName
      );
    } else {
      setIsInValidfFile(true);
      setErrorMessage({ txt: " Please upload a file" });
    }
  };

  const clearImage = () => {
    setButtonShow(true);
    setImage(demoImg);
    setImage2(demoImg);
    setImage3(demoImg);
  };
  const uuid = getUuid();
  const scrollChange = (event) => {
    var scrollToRef = (event == 'National_id')? National_id_ref :((event == 'Driving_license') ? Driving_license_ref : Passport_ref);
    setTimeout(()=>{scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });}, 500);
    

  }
  return (
    <>
      <GetVisitorsParams />
      <div id="id_upload1" className="id_upload" style={{display:displayBlock}}>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-12 col-md-12 col-lg-12 logo text-center">
                <img src={logoImg} alt="" />
              </div>
            </div>
          </div>
        </header>
        <section className="content">
          <div className="container text-center">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 intro">
                <img src={topImg} alt="" />
                <h2>Verify Your identity</h2>
                <h3>You must provide 1 proof(s) of address to proceed.</h3>
                <p>
                  Tip - Please provide a photo of one of the following:{" "}
                  <strong>National ID Card, Driver's License, Passport</strong>
                </p>
              </div>
              <Accordion className="accordion-section">
                <div className="uploading-part col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-12">
                  <div className="accordion accordion-flush" id="faqs">
                    <Accordion.Item className="accordion-item" eventKey="0">
                      <Accordion.Header className="accordion-header" onClick={(e) => { scrollChange("National_id"); }}>
                        National ID Card
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey="0"
                        id="accor-1"
                        className="accordion-collapse collapse acchide"
                      >
                        <Accordion.Body  className="accordion-body">
                          <div className="col-lg-12  col-12 upload-area">
                            <img src={image} width="313px" height="216px" alt="" />
                            <div className="col-lg-12 col-12">

                            {buttonShow ? 
                              (
                              <div>
                                <span className="button-tab browsebut">
                                <img src={browseImg} alt="" /> Browse from Your
                                Device
                                <input
                                  type="file"
                                  className="form-control"
                                  id="idTypeFile"
                                  name="National_id"
                                  placeholder="Upload picture of Your Id"
                                  accept="image/*"
                                  onChange={changeFilesHandler}
                                  onClick={e => (e.target.value = null)}
                                />
                              </span>
                                <a className="button-tab"  >
                                  <img src={cameraImg} alt="" /> Take picture of Your Id
                                  <input type="file"
                                  id="idTypeFile"
                                  name="National_id"
                                  accept="image/*"
                                  onChange={changeFilesHandler}
                                  onClick={e => (e.target.value = null)}
                                  capture="user"/>
                                  
                                </a>
                                <div className="id_msg">
                                    {isInValidfFile &&
                                    <span
                                      className="error_msg"
                                      id="email_err"
                                    >
                                    {errorMessage.txt}</span>
                                    }
                                </div>
                                </div>)

                                      :
                            <div>
                              
                              <input
                                type="button"
                                id=""
                                name=""
                                className="btn btn-danger clearImg"
                                value="Clear"
                                onClick={clearImage}
                              />
                              
                              <InputButton
                                    name="formSubmitBtn"
                                    className="btn btn-success submitImg"
                                    id="accor-1"
                                    value="Next"
                                    btnType="submit"
                                    onClick={(event)=>{showAnalyzeModal(event)}}
                                    style={{ color: "#fff!important" }}
                                  />
                                </div>
                                  }
                              {/* <Link to={`/preview`} className="btn btn-success" style={{ color: "#fff!important" }} >Next </Link> */}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                    <div ref={National_id_ref} ></div>
                    <Accordion.Item className="accordion-item" eventKey="1">
                      <Accordion.Header className="accordion-header" onClick={(e) => { scrollChange("Driving_license"); }}>
                        Driver's License
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey="1"
                        id="accor-2"
                        className="accordion-collapse collapse"
                      >
                        <Accordion.Body  className="accordion-body">
                          <div className="col-lg-12  col-12 upload-area">
                            <img src={image2} width="313px" height="216px" alt="" />
                            <div className="col-lg-12 col-12">
                            {buttonShow ? 
                              (
                              <div>
                              <span className="button-tab browsebut">
                                <img src={browseImg} alt="" /> Browse from Your
                                Device
                                <input
                                  type="file"
                                  className="form-control"
                                  id="idTypeFile"
                                  name="Driving_license"
                                  placeholder="Upload picture of Your Id"
                                  accept="image/*"
                                  onChange={changeFilesHandler}
                                  onClick={e => (e.target.value = null)}
                                />
                              </span>
                              <a className="button-tab"  >
                                  <img src={cameraImg} alt="" /> Take picture of Your Id
                                  <input type="file"
                                  id="idTypeFile"
                                  name="Driving_license"
                                  accept="image/*"
                                  onChange={changeFilesHandler}
                                  onClick={e => (e.target.value = null)}
                                  capture="user"/>
                                  
                                </a>
                                <div className="id_msg">
                                    {isInValidfFile &&
                                    <span
                                      className="error_msg"
                                      id="email_err"
                                    >
                                    {errorMessage.txt}</span>
                                    }
                                </div></div>)

                                :
                                <div>
                                                              
                                <input
                                  type="button"
                                  id="form-control"
                                  name="form-control"
                                  className="btn btn-danger clearImg"
                                  value="Clear"
                                  onClick={clearImage}
                                />

                                <InputButton
                                      name="formSubmitBtn"
                                      className="btn btn-success submitImg"
                                      id="accor-2"
                                      value="Next"
                                      btnType="submit"
                                      onClick={(event)=>{showAnalyzeModal(event)}}
                                      style={{ color: "#fff!important" }}
                                    />
                                  </div>
                                    }
                              {/* <Link to={`/preview`} className="btn btn-success" style={{ color: "#fff!important" }} >Next </Link> */}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                    <div ref={Driving_license_ref} ></div>
                    <Accordion.Item className="accordion-item" eventKey="2">
                      <Accordion.Header className="accordion-header" onClick={(e) => { scrollChange("Passport"); }}>
                        Passport
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey="2"
                        id="accor-3"
                        className="accordion-collapse collapse"
                      >
                        <Accordion.Body className="accordion-body">
                          <div className="col-lg-12  col-12 upload-area">
                            <img src={image3} width="313px" height="216px" alt="" />
                            <div className="col-lg-12 col-12">
                            {buttonShow ? 
                              (
                              <div>
                              <span className="button-tab browsebut">
                                <img src={browseImg} alt="" /> Browse from Your
                                Device 
                                <input
                                  type="file"
                                  className="form-control"
                                  id="idTypeFile"
                                  name="Passport"
                                  placeholder="Upload picture of Your Id"
                                  accept="image/*"
                                  onChange={changeFilesHandler}
                                  onClick={e => (e.target.value = null)}
                                />
                              </span>
                              <a className="button-tab"  >
                                  <img src={cameraImg} alt="" /> Take picture of Your Id
                                  <input type="file"
                                  id="idTypeFile"
                                  name="Passport"
                                  accept="image/*"
                                  onChange={changeFilesHandler}
                                  onClick={e => (e.target.value = null)}
                                  capture="user"/>
                                  
                                </a>
                                <div className="id_msg">
                                    {isInValidfFile &&
                                    <span
                                      className="error_msg"
                                      id="email_err"
                                    >
                                    {errorMessage.txt}</span>
                                    }
                                </div></div>)

                                      :
                                      <div>
                                                                                                    
                                      <input
                                        type="button"
                                        id=""
                                        name=""
                                        className="btn btn-danger clearImg"
                                        value="Clear"
                                        onClick={clearImage}
                                      />

                                      <InputButton
                                            name="formSubmitBtn"
                                            className="btn btn-success submitImg"
                                            id="accor-3"
                                            value="Next"
                                            btnType="submit"
                                            onClick={(event)=>{showAnalyzeModal(event)}}
                                            style={{ color: "#fff!important" }}
                                          />
                                        </div>
                                          }
                              {/* <Link to={`/preview`} className="btn btn-success" style={{ color: "#fff!important" }} >Next </Link> */}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                    <div ref={Passport_ref} ></div>
                  </div>
                </div>
              </Accordion>
              
              <div className="doc-op">
                <ul>
                  <li> Document should be Clear &amp; Legible</li>
                  <li> Entire Document should be Captured</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
            <AnalyzeModal
                modalClass={modalClass}
                modalClass1={modalClass1}
                modalClass2={modalClass2}
                textHeading={loadingShow}
            />
    </>
  );
};

export default IdUpload;
