import { useState } from "react";
import { Api } from "../api/Api";

export const useSaveErcheck = () => {
  const [isLoading, setIsLoading] = useState(false);
  const saveEr_check = async (
    form_data,
    uuid,
  ) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/get-javascript-key", {
      form_data,
      uuid,
    });
   
    setIsLoading(false);
    return response;
  };
  return {
    isLoading,
    saveEr_check,
  };
};
